import { FC } from 'react';
import { Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UIButton } from '@/shared/ui/ui-button';
import { Link } from 'react-router-dom';

import backgroundImage from '@/app/assets/images/landing_background.png';

import { Colors } from '@/app/constants/colors';
import { useTranslation } from 'react-i18next';

export const LandingHero: FC = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();

  const didoxUrl = import.meta.env.VITE_DIDOX_BASE_URL ?? 'https://didox.uz';
  const origin = window.location.origin;

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <Box
      sx={{
        background: `no-repeat url(${backgroundImage})`,
        backgroundSize: 'cover',
        pt: isMobile ? '4rem' : '6.4rem',
      }}
    >
      <Stack
        direction='column'
        alignItems={isMobile ? 'flex-start' : 'center'}
        px={isMobile || isTablet ? 2 : 0}
        py={isMobile ? 4 : 8}
      >
        <Typography
          variant={isMobile ? 'h6' : 'h4'}
          textAlign={isMobile ? 'left' : 'center'}
          color={Colors.WHITE_TEXT}
        >
          {t('self_empl_service_landing')}
        </Typography>

        <Stack direction='row' spacing={2} mt={isMobile ? 4 : 5}>
          <Link to={`${didoxUrl}/login_with_signature?r=${origin}/auth/customer`}>
            <UIButton size={isMobile ? 'medium' : 'large'}>{t('i_am_customer')}</UIButton>
          </Link>
          <Tooltip title={t('under_development')}>
            {/* <Link to='/auth/employee'> */}
            <Box component='span'>
              <UIButton size={isMobile ? 'medium' : 'large'} disabled>
                {t('i_am_worker')}
              </UIButton>
            </Box>
            {/* </Link> */}
          </Tooltip>
        </Stack>
      </Stack>
    </Box>
  );
};
