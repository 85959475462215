import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppState, CabinetType } from ".";

const initialState: AppState = {
  cabinetType: localStorage.getItem("cabinetType") as CabinetType,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCabinetType: (
      state,
      payload: PayloadAction<CabinetType | undefined>
    ) => {
      if (!payload.payload) {
        localStorage.removeItem("cabinetType");
      } else {
        localStorage.setItem("cabinetType", payload.payload);
      }
      state.cabinetType = payload.payload;
    },
    setFullscreenLoaderVisibility: (state, payload: PayloadAction<boolean>) => {
      state.fullScreenLoading = payload.payload;
    },
  },
});

export const { setCabinetType, setFullscreenLoaderVisibility } =
  appSlice.actions;
