import { FC } from 'react';
import {
  AppBar,
  Box,
  ButtonBase,
  ButtonBaseProps,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ProfileMenu } from '@/widgets/profile-menu';
import { useAppSelector } from '@/app/hooks';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { TariffHeaderMenu } from '@/features/tariff-header-menu';
import { LangSwitcher } from '@/features/lang-switcher';
import { NavDrawer } from '@/shared/header/ui/nav-drawer';
import { getHeaderNavData, getLandingNavData } from '@/shared/header/lib/index';

import LogoDidox from '@/app/assets/images/didox_s-logo.svg?react';
import BurgerMenu from '@/app/assets/icons/burger-menu.svg?react';
import PhoneIcon from '@/app/assets/icons/phone-icon.svg?react';

import { Colors } from '@/app/constants/colors';

const ButtonComp: FC<ButtonBaseProps> = (props) => (
  <ButtonBase
    {...props}
    sx={{
      px: 2,
      height: '64px',
      ...props.sx,
    }}
  />
);

export const GeneralHeader = () => {
  const { breakpoints } = useTheme();
  const { authorized } = useAppSelector((state) => state.auth);
  const { cabinetType } = useAppSelector((state) => state.app);
  const navDrawerRef = useModalRef();

  const isMobile = useMediaQuery('(max-width: 715px)');
  const isLessThanLg = useMediaQuery(breakpoints.down('lg'));

  const navData = authorized ? getHeaderNavData() : getLandingNavData();

  return (
    <AppBar
      position='absolute'
      sx={{
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        boxShadow: 'none',
        pt: 1,
      }}
    >
      <Container>
        <Stack direction='row' justifyContent='space-between' alignItems='center' px={2}>
          <Stack direction='row' alignItems='center' spacing={2}>
            {isMobile && (
              <ButtonBase
                onClick={() => {
                  navDrawerRef.current?.open();
                }}
                sx={{
                  p: 1,
                  borderRadius: '50%',
                }}
              >
                <Box component={BurgerMenu} />
              </ButtonBase>
            )}

            <Stack component={Link} to='/' justifyContent='center'>
              <Box
                component={LogoDidox}
                sx={{
                  width: isMobile ? '90px' : 'auto',
                }}
              />
            </Stack>
          </Stack>

          {!isMobile && (
            <Stack direction='row' spacing={4}>
              {navData.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    textDecoration: 'none',
                  }}
                  {...(item.to
                    ? {
                        component: Link,
                        to: item.to,
                      }
                    : {
                        component: 'a',
                        href: item.href,
                      })}
                >
                  <Typography color={Colors.WHITE_TEXT}>{item.label}</Typography>
                </Box>
              ))}
            </Stack>
          )}

          <Stack direction='row'>
            {!isMobile && (
              <>
                <Box
                  component='a'
                  href='tel:+99878148-49-00'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                  }}
                >
                  <Box component={PhoneIcon} width={20} sx={{ path: { stroke: Colors.WHITE } }} />{' '}
                  <Typography ml={2} color={Colors.WHITE_TEXT}>
                    (78) 148-49-00
                  </Typography>
                </Box>
                <LangSwitcher Container={ButtonComp} />
              </>
            )}

            {authorized && (
              <>
                {!isLessThanLg && cabinetType === 'customer' && <TariffHeaderMenu />}

                <ProfileMenu
                  Container={ButtonComp}
                  sx={{
                    backgroundColor: `rgba(255,255,255,.08)`,
                    maxWidth: '280px',
                    width: '100%',
                  }}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Container>
      <NavDrawer ref={navDrawerRef} />
    </AppBar>
  );
};
