import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { FeaturesProps } from '../types';
import { Colors } from '@/app/constants/colors';

export const DidoxSFeatures: FC<FeaturesProps> = ({
  Image,
  title,
  description,
  isMobile,
  index,
}) => {
  return (
    <Stack direction='column' spacing={isMobile ? 0.7 : 1.5}>
      <Box
        component={Image}
        width={isMobile ? '26px' : '36px'}
        height={isMobile ? '26px' : '36px'}
        sx={{ path: { stroke: !index ? Colors.ACCENT : '' } }}
      />
      <Typography variant={isMobile ? 'h6' : 'h5'}>{title}</Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} fontSize='1.4rem'>
        {description}
      </Typography>
    </Stack>
  );
};
