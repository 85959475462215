import { useEffect, useState } from 'react';
import { UseFiltersProps, UseFiltersReturn } from './types';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

export function useFilters<T extends object>(props?: UseFiltersProps<T>): UseFiltersReturn<T> {
  const { initialFilterParams = {}, saveInUrl = false } = props ?? {};
  const navigate = useNavigate();
  const { search } = useLocation();

  const [filterParams, setFilterParams] = useState<T>(
    Object.entries(initialFilterParams).reduce((acc, [key, value]) => {
      if (!value || (Array.isArray(value) && !value.length)) return acc;
      return { ...acc, [key]: value };
    }, {}) as T
  );

  useEffect(() => {
    if (saveInUrl) {
      const qsParams = qs.stringify(filterParams, { skipNulls: true });

      const newUrl = `${location.pathname}?${qsParams}`;
      navigate(newUrl, { replace: true });
    }
  }, [filterParams]);

  const setUrlArgsToState = (): T => {
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const newParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value || (Array.isArray(value) && !value.length)) return acc;
      return { ...acc, [key]: value };
    }, {} as T);

    setFilterParams((prev) => ({ ...prev, ...newParams }));

    return newParams;
  };

  const onChangeFilterParams = (params: Partial<T>) => {
    setFilterParams((prev) => {
      const newParams = Object.entries({ ...prev, ...params }).reduce((acc, [key, value]) => {
        if ((!value && typeof value !== 'boolean') || (Array.isArray(value) && !value.length)) {
          return acc;
        }

        return { ...acc, [key]: value };
      }, {}) as T;

      return newParams;
    });
  };

  return {
    onChangeFilterParams,
    filterParams,
    setUrlArgsToState,
  };
}
