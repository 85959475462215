import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useAppSelector, useOpen } from '@/app/hooks';
import { CircularProgress, Dialog, Typography } from '@mui/material';
import ModalTemplate from '@/shared/modal-template';
import { UITable } from '@/shared/ui/ui-table';
import {
  deleteDraft,
  rejectDidoxDoc,
  signDidoxDoc,
  signOutgoingDoc,
} from '@/app/helpers/sign-didox-doc';
import { CommentModal, CommentModalRef } from '@/pages/single-document-page/ui/comment-modal';

import { DoneIcon } from '@/shared/icons';

import { DocumentActionType, SignDocsModalProps, SignDocsModalRef } from './types';
import { YandexDoc } from '@/app/api/model';
import { useTranslation } from 'react-i18next';

type Status = 'fail' | 'success' | 'inQueue' | 'inProcess';

interface Doc {
  doc: YandexDoc;
  status: Status;
  error?: string;
}

export const SignDocsModal = forwardRef<SignDocsModalRef, SignDocsModalProps>(
  ({ callback }, ref) => {
    const { t } = useTranslation();
    const { company } = useAppSelector((state) => state.auth);
    const { open, setOpen } = useOpen();
    const [docs, setDocs] = useState<Doc[]>([]);
    const [isCompleted, setIsCompleted] = useState(false);

    const commentModalRef = useRef<CommentModalRef>(null);

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
        init: onInit,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
      setIsCompleted(false);
      setDocs([]);
    };

    const onInit = (docs: YandexDoc[], action: DocumentActionType) => {
      if (!company) return;
      const docsToSign: Doc[] = docs.map((el) => ({
        doc: el,
        status: 'inQueue',
      }));

      setDocs(docsToSign);
      onOpen();

      let chain = Promise.resolve();

      docsToSign.forEach((entity, idx) => {
        if (!entity.doc.didoxId) {
          changeDocStatus(idx, 'fail', t('doc_not_found_at_didox'));
        } else {
          chain = chain.then(async () => {
            try {
              changeDocStatus(idx, 'inProcess');
              if (action === 'sign') {
                if (entity.doc.status === 'draft') {
                  await signDidoxDoc(entity.doc.didoxId as string);
                } else await signOutgoingDoc(entity.doc.didoxId as string);
              }
              if (action === 'reject') {
                const comment = await commentModalRef.current?.init({ title: t('reject_title') });
                await rejectDidoxDoc(entity.doc.didoxId as string, comment ?? '');
              }
              if (action === 'delete') await deleteDraft(entity.doc.didoxId as string);
              changeDocStatus(idx, 'success');
            } catch (err) {
              changeDocStatus(
                idx,
                'fail',
                err?.data?.error?.message || err?.data?.data?.message || ''
              );
            } finally {
              if (idx === docsToSign.length - 1) {
                setIsCompleted(true);
                callback?.();
              }
            }
          });
        }
      });
    };

    const changeDocStatus = (index: number, status: Status, error?: string) => {
      setDocs((prev) => {
        const newDocs = [...prev];
        newDocs[index] = {
          ...newDocs[index],
          status,
          error,
        };
        return newDocs;
      });
    };

    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            maxWidth: 900,
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={t('docs_signing')}
          {...(isCompleted && {
            onClose,
          })}
        >
          <UITable
            data={docs}
            columns={[
              { label: '№', key: 'doc.index', nowrap: true },
              { label: t('document_number'), key: 'doc.name' },
              {
                label: t('status'),
                render: ({ status, error }) => {
                  if (error) {
                    return <Typography color='error'>{error}</Typography>;
                  }

                  switch (status) {
                    case 'inQueue':
                      return t('in_queue');
                    case 'inProcess':
                      return <CircularProgress size={20} color='secondary' />;
                    case 'success':
                      return <DoneIcon />;
                    case 'fail':
                      return error;
                  }
                },
              },
            ]}
          />
        </ModalTemplate>
        <CommentModal ref={commentModalRef} />
      </Dialog>
    );
  }
);
