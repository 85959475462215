import { SIZES } from '@/app/constants';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { forwardRef } from 'react';

export const PageSizeSelect = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  return (
    <Select
      size='small'
      {...props}
      ref={ref}
      sx={{
        minWidth: 100,
        ...props.sx,
      }}
    >
      {SIZES.map((el) => (
        <MenuItem key={el} value={el}>
          {el}
        </MenuItem>
      ))}
    </Select>
  );
});
