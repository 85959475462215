import { FC } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { UIFormLabel } from '../../../shared/ui/ui-form-label';
import { Control, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { DocumentReportTypeId, GetDocumentReportTypes } from '../../../app/api/model';

interface CreateYandexReportComponentProps {
  control: Control<{ type?: DocumentReportTypeId }, any>;
  typesData: GetDocumentReportTypes[] | undefined;
}

export const CreateYandexReportComponent: FC<CreateYandexReportComponentProps> = ({
  control,
  typesData,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <UIFormLabel>{t('customer_trips_page.report_type')}:</UIFormLabel>

      <Controller
        control={control}
        name='type'
        render={({ field }) => (
          <RadioGroup {...field}>
            {typesData?.map((obj) => (
              <FormControlLabel
                key={obj.id}
                value={obj.id}
                label={obj.name}
                required
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
