import { useContext } from "react";
import { EmployeeRegistrationContext } from "../lib/employee-registration-context";
import { Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const RegistrationEndStep = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const { userData } = useContext(EmployeeRegistrationContext);

  // const dispatch = useAppDispatch();

  // const onSubmitHandler = () => {
  //   const token = Cookies.get("token");
  //   if (!token) return;

  //   dispatch(
  //     authEmployee({
  //       token,
  //       userData: _.omit(userData, ["faceIdRequired", "hasSmsCode"]),
  //     })
  //   );
  //   navigate("/balance");
  // };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <Typography variant="h5" mb={3} textAlign="center">
          {t("registration_success")}
        </Typography>

        <Typography textAlign="center">
          {t("registration_congrats", {
            name: userData?.name ?? "registration_congrats",
          })}
        </Typography>

        {/* <UIButton
          sx={{
            mt: 5,
          }}
          onClick={onSubmitHandler}
        >
          {t("open_service")}
        </UIButton> */}
      </Stack>
    </Container>
  );
};
