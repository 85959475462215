import { Controller, FieldValues } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps,
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';

import { useTranslation } from 'react-i18next';
import { BaseControllerInputProps } from '../types';

export const FormDatePicker = <T extends FieldValues, TDate>(
  props: BaseControllerInputProps<T, DatePickerProps<TDate>>
) => {
  const { t } = useTranslation();
  const { control, name, onChange, rules, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...rules,
        required: rules?.required === true ? t('errors:required_field') : rules?.required,
      }}
      render={({ field: { value = null, ...field }, fieldState }) => {
        const onChangeHandler = (
          value: TDate | null,
          context: PickerChangeHandlerContext<DateValidationError>
        ) => {
          field.onChange(value);
          onChange?.(value, context);
        };

        return (
          <DatePicker
            value={value as TDate}
            {...rest}
            {...field}
            format='DD/MM/YYYY'
            onChange={onChangeHandler}
            slotProps={{
              textField: {
                error: fieldState.invalid,
                helperText: fieldState.error?.message,
                ...rest.slotProps?.textField,
              },
            }}
          />
        );
      }}
    />
  );
};
