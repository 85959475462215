import { useState } from "react";

export function useOpen(defaultValue: boolean = false) {
  const [open, setOpen] = useState(defaultValue);

  return {
    open,
    setOpen,
  };
}
