import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import CheckIcon from '@/app/assets/icons/check_circle.svg?react';

import { HowItWorksProps } from '../types';

export const HowItWorks: FC<HowItWorksProps> = ({
  Image,
  title,
  paragpaphs,
  backgroundColor,
  isMobile,
}) => {
  return (
    <Stack
      direction='column'
      spacing={1.5}
      p={isMobile ? '16px 0 0 20px' : '32px 0 0 40px'}
      borderRadius={2}
      sx={{ backgroundColor }}
    >
      <Typography variant={isMobile ? 'h6' : 'h5'}>{title}</Typography>
      {paragpaphs.map((paragraph) => (
        <Stack key={paragraph} direction='row' alignItems='center' maxWidth='80%' spacing={2}>
          <Box component={CheckIcon} minWidth={isMobile ? 16 : 24} />
          <Typography variant={isMobile ? 'body2' : 'body1'}>{paragraph}</Typography>
        </Stack>
      ))}
      <Box component='img' src={Image} width='100%' />
    </Stack>
  );
};
