import { useAppSelector } from "@/app/hooks";
import { Box, Modal, Stack } from "@mui/material";
import Logo from "@/app/assets/images/logo_didox_dark.svg?react";
import { UILoader } from "../ui/ui-loader";

export const FullscreenLoader = () => {
  const { fullScreenLoading } = useAppSelector((state) => state.app);

  return (
    <Modal
      open={!!fullScreenLoading}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
        sx={{
          backgroundColor: "#fff",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box component={Logo} />
        <UILoader />
      </Stack>
    </Modal>
  );
};
