import { useLayoutEffect } from 'react';
import { Layout } from '@/shared/layout';
import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/app/hooks';
import { DidoxSFeatures } from './ui/didoxs-features';
import { HowItWorks } from './ui/how-it-works';
import { ProductAdvantages } from './ui/product-advantages';
import { GeneralHeader } from '@/shared/general-header';
import { LandingHero } from '@/features/landing-hero';

import DocumentIcon from '@/app/assets/icons/document-text.svg?react';
import ManIcon from '@/app/assets/icons/man-icon.svg?react';
import WalletIcon from '@/app/assets/icons/wallet.svg?react';
import ArrowsIcon from '@/app/assets/icons/arrows-icon.svg?react';
import PhoneIcon from '@/app/assets/icons/phone-icon.svg?react';
import AddEmployeesImage from '@/app/assets/images/add_employees.png';
import ClearingImage from '@/app/assets/images/clearing.png';

import i18n from '@/app/i18n';
import { useTranslation } from 'react-i18next';
import { Features, HowItWorksInfo } from './types';
import { Colors } from '@/app/constants/colors';
import OurPartners from '@/pages/main-page/ui/our-partners.tsx';

const t = i18n.t.bind(i18n);

const getFeatures = (): Features[] => {
  return [
    {
      Image: DocumentIcon,
      title: t('common:document_flow'),
      description: t('landing:features.0'),
    },
    {
      Image: ManIcon,
      title: t('common:self_employed_status'),
      description: t('landing:features.1'),
    },
    {
      Image: WalletIcon,
      title: t('common:finance'),
      description: t('landing:features.2'),
    },
    {
      Image: ArrowsIcon,
      title: t('common:integration'),
      description: t('landing:features.3'),
    },
  ];
};

const getHowItWorksInfo = (): HowItWorksInfo[] => {
  return [
    {
      Image: AddEmployeesImage,
      title: t('common:document_flow'),
      paragpaphs: [
        t('landing:employers_paragraphs.0'),
        t('landing:employers_paragraphs.1'),
        t('landing:employers_paragraphs.2'),
        t('landing:employers_paragraphs.3'),
        t('landing:employers_paragraphs.4'),
      ],
      backgroundColor: '#F3EEE4',
    },
    {
      Image: ClearingImage,
      title: t('common:self_employed_status'),
      paragpaphs: [
        t('landing:employees_paragraphs.0'),
        t('landing:employees_paragraphs.1'),
        t('landing:employees_paragraphs.2'),
        t('landing:employees_paragraphs.3'),
      ],
      backgroundColor: '#E0F7E0',
    },
  ];
};

export const MainPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const { authorized } = useAppSelector((state) => state.auth);

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isLessThanLg = useMediaQuery(breakpoints.down('lg'));

  useLayoutEffect(() => {
    if (authorized) {
      navigate('/balance');
    }
  }, []);

  return (
    <Layout
      CustomHeader={GeneralHeader}
      ContainerProps={{
        sx: {
          p: 0,
        },
      }}
    >
      <LandingHero />
      <Container>
        <Stack
          direction='column'
          alignItems='center'
          spacing={isMobile ? 4 : 6}
          mt={isMobile ? 2 : 4}
        >
          <Stack
            direction={isMobile || isTablet ? 'column' : 'row'}
            px={isMobile || isLessThanLg ? 2 : 0}
            spacing={isMobile ? 2 : 3}
          >
            {getFeatures().map((feature, idx) => (
              <DidoxSFeatures key={feature.title} isMobile={isMobile} index={idx} {...feature} />
            ))}
          </Stack>

          <Stack
            direction='column'
            alignItems='center'
            spacing={isMobile || isTablet ? 2 : 5}
            id='how-it-works'
          >
            <Typography variant={isMobile ? 'h5' : 'h4'}>{t('landing:how_it_works')}</Typography>
            <Stack
              direction={isMobile || isTablet ? 'column' : 'row'}
              px={isMobile || isLessThanLg ? 2 : 0}
              spacing={isMobile || isTablet ? 2 : 3}
            >
              {getHowItWorksInfo().map((info) => (
                <HowItWorks key={info.title} isMobile={isMobile} {...info} />
              ))}
            </Stack>
          </Stack>

          <Stack
            direction='column'
            alignItems='center'
            px={isMobile || isLessThanLg ? 2 : 0}
            spacing={isMobile ? 2 : 5}
            id='advantages'
          >
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {t('landing:product_advantages')}
            </Typography>
            <ProductAdvantages isMobile={isMobile} isTablet={isTablet} />
          </Stack>

          <Stack
            direction='column'
            alignItems='center'
            px={isMobile || isLessThanLg ? 2 : 0}
            spacing={isMobile ? 2 : 5}
            id='our-partners'
          >
            <Typography variant={isMobile ? 'h5' : 'h4'}>{t('landing:our_partners')}</Typography>
            <OurPartners isMobile={isMobile} isTablet={isTablet} />
          </Stack>

          <Stack
            direction='column'
            alignItems='center'
            spacing={3}
            px={isMobile ? 2 : 0}
            maxWidth={isMobile ? '100%' : '80%'}
            textAlign='center'
            sx={{ backgroundColor: Colors.LGRAY, padding: isMobile ? 1 : 2 }}
          >
            <Typography variant={isMobile ? 'body1' : 'h5'} fontWeight={600}>
              {t('landing:consultation')}
            </Typography>
            <Box
              component='a'
              href='tel:+998781484900'
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <Box component={PhoneIcon} width={24} sx={{ path: { fill: 'white' } }} />
              <Typography ml={2} variant={isMobile ? 'body2' : 'body1'}>
                (78) 148-49-00
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
};
