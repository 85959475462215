import React from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import _ from 'lodash';

import { UITableProps } from '../types';
import { Colors } from '@/app/constants/colors';

export function UITableRow<T extends object>(
  props: UITableProps<T> & {
    index: number;
    selected: number[];
    setSelected: React.Dispatch<React.SetStateAction<number[]>>;
    element: T;
  }
) {
  const {
    checkbox,
    index,
    selected,
    setSelected,
    columns,
    element,
    addittionRows,
    additionalRowsSx,
    onRowClick,
    data,
  } = props;

  const additionalRow = addittionRows?.(element, index);
  const additionalRowSx = additionalRowsSx?.(element, index);

  return (
    <TableRow
      key={index}
      sx={{
        position: 'relative',
      }}
    >
      {checkbox && (
        <TableCell
          sx={{
            borderLeft: `1px solid rgba(112, 125, 159, 0.2)`,
            borderBottom: `1px solid rgba(112, 125, 159, 0.2)`,
            borderTop: `1px solid rgba(112, 125, 159, 0.2)`,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            position: 'relative',
            zIndex: 1,
            backgroundColor: Colors.WHITE,
          }}
        >
          <Checkbox
            color='secondary'
            checked={selected.includes(index)}
            onChange={(_event, checked) => {
              if (checked) {
                if (typeof checkbox === 'object') {
                  checkbox.setSelected((prev) => [...prev, index]);
                }
                setSelected((prev) => [...prev, index]);
              } else {
                if (typeof checkbox === 'object') {
                  checkbox.setSelected((prev) => prev.filter((el) => el !== index));
                }
                setSelected((prev) => prev.filter((el) => el !== index));
              }
            }}
          />
        </TableCell>
      )}
      {columns.map((col, idx) => {
        const isFirst = idx === 0 && !checkbox;
        const isLast = idx === columns.length - 1;

        const value = col?.key ? _.get(element, col?.key) : col?.render?.(element, index);

        return (
          <TableCell
            align={col?.align}
            key={idx}
            onClick={() => onRowClick?.(data[index], index)}
            sx={{
              borderLeft: isFirst ? `1px solid rgba(112, 125, 159, 0.2)` : 'none',
              borderRight: isLast ? `1px solid rgba(112, 125, 159, 0.2)` : 'none',
              borderBottom: `1px solid rgba(112, 125, 159, 0.2)`,
              borderTop: `1px solid rgba(112, 125, 159, 0.2)`,
              borderTopRightRadius: isLast ? '8px' : 'none',
              borderBottomRightRadius: isLast ? '8px' : 'none',
              borderTopLeftRadius: isFirst ? '8px' : 'none',
              borderBottomLeftRadius: isFirst ? '8px' : 'none',
              zIndex: 1,
              position: 'relative',
              backgroundColor: Colors.WHITE,
              cursor: onRowClick ? 'pointer' : 'default',
              ...col?.sx,
            }}
          >
            {value as string}
          </TableCell>
        );
      })}
      {additionalRow && (
        <TableCell
          sx={{
            position: 'absolute',
            backgroundColor: Colors.LGRAY,
            width: '100%',
            height: '40px',
            padding: '14px 8px 8px 8px',
            left: '0',
            top: 'calc(100% - 8px)',
            display: 'table-cell',
            zIndex: 0,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            ...additionalRowSx,
          }}
        >
          {additionalRow}
        </TableCell>
      )}
    </TableRow>
  );
}
