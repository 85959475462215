import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { BaseModalRef } from '@/app/helpers';
import { useAppSelector, useOpen } from '@/app/hooks';
import { Drawer, FormControl, Stack, Typography } from '@mui/material';
import ModalTemplate from '@/shared/modal-template';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import FormSelectInput from '@/shared/inputs/form-autocomplete-input';
import { useGetCompanyWorkerStatusesQuery } from '@/app/api';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { EmployeesFiltersProps } from './types';
import { EmployeesFormValues } from '../../types';
import { Colors } from '@/app/constants/colors';
import { WorkerStatus } from '../../../../app/constants';

export const EmployeesFilters = forwardRef<BaseModalRef, EmployeesFiltersProps>(
  ({ form, onChangeFilterParams, initialFilterParams, filterParams }, ref) => {
    const { t, language } = useTranslation()[1];
    const { company } = useAppSelector((state) => state.auth);

    const isYandex = company?.invitationFlow === 'yandex';
    const isUzum = company?.invitationFlow === 'uzum';
    // const isUklon = company?.invitationFlow === 'uklon';
    // const isTaxiAndDelivery = company?.invitationFlow === 'taxiAndDelivery';
    // const isPlovme = company?.invitationFlow === 'plovme';

    const arrOfActTypes = company?.actTypes ?? [];

    const { open, setOpen } = useOpen();
    const { control, handleSubmit, reset } = form;

    const { data: statuses } = useGetCompanyWorkerStatusesQuery({});

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onSubmitHandler = (data: EmployeesFormValues) => {
      const actTypes: Array<number> = [];
      const excludedActTypes: Array<number> = [];
      Object.entries(data.activityFilters).forEach(([code, type]) => {
        if (typeof type.value === 'boolean') {
          if (type.value) actTypes.push(Number(code));
          else excludedActTypes.push(Number(code));
        }
      });
      _.unset(data, 'activityFilters');

      onChangeFilterParams({
        ...data,
        page: 1,
        counterFilter: filterParams.counterFilter ?? data.counterFilter.id,
        status: data.status.id,
        yourCommitentFilter: data.yourCommitentFilter.value,
        uzumSubcommitentFilter: data.uzumSubcommitentFilter.value,
        selfEmployedFilter: data.selfEmployedFilter.value,
        yandexTaxiSubcommitentFilter: data.yandexTaxiSubcommitentFilter.value,
        yandexDeliverySubcommitentFilter: data.yandexDeliverySubcommitentFilter.value,
        actTypes,
        excludedActTypes,
      });
      onClose();
    };

    const onResetHandler = () => {
      reset();
      onChangeFilterParams(initialFilterParams);
      onClose();
    };

    const statusOptions = [{ id: WorkerStatus.ALL, name: t('all') }, ...(statuses?.data ?? [])];

    const options = useMemo(
      () => [
        {
          value: '',
          label: t('all'),
        },
        {
          value: true,
          label: t('yes'),
        },
        {
          value: false,
          label: t('no'),
        },
      ],
      []
    );

    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '480px',
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={t('filter')}
          submitBtnText={t('apply')}
          cancelBtnText={t('reset')}
          onClose={onResetHandler}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack spacing={2}>
            <FormControl>
              <UIFormLabel>{t('registration_status')}:</UIFormLabel>
              <FormSelectInput
                control={control}
                name='status'
                options={statusOptions}
                disableClearable
                onBeforeInputCapture={(e) => {
                  e.preventDefault();
                }}
              />
            </FormControl>

            <Stack
              direction='column'
              spacing={1.5}
              sx={{ backgroundColor: Colors.LGRAY, p: 3, borderRadius: 1 }}
            >
              <Typography variant='subtitle1' color={Colors.ACCENT}>
                {t('self_emplt_status')}
              </Typography>

              <Stack direction='row' spacing={2}>
                {!!arrOfActTypes.length &&
                  arrOfActTypes.map((item) => (
                    <FormControl key={item.code}>
                      <UIFormLabel>{language === 'ru' ? item.nameRu : item.nameUz}:</UIFormLabel>
                      <FormSelectInput
                        control={control}
                        name={`activityFilters.${item.code}`}
                        options={options}
                        disableClearable
                      />
                    </FormControl>
                  ))}
              </Stack>
              {/* {isUklon && (
                <FormControl>
                  <UIFormLabel>{t('self_employed_taxi_status')}:</UIFormLabel>
                  <FormSelectInput
                    control={control}
                    name='selfEmployedTaxiFilter'
                    options={options}
                    disableClearable
                    onBeforeInputCapture={(e) => {
                      e.preventDefault();
                    }}
                  />
                </FormControl>
              )} */}

              {/* {(isUzum || isPlovme) && (
                <FormControl>
                  <UIFormLabel>{t('self_employed_delivery_status')}:</UIFormLabel>
                  <FormSelectInput
                    control={control}
                    name='selfEmployedDeliveryFilter'
                    options={options}
                    disableClearable
                    onBeforeInputCapture={(e) => {
                      e.preventDefault();
                    }}
                  />
                </FormControl>
              )} */}

              {/* {(isTaxiAndDelivery || isYandex) && (
                <Stack direction='row' spacing={2}>
                  <FormControl>
                    <UIFormLabel>{t('self_employed_taxi_status')}:</UIFormLabel>
                    <FormSelectInput
                      control={control}
                      name='selfEmployedTaxiFilter'
                      options={options}
                      disableClearable
                      onBeforeInputCapture={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <UIFormLabel>{t('self_employed_delivery_status')}:</UIFormLabel>
                    <FormSelectInput
                      control={control}
                      name='selfEmployedDeliveryFilter'
                      options={options}
                      disableClearable
                      onBeforeInputCapture={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>
                </Stack>
              )} */}

              <Typography variant='subtitle1' color={Colors.ACCENT}>
                {t('is_my_commitent')}
              </Typography>

              <FormControl>
                <FormSelectInput
                  control={control}
                  name='yourCommitentFilter'
                  options={options}
                  disableClearable
                  onBeforeInputCapture={(e) => {
                    e.preventDefault();
                  }}
                />
              </FormControl>

              {(isUzum || isYandex) && (
                <Typography variant='subtitle1' color={Colors.ACCENT}>
                  {t('agregator_subcommitent')}
                </Typography>
              )}

              {isUzum && (
                <FormControl>
                  <UIFormLabel>Uzum:</UIFormLabel>
                  <FormSelectInput
                    control={control}
                    name='uzumSubcommitentFilter'
                    options={options}
                    disableClearable
                    onBeforeInputCapture={(e) => {
                      e.preventDefault();
                    }}
                  />
                </FormControl>
              )}

              {isYandex && (
                <Stack direction='row' spacing={2}>
                  <FormControl>
                    <UIFormLabel>Yandex GO:</UIFormLabel>
                    <FormSelectInput
                      control={control}
                      name='yandexTaxiSubcommitentFilter'
                      options={options}
                      disableClearable
                      onBeforeInputCapture={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <UIFormLabel>Yandex Delivery:</UIFormLabel>
                    <FormSelectInput
                      control={control}
                      name='yandexDeliverySubcommitentFilter'
                      options={options}
                      disableClearable
                      onBeforeInputCapture={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </FormControl>
                </Stack>
              )}
            </Stack>
          </Stack>
        </ModalTemplate>
      </Drawer>
    );
  }
);
