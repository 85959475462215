import { useAppSelector } from "@/app/hooks";
import { CabinetType } from "@/entities/app/model";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

interface Props extends PropsWithChildren {
  cabinetTypeCheck?: CabinetType;
  checkIsLogged?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({
  children,
  cabinetTypeCheck,
  checkIsLogged = true,
}) => {
  const { authorized } = useAppSelector((state) => state.auth);
  const { cabinetType } = useAppSelector((state) => state.app);

  const check = () => {
    if (checkIsLogged && !authorized) {
      return <Navigate to="/" replace />;
    }

    // if (cabinetTypeCheck && cabinetType !== cabinetTypeCheck) {
    //   return <Navigate to="/balance" replace />;
    // }

    return children;
  };

  return check();
};
