import { useMemo, useRef, useState } from 'react';
import { Layout } from '@/shared/layout';
import { UISection } from '@/shared/ui/ui-section';
import { Box, ButtonGroup, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { UIButton } from '@/shared/ui/ui-button';
import { BaseModalRef, checkIfHasPermission, getDaysString, handleError } from '@/app/helpers';
import { AddEmployeeModal } from '@/shared/modals/add-employee-modal';
import { useAppSelector, useFilters } from '@/app/hooks';
import { useForm } from 'react-hook-form';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { EmployeesTable } from './ui/employees-table';
import { UIAlert } from '@/shared/ui/ui-alert';
import notify from '@/shared/toaster/lib/notify';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import ConfirmModal from '@/shared/confirm-modal';
import { ExcelUploadHistoryModal } from '@/features/excel-upload-history-modal';
import { EmployeesFilters } from './ui/employees-filters';
import { ImportYandexDriversHistoryModal } from '../customer-mutual-settlements-page/ui/import-yandex-drivers-histroy-modal';
import { ImportYandexDriversModal } from '../customer-mutual-settlements-page/ui/import-yandex-drivers-modal';
import PermissionCheckHOC from '../../shared/permission-hoc';
import {
  useCheckSubscriptionQuery,
  useDeleteWorkersMutation,
  useExportCompanyWorkersExcelMutation,
  useGetCompanyWorkersCountersQuery,
  useGetCompanyWorkersQuery,
  useLazyGetImportWorkersFromYandexActiveDriversCountQuery,
  useMultipleSendingInviteManuallyMutation,
} from '@/app/api';
import _ from 'lodash';

import { PlusIcon } from '@/shared/icons';

import i18n from '@/app/i18n';
import { useTranslation } from 'react-i18next';
import { GetEmployeesFilterParams } from '@/app/api/model';
import { AddEmployeeModalRef } from '@/shared/modals/add-employee-modal/types';
import { SIZES, WorkerStatus } from '@/app/constants';
import { ConfirmModalRef } from '@/shared/confirm-modal/types';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { EmployeesFormValues } from './types';
import { Colors } from '@/app/constants/colors';

export const EmployeesPage = () => {
  const { t } = useTranslation();
  const { company, roles } = useAppSelector((state) => state.auth);

  const initialFilterParams: GetEmployeesFilterParams = {
    page: 1,
    pageSize: 10,
    yourCommitentFilter: '',
    uzumSubcommitentFilter: '',
    yandexTaxiSubcommitentFilter: '',
    yandexDeliverySubcommitentFilter: '',
    selfEmployedFilter: '',
    // * new properties according to DID-687
    actTypes: [],
    counterFilter: WorkerStatus.ALL,
    status: WorkerStatus.ALL,
    excludedActTypes: [],
    // ! expired properties according to DID-687
    // selfEmployedTaxiFilter: '',
    // selfEmployedDeliveryFilter: '',
  };

  const form = useForm<EmployeesFormValues>({
    defaultValues: {
      counterFilter: {
        id: WorkerStatus.ALL,
        name: t('all'),
      },
      yourCommitentFilter: {
        value: '',
        label: t('all'),
      },
      uzumSubcommitentFilter: {
        value: '',
        label: t('all'),
      },
      yandexTaxiSubcommitentFilter: {
        value: '',
        label: t('all'),
      },
      yandexDeliverySubcommitentFilter: {
        value: '',
        label: t('all'),
      },
      selfEmployedFilter: {
        value: '',
        label: t('all'),
      },
      status: {
        id: WorkerStatus.ALL,
        name: t('all'),
      },
      activityFilters: company?.actTypes.reduce(
        (acc, type) => ({
          ...acc,
          [type.code]: { value: '', label: t('all') },
        }),
        {}
      ),
    },
  });
  const { control, handleSubmit } = form;

  const { filterParams, onChangeFilterParams } = useFilters<GetEmployeesFilterParams>({
    initialFilterParams,
  });

  const [selected, setSelected] = useState<number[]>([]);
  const [uzumModalRef, setUzumModalRef] = useState<AddEmployeeModalRef | null>(null);

  const confirmRef = useRef<ConfirmModalRef>(null);
  const excelUploadHistoryModalRef = useModalRef();
  const filtersRef = useModalRef();
  const importYandexModalRef = useRef<BaseModalRef>(null);
  const importYandexHistoryModalRef = useRef<BaseModalRef>(null);

  const { data, refetch, isFetching } = useGetCompanyWorkersQuery(filterParams);
  const { data: tariffData } = useCheckSubscriptionQuery({});
  const { data: statusesCounter, refetch: refetchStatusesCounter } =
    useGetCompanyWorkersCountersQuery(_.omit(filterParams, ['counterFilter', 'search']));

  const [multipleSendInvite, { isLoading: isSendingInviteLoading }] =
    useMultipleSendingInviteManuallyMutation();
  const [downloadExcel, { isLoading: isDownloadExcelLoading }] =
    useExportCompanyWorkersExcelMutation();

  const [deleteWorkers, { isLoading: isDeleteLoading }] = useDeleteWorkersMutation();

  const [getImportWorkersCount, { isFetching: isYandexImportCountFetching }] =
    useLazyGetImportWorkersFromYandexActiveDriversCountQuery();

  const selectedWorkers = (data?.data ?? []).filter((_el, idx) => selected.includes(idx));

  const onSearchSubmit = (data: EmployeesFormValues) => {
    const actTypes: Array<number> = [];
    const excludedActTypes: Array<number> = [];
    Object.entries(data.activityFilters).forEach(([code, type]) => {
      if (typeof type.value === 'boolean') {
        if (type.value) actTypes.push(Number(code));
        else excludedActTypes.push(Number(code));
      }
    });
    _.unset(data, 'activityFilters');

    onChangeFilterParams({
      ...data,
      page: 1,
      counterFilter: data.counterFilter.id,
      status: data.status.id,
      yourCommitentFilter: data.yourCommitentFilter.value,
      uzumSubcommitentFilter: data.uzumSubcommitentFilter.value,
      selfEmployedFilter: data.selfEmployedFilter.value,
      yandexTaxiSubcommitentFilter: data.yandexTaxiSubcommitentFilter.value,
      yandexDeliverySubcommitentFilter: data.yandexDeliverySubcommitentFilter.value,
      actTypes,
      excludedActTypes,
    });
  };

  const handleSendInvite = async () => {
    try {
      await multipleSendInvite({
        unique: selected.map((idx) => data?.data?.[idx]?.unique).filter(Boolean) as number[],
      }).unwrap();

      setSelected([]);
      notify(t('toast.success'), 'success');
    } catch (error) {
      handleError(error);
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const res = await downloadExcel(filterParams).unwrap();

      const link = document.createElement('a');
      link.download = 'employees.xlsx';
      link.href = res;
      link.click();
    } catch (error) {
      handleError(error);
    }
  };

  const onMassDeleteHandler = async () => {
    if (!data) return;
    try {
      await confirmRef.current?.init({
        title: t('delete_employees_confirm_title'),
        text: '',
      });
      const workers = selectedWorkers.map((el) => el.unique);
      await deleteWorkers({ workers }).unwrap();
      setSelected([]);
      notify(t('employees_deleted_successfully'), 'success');
    } catch (error) {
      handleError(error);
    }
  };

  const onImportYandexModalOpen = async () => {
    await getImportWorkersCount({}).unwrap();
    importYandexModalRef.current?.open();
  };

  const isTariffMaxUsers =
    tariffData?.data?.activatedUsers && tariffData?.data?.activatedUsersLimit
      ? tariffData.data.activatedUsers >= tariffData.data.activatedUsersLimit
      : false;

  const isTariifNotPurchased = tariffData?.success === false;
  const daysLeft = tariffData?.data?.daysLeft ?? 0;

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18722']), [roles]);

  return (
    <Layout>
      {tariffData && daysLeft <= 10 && daysLeft !== 0 && (
        <UIAlert
          type='warning'
          text={t('errors:tariff_days_left', {
            days: daysLeft,
            dayString: getDaysString(daysLeft),
          })}
          closable
          sx={{
            maxWidth: '100%',
            mb: 2,
          }}
        />
      )}
      {tariffData && (
        <>
          {isTariffMaxUsers && (
            <UIAlert
              type='warning'
              text={t('errors:tariff_max_users')}
              closable
              sx={{
                maxWidth: '100%',
                mb: 2,
              }}
            />
          )}

          {isTariifNotPurchased && (
            <UIAlert
              type='warning'
              text={t('errors:not_purchased_tariff_error')}
              closable
              sx={{
                maxWidth: '100%',
                mb: 2,
              }}
            />
          )}
        </>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={1}>
          {company?.invitationFlow === 'yandex' && (
            <>
              <Tooltip
                title={
                  isFetching
                    ? ''
                    : data?.importWorkersFromYandexApiIsOk
                    ? ''
                    : t('fill_yandex_api_tooltip')
                }
              >
                <Box component='span'>
                  <UIButton
                    onClick={onImportYandexModalOpen}
                    disabled={!data?.importWorkersFromYandexApiIsOk}
                    loading={isYandexImportCountFetching}
                  >
                    {t('upload_from_yandex_go')}
                  </UIButton>
                </Box>
              </Tooltip>
              <UIButton
                variant='outlined'
                color='secondary'
                onClick={() => {
                  importYandexHistoryModalRef.current?.open();
                }}
              >
                {t('import_history')}
              </UIButton>
            </>
          )}
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={2} mb={2}>
          <ButtonGroup variant='outlined'>
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                color: 'secondary',
                onClick: handleDownloadExcel,
                loading: isDownloadExcelLoading,
                disabled: !hasPermission,
                children: t('excel_download'),
              }}
            />

            <UIButton
              color='secondary'
              onClick={() => {
                excelUploadHistoryModalRef.current?.open();
              }}
            >
              {t('history')}
            </UIButton>
          </ButtonGroup>
          <PermissionCheckHOC
            WrappedComponent={UIButton}
            props={{
              type: 'submit',
              endIcon: <PlusIcon />,
              onClick: () => {
                uzumModalRef?.open();
              },
              disabled:
                tariffData?.success === false ||
                Number(tariffData?.data?.activatedUsers) >=
                  Number(tariffData?.data?.activatedUsersLimit) ||
                !hasPermission,
              children: t('add_employees'),
            }}
          />
        </Stack>
      </Stack>

      <UISection sx={{ height: '100%' }}>
        {statusesCounter?.statuses.length && (
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' spacing={2}>
              <Stack
                component='div'
                direction='row'
                alignItems='center'
                spacing={1.5}
                onClick={() => onChangeFilterParams({ ...filterParams, counterFilter: '' })}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  '::after': {
                    ...(!filterParams.counterFilter?.length && {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: '-15px',
                      height: '2px',
                      backgroundColor: Colors.ACCENT,
                    }),
                  },
                }}
              >
                <Typography variant='body2'>{t('total')}</Typography>
                <Typography
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  variant='body2'
                  sx={{
                    width: `${Math.max(
                      35,
                      20 + (statusesCounter.elements ?? 0).toString().length * 8
                    )}px`,
                    borderRadius: '20px',
                    color: filterParams.counterFilter?.length
                      ? Colors.SECONDARY
                      : Colors.WHITE_TEXT,
                    backgroundColor: filterParams.counterFilter?.length
                      ? Colors.LGRAY
                      : Colors.ACCENT,
                  }}
                >
                  {statusesCounter.elements ?? 0}
                </Typography>
              </Stack>

              {statusesCounter.statuses.map((counterFilter) => (
                <Stack
                  component='div'
                  key={counterFilter.id}
                  direction='row'
                  alignItems='center'
                  spacing={1.5}
                  onClick={() =>
                    onChangeFilterParams({
                      ...filterParams,
                      counterFilter: counterFilter.id,
                    })
                  }
                  sx={{
                    cursor: 'pointer',
                    position: 'relative',
                    '::after': {
                      ...(filterParams.counterFilter === counterFilter.id && {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: '-15px',
                        height: '2px',
                        backgroundColor: Colors.ACCENT,
                      }),
                    },
                  }}
                >
                  <Typography variant='body2'>
                    {i18n.language === 'ru' ? counterFilter.name : counterFilter.nameUz}
                  </Typography>
                  <Typography
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    variant='body2'
                    sx={{
                      width: `${Math.max(35, 20 + counterFilter.count.toString().length * 8)}px`,
                      borderRadius: '20px',
                      color:
                        filterParams.counterFilter === counterFilter.id
                          ? Colors.WHITE_TEXT
                          : Colors.SECONDARY,
                      backgroundColor:
                        filterParams.counterFilter === counterFilter.id
                          ? Colors.ACCENT
                          : Colors.LGRAY,
                    }}
                  >
                    {counterFilter.count ?? 0}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <UIButton color='inherit' onClick={refetchStatusesCounter}>
              {t('update_statuses')}
            </UIButton>
          </Stack>
        )}

        <Stack
          direction='row'
          justifyContent='space-between'
          spacing={2}
          flexWrap='wrap'
          useFlexGap
          mt={3}
        >
          <Stack direction='row' spacing={2}>
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                onClick: refetch,
                disabled: !hasPermission,
                children: t('update'),
              }}
            />

            {!!selected.length && (
              <PermissionCheckHOC
                WrappedComponent={UIButton}
                props={{
                  onClick: handleSendInvite,
                  loading: isSendingInviteLoading,
                  disabled: !hasPermission,
                  children: t('send_invites'),
                }}
              />
            )}

            {!!selectedWorkers.length &&
              selectedWorkers.every((el) => el.status !== WorkerStatus.ACTIVE) && (
                <PermissionCheckHOC
                  WrappedComponent={UIActionButton}
                  props={{
                    loading: isDeleteLoading,
                    onClick: onMassDeleteHandler,
                    disabled: !hasPermission,
                    icon: 'delete',
                  }}
                />
              )}
          </Stack>
          <Stack
            direction='row'
            spacing={1}
            component='form'
            onSubmit={handleSubmit(onSearchSubmit)}
          >
            <FormTextInput control={control} name='search' label={t('search')} />
            <UIButton type='submit'>{t('to_find')}</UIButton>

            <Select
              value={filterParams.pageSize}
              size='small'
              onChange={(e) => onChangeFilterParams({ pageSize: e.target.value as number })}
            >
              {SIZES.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>

            <UIButton
              color='secondary'
              variant='outlined'
              onClick={() => {
                filtersRef.current?.open();
              }}
            >
              {t('filter')}
            </UIButton>
          </Stack>
        </Stack>
        {uzumModalRef && (
          <EmployeesTable
            filterParams={filterParams}
            onChangeFilterParams={onChangeFilterParams}
            data={data ?? { data: [], totalPages: 0, success: true }}
            openAddEmployeeModal={uzumModalRef?.open}
            isLoading={isFetching}
            checkbox={{
              selected,
              setSelected,
            }}
          />
        )}
      </UISection>

      <ConfirmModal ref={confirmRef} />
      <AddEmployeeModal ref={setUzumModalRef} />
      <ExcelUploadHistoryModal ref={excelUploadHistoryModalRef} />
      <EmployeesFilters
        ref={filtersRef}
        form={form}
        onChangeFilterParams={onChangeFilterParams}
        initialFilterParams={initialFilterParams}
        filterParams={filterParams}
      />
      <ImportYandexDriversModal ref={importYandexModalRef} />
      <ImportYandexDriversHistoryModal ref={importYandexHistoryModalRef} />
    </Layout>
  );
};
