import { FormEventHandler, forwardRef, useImperativeHandle } from 'react';
import {
  useGetImportWorkersFromYandexActiveDriversCountQuery,
  useImportBotDriversFromYandexMutation,
  useImportWorkersFromYandexMutation,
} from '@/app/api';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, Typography } from '@mui/material';
import { numericFormatter } from 'react-number-format';
import { BaseModalRef, handleError } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import notify from '@/shared/toaster/lib/notify';

import { useTranslation } from 'react-i18next';

export const ImportYandexDriversModal = forwardRef<
  BaseModalRef,
  { mode?: 'employees' | 'settlements' }
>(({ mode = 'employees' }, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();

  const { data } = useGetImportWorkersFromYandexActiveDriversCountQuery({}, { skip: !open });
  const [startImport] = useImportWorkersFromYandexMutation();
  const [startBotImport] = useImportBotDriversFromYandexMutation();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit: FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    try {
      if (mode === 'employees') {
        await startImport({}).unwrap();
      } else await startBotImport({}).unwrap();

      onClose();
      notify(t('upload_is_started_successfully'), 'success');
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '560px',
          width: '100%',
        },
      }}
    >
      <ModalTemplate
        submitBtnText={t('start_upload')}
        title={t('import_drivers_from_yandex_go')}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <Typography variant='body2'>
          {t('import_drivers_from_yandex_go_text', {
            driversCount: numericFormatter(String(data?.total), { thousandSeparator: ',' }),
          })}
        </Typography>
      </ModalTemplate>
    </Dialog>
  );
});
