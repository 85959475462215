import { FC, useEffect, useMemo } from 'react';
import {
  useExportCalculationsTransactionsMutation,
  useGetCalculationsTransactionsQuery,
  useGetCalculationsTransactionsStatusesQuery,
} from '@/app/api';
import { useAppSelector, useFilters } from '@/app/hooks';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import FormSelectInput from '@/shared/inputs/form-autocomplete-input';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { PageSizeSelect } from '@/shared/page-size-select';
import { UIButton } from '@/shared/ui/ui-button';
import { UIChip } from '@/shared/ui/ui-chip';
import { UIChipProps } from '@/shared/ui/ui-chip/types';
import { UITable } from '@/shared/ui/ui-table';
import { Box, Stack, Typography } from '@mui/material';
import PermissionCheckHOC from '../../../shared/permission-hoc';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { numericFormatter } from 'react-number-format';
import { checkIfHasPermission, handleError } from '@/app/helpers';

import IncomeIcon from '@/app/assets/icons/add-circle.svg?react';
import ExpenseIcon from '@/app/assets/icons/minus-cirlce.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

interface MutualSettlementsTransactionsProps {
  setTotalSum: React.Dispatch<React.SetStateAction<number>>;
}

interface FormValues {
  dateFrom: Date;
  dateTo: Date;
  search: string;
  status: { id: string; name: string };
}

export const MutualSettlementsTransactions: FC<MutualSettlementsTransactionsProps> = ({
  setTotalSum,
}) => {
  const { t } = useTranslation();
  const { roles } = useAppSelector((state) => state.auth);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      search: '',
      status: { id: 'all', name: t('all') },
    },
  });

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      dateTo: '',
      dateFrom: '',
      page: 1,
      pageSize: 10,
      search: '',
      status: '',
    },
  });

  const { data, isFetching } = useGetCalculationsTransactionsQuery(filterParams);
  const { data: statuses } = useGetCalculationsTransactionsStatusesQuery({});
  const [downloadExcel, { isLoading: isDownloadExcelLoading }] =
    useExportCalculationsTransactionsMutation();

  useEffect(() => {
    if (typeof data?.amountTotal !== 'undefined') {
      setTotalSum(data?.amountTotal);
    }

    return () => {
      setTotalSum(0);
    };
  }, [data?.amountTotal]);

  const onSubmitHandler = ({ dateFrom, dateTo, search, status }: FormValues) => {
    onChangeFilterParams({
      dateFrom: dayjs(dateFrom).isValid() ? dayjs(dateFrom).format('DD.MM.YYYY') : '',
      dateTo: dayjs(dateTo).isValid() ? dayjs(dateTo).format('DD.MM.YYYY') : '',
      search,
      status: status.id === 'all' ? '' : status.id,
      page: 1,
    });
  };

  const getStatus = (status: string): UIChipProps => {
    let color: UIChipProps['color'] = 'success';
    const statusOption = statuses?.data.find((item) => item.id === status);

    switch (status) {
      case 'canceled':
        color = 'error';
        break;
      case 'notCompleted':
        color = 'warning';
        break;
      default:
        break;
    }

    return {
      color,
      label: statusOption?.name ?? status,
    };
  };

  const handleDownloadExcel = async () => {
    try {
      const res = await downloadExcel(filterParams).unwrap();

      const link = document.createElement('a');
      link.download = 'transactions.xlsx';
      link.href = res;
      link.click();
    } catch (error) {
      handleError(error);
    }
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18732']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        justifyContent='space-between'
        component='form'
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Stack direction='row' spacing={2}>
          <FormDatePicker control={control} name='dateFrom' label={t('from')} format='DD.MM.YYYY' />
          <FormDatePicker control={control} name='dateTo' label={t('to')} format='DD.MM.YYYY' />

          <FormSelectInput
            control={control}
            name='status'
            options={[{ id: 'all', name: t('all') }, ...(statuses?.data ?? [])]}
            disableClearable
            TextFieldProps={{
              sx: {
                minWidth: '150px',
              },
            }}
          />
        </Stack>

        <Stack direction='row' spacing={1.5}>
          <FormTextInput control={control} name='search' label={t('search')} />
          <UIButton type='submit'>{t('to_find')}</UIButton>
          <PageSizeSelect
            value={filterParams.pageSize}
            onChange={({ target }) =>
              onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
            }
          />
          <PermissionCheckHOC
            WrappedComponent={UIButton}
            props={{
              color: 'secondary',
              variant: 'outlined',
              loading: isDownloadExcelLoading,
              onClick: handleDownloadExcel,
              disabled: !data?.data?.length || !hasPermission,
              children: t('excel_download'),
            }}
          />
        </Stack>
      </Stack>

      <UITable
        data={data?.data ?? []}
        EmptyPlaceholder={<EmptyPlaceholder />}
        isLoading={isFetching}
        columns={[
          {
            label: '№',
            render: (_row, index) => filterParams.pageSize * (filterParams.page - 1) + index + 1,
            nowrap: true,
          },
          {
            label: t('date'),
            render: ({ date }) => (
              <Typography variant='body2' color={Colors.BGRAY}>
                {date}
              </Typography>
            ),
          },
          { label: t('fio'), key: 'driverName' },
          { label: t('card_number'), key: 'cardNumber' },
          {
            label: t('card_owner_fio'),
            render: ({ cardOwner }) => (
              <Typography variant='body2' color={Colors.BGRAY}>
                {cardOwner}
              </Typography>
            ),
          },
          {
            label: t('initiator'),
            key: 'initiator',
          },
          {
            label: t('sum'),
            render: ({ amount, type }) => (
              <Stack direction='row' alignItems='center' spacing={1.5}>
                <Box component={type === 'replenishment' ? IncomeIcon : ExpenseIcon} />
                <Typography variant='h6'>
                  {numericFormatter(Number(amount / 100).toFixed(2), { thousandSeparator: ' ' })}
                </Typography>
              </Stack>
            ),
            align: 'right',
            // headerAlign: 'right',
          },
          {
            label: t('status'),
            render: ({ status }) => status && <UIChip {...getStatus(status)} />,
          },
        ]}
        PaginationProps={{
          count: data?.totalPages ?? 0,
          page: filterParams.page,
          onChange: (_e, page) => onChangeFilterParams({ page }),
        }}
      />
    </Stack>
  );
};
