import i18n from '@/app/i18n';
import { HeaderNavItem } from '../types';
import { checkIfHasPermission } from '../../../app/helpers';

const t = i18n.t.bind(i18n);

export const getHeaderNavData = (): HeaderNavItem[] => {
  return [
    {
      label: t('balance'),
      condition: ({ cabinetType, roles }) =>
        cabinetType === 'customer' && checkIfHasPermission(roles, ['18711', '18712']),
      to: '/balance',
    },
    {
      label: t('workers'),
      to: '/employees',
      condition: ({ cabinetType, roles }) =>
        cabinetType === 'customer' && checkIfHasPermission(roles, ['18721', '18722']),
    },
    // {
    //   label: t('tasks'),
    //   to: '/tasks',
    // },
    {
      label: t('document_flow'),
      to: '/documents',
      condition: ({ cabinetType, invitationFlow, roles }) =>
        cabinetType === 'customer' &&
        (invitationFlow === 'uzum' || invitationFlow === 'plovme' || invitationFlow === 'wolt') &&
        checkIfHasPermission(roles, ['18741', '18742']),
    },
    // {
    //   label: t('document_flow'),
    //   to: '/yandex/documents',
    //   condition: ({ cabinetType, invitationFlow, roles }) =>
    //     cabinetType === 'customer' &&
    //     invitationFlow === 'yandex' &&
    //     checkIfHasPermission(roles, ['18741', '18742']),
    // },
    {
      label: t('document_flow'),
      to: '/customer/documents',
      condition: ({ cabinetType, invitationFlow, roles }) =>
        cabinetType === 'customer' &&
        (invitationFlow === 'yandex' || invitationFlow === 'uklon') &&
        checkIfHasPermission(roles, ['18741', '18742']),
    },
    {
      label: t('mutual_settlements'),
      to: '/settlements',
      // condition: ({ cabinetType, invitationFlow }) =>
      //   cabinetType === 'customer' && invitationFlow === 'yandex',
      condition: ({ cabinetType, invitationFlow, roles }) =>
        cabinetType === 'customer' &&
        invitationFlow !== 'yemak' &&
        checkIfHasPermission(roles, ['18731', '18732']),
    },
  ];
};

export const getLandingNavData = (): HeaderNavItem[] => {
  return [
    { label: t('landing:how_it_works'), href: '/#how-it-works' },
    { label: t('landing:product_advantages'), href: '/#advantages' },
    { label: t('landing:our_partners'), href: '/#our-partners' },
  ];
};
