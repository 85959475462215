import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog } from '@mui/material';
import { CreateYandexReportComponent } from './create-yandex-report-component';
import { FilePicker } from '../../../shared/inputs/file-picker';
import { useForm } from 'react-hook-form';
import { useAppSelector, useOpen } from '@/app/hooks';
import { useAddReportMutation, useAddReportUklonMutation } from '@/app/api';
import { useNavigate } from 'react-router-dom';
import notify from '@/shared/toaster/lib/notify';

import { useTranslation } from 'react-i18next';
import { BaseModalRef, fileToBase64 } from '@/app/helpers';
import { AddReport, DocumentReportTypeId, GetDocumentReportTypes } from '@/app/api/model';

interface FormValues {
  type?: DocumentReportTypeId;
  uklonExcelFile?: File;
}

export interface CreateReportModalProps {
  typesData: GetDocumentReportTypes[] | undefined;
  isFetching: boolean;
}

export const CreateReportModal = forwardRef<BaseModalRef, CreateReportModalProps>(
  ({ typesData, isFetching }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { company } = useAppSelector((state) => state.auth);
    const [files, setFiles] = useState<File[]>([]);

    const { isUklon, isYandex } = useMemo(() => {
      const isUklon = company?.invitationFlow === 'uklon';
      const isYandex = company?.invitationFlow === 'yandex';

      return { isUklon, isYandex };
    }, [company]);

    const { control, handleSubmit, reset } = useForm<FormValues>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        type: 'travel',
      },
    });
    const { open, setOpen } = useOpen();

    const [addReportMutation, { isLoading: isYandexLoading }] = useAddReportMutation();
    const [addUklonReport, { isLoading: isUklonLoading }] = useAddReportUklonMutation();

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
      reset();
      setFiles([]);
    };

    const onSubmitHandler = async (data: FormValues) => {
      let reportsUnique;
      let isSuccess = false;

      if (isYandex) {
        if (data.type) {
          const body: AddReport = {
            type: data.type,
          };
          const { success, unique } = await addReportMutation(body).unwrap();
          reportsUnique = unique;
          isSuccess = success;
        }
      }

      if (isUklon) {
        const base64 = await fileToBase64(files[0]).then((res) => res.split(',')[1]);

        const { unique, success } = await addUklonReport({
          base64,
        }).unwrap();
        reportsUnique = unique;
        isSuccess = success;
      }

      notify('', 'success');
      onClose();
      if (isSuccess) {
        navigate(`/customer/reports/${reportsUnique}`);
      }
    };

    const renderModalBody = () => {
      switch (company?.invitationFlow) {
        case 'yandex':
          return <CreateYandexReportComponent control={control} typesData={typesData} />;
        case 'uklon':
          return (
            <FilePicker
              value={files}
              onChange={setFiles}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              showFiles={false}
              disabled={!!files.length}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '560px',
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={t('customer_trips_page.create_report')}
          submitBtnText={t('send')}
          onClose={onClose}
          loading={isFetching || isYandexLoading || isUklonLoading}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          {renderModalBody()}
        </ModalTemplate>
      </Dialog>
    );
  }
);
