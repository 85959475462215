import { FC, PropsWithChildren } from 'react';
import { FormControl, FormHelperText, Stack, StackProps } from '@mui/material';
import { UIFormLabel } from '../../../shared/ui/ui-form-label';
import { FormTextInput } from '../../../shared/inputs/form-text-input';
import { FormDatePicker } from '../../../shared/inputs/form-date-picker';
import { UseFormReturn } from 'react-hook-form';
import { validateDateValues } from '../helpers/scripts';
import _, { DebouncedFunc } from 'lodash';

import { useTranslation } from 'react-i18next';
import { ChangeYandexRowModalFormValues } from '../types';
import { Dayjs } from 'dayjs';
import { Colors } from '../../../app/constants/colors';

interface YandexRowModalBodyProps {
  yandexForm: UseFormReturn<ChangeYandexRowModalFormValues, any, undefined>;
  isTravelReport: boolean;
  isRewardReport: boolean;
  debounceOnChange: DebouncedFunc<(value: string | undefined) => Promise<void>>;
}

const StackComponent: FC<PropsWithChildren<StackProps>> = ({ children, ...rest }) => {
  return (
    <Stack direction='row' justifyContent='space-between' spacing={1.5} {...rest}>
      {children}
    </Stack>
  );
};

export const YandexRowModalBody: FC<YandexRowModalBodyProps> = ({
  yandexForm,
  isRewardReport,
  isTravelReport,
  debounceOnChange,
}) => {
  const { t } = useTranslation();

  const { control, watch, getValues, setValue } = yandexForm;

  const fullName = watch('name') ?? '';

  return (
    <Stack spacing={2}>
      {isTravelReport && (
        <StackComponent>
          <FormControl required fullWidth>
            <UIFormLabel>{t('customer_trips_page.contract_number')}</UIFormLabel>
            <FormTextInput
              control={control}
              name='contractNumber'
              rules={{ required: true }}
              placeholder={t('customer_trips_page.contract_number')}
            />
          </FormControl>

          <FormControl required fullWidth>
            <UIFormLabel>{t('customer_trips_page.contract_date')}</UIFormLabel>
            <FormDatePicker
              control={control}
              name='contractDate'
              rules={{
                required: true,
                validate: (value) => {
                  const currentDate = value as Dayjs;
                  return validateDateValues(currentDate, t);
                },
              }}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </FormControl>
        </StackComponent>
      )}
      <FormControl required fullWidth>
        <UIFormLabel required>{t('pinfl')}</UIFormLabel>
        <FormTextInput
          control={control}
          name='pinfl'
          placeholder={t('pinfl')}
          onChange={async (event) => {
            const value = event.target.value;
            await debounceOnChange(value);
          }}
          regExp={/^\d+$/}
          rules={{
            required: true,
            validate: (val) => (typeof val === 'string' ? /^\d{14}$/.test(val) : false),
          }}
        />
      </FormControl>
      {fullName && <FormHelperText sx={{ color: Colors.INFO }}>{fullName}</FormHelperText>}
      {!isRewardReport && (
        <FormControl required fullWidth>
          <UIFormLabel required>{t('customer_trips_page.total_sum')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='amount'
            placeholder={t('customer_trips_page.total_sum')}
            onChange={(event) => {
              const taxiFleetPercent = getValues('taxiPercent');
              const aggregatorPercent = getValues('aggregatorPercent');
              if (taxiFleetPercent) {
                const taxiFleetFee = _.multiply(
                  +event.target.value,
                  _.divide(+taxiFleetPercent, 100)
                );
                setValue('taxiCommission', taxiFleetFee);
              }

              if (aggregatorPercent) {
                const aggregatorFee = _.multiply(
                  +event.target.value,
                  _.divide(+aggregatorPercent, 100)
                );
                setValue('aggregatorCommission', aggregatorFee);
              }
            }}
            regExp={/^\d+$/}
            rules={{
              required: true,
              validate: (val) => !!Number(val),
            }}
          />
        </FormControl>
      )}
      {!isRewardReport && (
        <StackComponent>
          <FormControl fullWidth>
            <UIFormLabel>{t('customer_trips_page.aggregator_percent')}</UIFormLabel>
            <FormTextInput
              control={control}
              name='aggregatorPercent'
              placeholder={t('customer_trips_page.aggregator_percent')}
              onChange={(event) => {
                const amount = getValues('amount');
                if (amount) {
                  const aggregatorFee = _.multiply(amount, _.divide(+event.target.value, 100));
                  setValue('aggregatorCommission', aggregatorFee);
                }
              }}
              regExp={/^([0-9]|[1-9][0-9]|100)$/}
            />
          </FormControl>

          <FormControl fullWidth>
            <UIFormLabel>{t('customer_trips_page.taxi_fleet_percent')}</UIFormLabel>
            <FormTextInput
              control={control}
              name='taxiPercent'
              placeholder={t('customer_trips_page.taxi_fleet_percent')}
              onChange={(event) => {
                const amount = getValues('amount');
                if (amount) {
                  const taxiFleetFee = _.multiply(amount, _.divide(+event.target.value, 100));
                  setValue('taxiCommission', taxiFleetFee);
                }
              }}
              regExp={/^([0-9]|[1-9][0-9]|100)$/}
            />
          </FormControl>
        </StackComponent>
      )}

      {isRewardReport && (
        <FormControl required fullWidth>
          <UIFormLabel required>{t('customer_trips_page.drivers_bonuses')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='taxiDriverReward'
            placeholder={t('customer_trips_page.drivers_bonuses')}
            regExp={/^\d+$/}
            rules={{
              required: true,
            }}
          />
        </FormControl>
      )}
    </Stack>
  );
};
