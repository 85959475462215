import { handleError } from "@/app/helpers";
import { useAppDispatch } from "@/app/hooks";
import {
  useAuthVerifyWorkerMutation,
  useAuthWorkerMutation,
} from "@/entities/auth/api";
import { authEmployee } from "@/entities/auth/model/slice";
import FormFormattedInput from "@/shared/inputs/form-formatted-input";
import { Layout } from "@/shared/layout";
import { SmsConfirmModal } from "@/shared/modals/sms-confirm-modal";
import { SmsConfirmModalRef } from "@/shared/modals/sms-confirm-modal/types";
import { UIButton } from "@/shared/ui/ui-button";
import { UIFormLabel } from "@/shared/ui/ui-form-label";
import { Box, Container, FormControl, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface FormValues {
  phone: string;
}

export const EmployeeAuthPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues: {
      phone: "",
    },
  });

  const smsModalRef = useRef<SmsConfirmModalRef>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [authWorker] = useAuthWorkerMutation();
  const [authVerify, { isLoading: isAuthLoading }] =
    useAuthVerifyWorkerMutation();

  const dispatch = useAppDispatch();

  const onSubmitHandler = async (data: FormValues) => {
    try {
      const parsedPhone = "998" + data.phone.replace(/\D/g, "");
      setIsLoading(true);
      await authWorker({ p: parsedPhone }).unwrap();
      await smsModalRef.current?.init(data.phone, async (code) => {
        await onSmsConfirmHandler(code, parsedPhone);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSmsConfirmHandler = async (code: string, phone: string) => {
    try {
      const res = await authVerify({ code, phone }).unwrap();

      dispatch(
        authEmployee({
          token: res.authToken,
          userData: res.data,
        })
      );
      navigate("/tasks");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Layout
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Container>
        <Stack
          alignItems="center"
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack
            alignItems="center"
            sx={{
              maxWidth: "320px",
              width: "100%",
              mt: 8,
            }}
          >
            <Typography variant="h5">{t("authorization")}</Typography>

            <FormControl
              required
              sx={{
                mt: 2,
                mb: 4,
              }}
            >
              <UIFormLabel>{t("phone_number")}:</UIFormLabel>

              <FormFormattedInput
                autoFocus
                control={control}
                name="phone"
                mask={""}
                rules={{
                  required: true,
                  minLength: {
                    value: 14,
                    message: t("errors:phone_length"),
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Box
                      sx={{
                        mr: 1,
                      }}
                    >
                      +998
                    </Box>
                  ),
                }}
                format="(##) ### ## ##"
                placeholder="(99) 999 99 99"
              />
            </FormControl>

            <UIButton
              type="submit"
              loading={isLoading}
              sx={{
                width: "100%",
              }}
            >
              {t("login")}
            </UIButton>
            {/* 
            <Typography
              variant="body2"
              sx={{
                my: 1,
                color: Colors.BGRAY,
              }}
            >
              {t("or")}
            </Typography>

            <UIButton
              color="inherit"
              sx={{
                width: "100%",
              }}
            >
              {t("login_with_faceid")}
            </UIButton> */}

            {/* <Box
              mt={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="subtitle2">
                {t("not_registered_yet")}{" "}
                <Box
                  component={Link}
                  to="/registration/employee"
                  sx={{
                    display: "inline",
                  }}
                >
                  {t("to_register")}
                </Box>
              </Typography>
            </Box> */}
          </Stack>
        </Stack>
      </Container>

      <SmsConfirmModal
        ref={smsModalRef}
        loading={isAuthLoading}
        length={6}
        reSendSms={async () => {
          await authWorker({
            p: "998" + getValues("phone").replace(/\D/g, ""),
          }).unwrap();
        }}
      />
    </Layout>
  );
};
