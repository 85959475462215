import { forwardRef, useImperativeHandle } from 'react';
import { Drawer, FormControl, Stack } from '@mui/material';
import FormAutocompleteInput from '@/shared/inputs/form-autocomplete-input';
import ModalTemplate from '@/shared/modal-template';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import { BaseModalRef } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { CustomerDocsFiltersProps, YandexDocsFormValues } from '../types';

export const CustomerDocsFilterComponent = forwardRef<BaseModalRef, CustomerDocsFiltersProps>(
  (
    {
      form,
      onChangeFilterParams,
      initialFilterParams,
      typesData = [],
      statusesData = [],
      closingDocumentTypesData = [],
      documentStatusesData = [],
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = form;

    const { open, setOpen } = useOpen();

    useImperativeHandle(
      ref,
      () => ({
        open: onOpen,
        close: onClose,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onSubmitHandler = (data: YandexDocsFormValues) => {
      onChangeFilterParams({
        ...data,
        type: data?.type?.id,
        status: data?.status?.id,
        dateFrom: data.dateFrom ? dayjs(data.dateFrom).format('DD.MM.YYYY') : '',
        dateTo: data.dateTo ? dayjs(data.dateTo).format('DD.MM.YYYY') : '',
      });
      onClose();
    };

    const onResetHandler = () => {
      reset();
      onChangeFilterParams(initialFilterParams);
      onClose();
    };

    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '480px',
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={t('filter')}
          submitBtnText={t('apply')}
          cancelBtnText={t('reset')}
          onClose={onResetHandler}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack spacing={2}>
            <FormControl fullWidth>
              <UIFormLabel>{t('period')}:</UIFormLabel>
              <Stack direction='row' spacing={1}>
                <FormDatePicker control={control} name='dateFrom' label={t('from')} />
                <FormDatePicker control={control} name='dateTo' label={t('to')} />
              </Stack>
            </FormControl>

            {!!typesData.length && (
              <FormControl>
                <UIFormLabel>{t('type')}:</UIFormLabel>

                <FormAutocompleteInput
                  control={control}
                  name='type'
                  options={typesData}
                  getOptionLabel={(option) => (typeof option === 'object' ? option.name : '')}
                  TextFieldProps={{
                    placeholder: t('empty_placeholder'),
                  }}
                />
              </FormControl>
            )}

            {!!closingDocumentTypesData?.length && (
              <FormControl>
                <UIFormLabel>{t('type')}:</UIFormLabel>

                <FormAutocompleteInput
                  control={control}
                  name='type'
                  options={closingDocumentTypesData}
                  getOptionLabel={(option) => (typeof option === 'object' ? option.name : '')}
                  TextFieldProps={{
                    placeholder: t('empty_placeholder'),
                  }}
                />
              </FormControl>
            )}

            {!!statusesData.length && (
              <FormControl>
                <UIFormLabel>{t('status')}:</UIFormLabel>

                <FormAutocompleteInput
                  control={control}
                  name='status'
                  options={statusesData}
                  getOptionLabel={(option) => (typeof option === 'object' ? option.name : '')}
                  TextFieldProps={{
                    placeholder: t('empty_placeholder'),
                  }}
                />
              </FormControl>
            )}

            {!!documentStatusesData.length && (
              <FormControl>
                <UIFormLabel>{t('status')}:</UIFormLabel>

                <FormAutocompleteInput
                  control={control}
                  name='status'
                  options={documentStatusesData}
                  getOptionLabel={(option) => (typeof option === 'object' ? option.name : '')}
                  TextFieldProps={{
                    placeholder: t('empty_placeholder'),
                  }}
                />
              </FormControl>
            )}
          </Stack>
        </ModalTemplate>
      </Drawer>
    );
  }
);
