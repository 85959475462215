import {
  Box,
  BoxProps,
  ButtonBase,
  Stack,
  SxProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import XmarkIcon from '@/app/assets/icons/close-circle.svg?react';
import { UIButton } from '../ui/ui-button';

interface Props {
  loading?: boolean;
  title?: string;
  submitBtnText?: string;
  cancelBtnText?: string;
  submitBtnColor?: 'primary' | 'error';
  onSubmit?: React.FormEventHandler<HTMLDivElement>;
  onClose?: () => void;
  customCancelFunction?: () => void;
  children?: React.ReactNode;
  TitleProps?: TypographyProps;
  disabled?: boolean;
  noBtns?: boolean;
  ContainerProps?: BoxProps;
  absoluteCloseBtn?: boolean;
  sx?: SxProps;
  CloseBtnSx?: SxProps;
}

const ModalTemplate: React.FC<Props> = ({
  loading,
  title,
  onSubmit,
  onClose,
  submitBtnText,
  cancelBtnText,
  children,
  submitBtnColor,
  TitleProps,
  disabled,
  noBtns = false,
  customCancelFunction,
  ContainerProps,
  absoluteCloseBtn,
  CloseBtnSx,
  sx,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        ...sx,
      }}
      {...(onSubmit && {
        component: 'form',
        onSubmit,
      })}
    >
      <Stack direction='row-reverse' alignItems='center' justifyContent='space-between'>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              mr: '-8px',
              ...(absoluteCloseBtn && {
                position: 'absolute',
                right: '20px',
                mr: 0,
              }),
              ...CloseBtnSx,
            }}
          >
            <XmarkIcon />
          </ButtonBase>
        )}
        <Typography
          variant='h6'
          flex={1}
          sx={{
            lineHeight: 1,
          }}
          {...TitleProps}
        >
          {title}
        </Typography>
      </Stack>
      <Box
        id='modal-template-body'
        {...ContainerProps}
        sx={{
          maxHeight: 'calc(100vh - 300px)',
          overflow: 'auto',
          mt: 2,
          mb: 5,
          ...ContainerProps?.sx,
        }}
      >
        {children}
      </Box>
      {!noBtns && (
        <Stack direction='row' justifyContent='flex-end' spacing={2.5}>
          {onClose && (
            <UIButton
              onClick={customCancelFunction ? customCancelFunction : onClose}
              color='inherit'
              disabled={loading}
            >
              {cancelBtnText || t('cancel')}
            </UIButton>
          )}
          {onSubmit && (
            <UIButton type='submit' disabled={disabled} loading={loading} color={submitBtnColor}>
              {submitBtnText || t('save')}
            </UIButton>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default ModalTemplate;
