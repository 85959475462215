import { Colors } from '@/app/constants/colors';
import { Box, CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type Props = CircularProgressProps;

export const UILoader: FC<Props> = (props) => {
  return (
    <Stack alignItems='center' justifyContent='center' sx={{ minHeight: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
        }}
      >
        <CircularProgress
          {...props}
          sx={{
            '& circle': {
              strokeWidth: '2.5',
            },
            ...props.sx,
            color: Colors.LGRAY,
            position: 'absolute',
            left: 0,
          }}
          variant='determinate'
          value={100}
        />
        <CircularProgress
          {...props}
          sx={{
            '& circle': {
              strokeWidth: '2.5',
            },
            color: Colors.ACCENT,
            ...props.sx,
          }}
        />
      </Box>
      {props.title && (
        <Typography
          sx={{
            color: Colors.BGRAY,
          }}
        >
          {props.title}
        </Typography>
      )}
    </Stack>
  );
};
