import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  useLazyGetCardInfoQuery,
  useLazyGetDistrictsQuery,
  useLazyGetInvitationInfoQuery,
  useLazyGetRegionsQuery,
  useLazyGetVillagesQuery,
} from '@/app/api';
import {
  useLazyGetAgentContractByPinflQuery,
  useLazyGetAssignmentContractByPinflQuery,
  useLazyGetGuaranteeAgreementQuery,
  useLazyGetOfferContentQuery,
  useRegisterWorkerMutation,
} from '@/entities/auth/api';
import FormCheckboxInput from '@/shared/inputs/form-checkbox-input';
import FormFormattedInput from '@/shared/inputs/form-formatted-input';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import notify from '@/shared/toaster/lib/notify';
import { UIAlert } from '@/shared/ui/ui-alert';
import { UIButton } from '@/shared/ui/ui-button';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { UILoader } from '@/shared/ui/ui-loader';
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { EmployeeRegistrationContext } from '../lib/employee-registration-context';
import { useAppDispatch } from '@/app/hooks';
import { logout } from '@/entities/auth/model/slice';
import { setFullscreenLoaderVisibility } from '@/entities/app/model/slice';
import { BaseModalRef, handleError } from '@/app/helpers';
import { OfferModal } from '@/shared/modals/offer-modal';
import FormAutocompleteInput from '@/shared/inputs/form-autocomplete-input';

import { Trans, useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { UIStepperRef } from '@/shared/ui/ui-stepper/types';
import { BaseLocationData } from '@/app/api/model';

interface FormValues {
  pinfl: string;
  phone: string;
  name: string;
  card: string;
  acceptOffer: boolean;
  village?: Omit<BaseLocationData, 'nameRu'>;
  district?: BaseLocationData;
  region?: BaseLocationData;
  cardOwnerName?: string;
}

interface Props {
  stepperRef: UIStepperRef | null;
}

export const EmployeeRegistrationStep: FC<Props> = ({ stepperRef }) => {
  const { t, language } = useTranslation()[1];
  const navigate = useNavigate();

  const { setUserData, userData } = useContext(EmployeeRegistrationContext);
  const { control, handleSubmit, watch, reset, setValue } = useForm<FormValues>();
  const [hasCamera, setHasCamera] = useState<boolean>();

  const watchAcceptOffer = watch('acceptOffer');
  const watchedCard = watch('card') ?? '';

  const [getInvitationInfo, { isError, error }] = useLazyGetInvitationInfoQuery();
  const [registerWorker, { isLoading }] = useRegisterWorkerMutation();
  const [getGuaranteeAgreement, { data: offerPdf }] = useLazyGetGuaranteeAgreementQuery();
  const [getOfferContent, { data: offerContent }] = useLazyGetOfferContentQuery();
  const [getCardInfo, { data: cardInfo, isError: isCardInfoError }] = useLazyGetCardInfoQuery();
  const [getAgentContractByPinfl, { data: agentPdf }] = useLazyGetAgentContractByPinflQuery();
  const [getAssignmentContract, { data: assignmentContractPdf }] =
    useLazyGetAssignmentContractByPinflQuery();
  const [getRegions, { data: regions, isFetching: isRegionsLoading }] = useLazyGetRegionsQuery();
  const [getDistricts, { data: districts, isFetching: isDistrictsLoading }] =
    useLazyGetDistrictsQuery();
  const [getVillages, { data: villages, isFetching: isVillagesLoading }] =
    useLazyGetVillagesQuery();

  const urlParams = new URLSearchParams(window.location.search);
  const invitationCode = urlParams.get('p');
  const isWolt = userData?.invitationFlow === 'wolt';

  const offerModalRef = useRef<BaseModalRef>(null);
  const guaranteeOfferModalRef = useRef<BaseModalRef>(null);
  const agentPdfRef = useRef<BaseModalRef>(null);
  const assignmentPdfRef = useRef<BaseModalRef>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    checkHasCamera();
  }, []);

  const checkHasCamera = async () => {
    try {
      // Проверяем, поддерживает ли браузер getUserMedia
      if (!invitationCode) {
        notify(t('errors:invitation_code_not_found'));
        return navigate('/');
      }

      dispatch(setFullscreenLoaderVisibility(true));
      dispatch(logout());
      const res = await getInvitationInfo({ p: invitationCode })
        .unwrap()
        .then((res) => res.data);

      setUserData({ ...res, invitationCode });
      reset({
        ...res,
        ...(typeof res.regionCode === 'number' && {
          region: { code: res.regionCode, name: res.regionName },
        }),
        ...(typeof res.districtCode === 'number' && {
          district: { code: res.districtCode, name: res.districtName },
        }),
        ...(typeof res.villageCode === 'number' && {
          village: { code: res.villageCode, name: res.villageName },
        }),
        phone: res?.phone?.slice(3),
      });
      dispatch(setFullscreenLoaderVisibility(false));

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Запрашиваем доступ к видео-устройству
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            stream.getTracks().forEach((el) => el.stop());
            setHasCamera(true);
          })
          .catch(() => setHasCamera(false));
      } else {
        // Браузер не поддерживает getUserMedia
        setHasCamera(false);
      }

      await getRegions({}).unwrap();
      if (res.regionCode) await getDistricts({ region: res.regionCode }).unwrap();
      if (res.districtCode) await getVillages({ district: res.districtCode }).unwrap();
    } catch (error) {
      handleError(error);
      setHasCamera(false);
      dispatch(setFullscreenLoaderVisibility(false));
    }
  };

  const onSubmitHandler = async (data: FormValues) => {
    if (!invitationCode) return;
    const token = Cookies.get('token');

    if (!token) {
      try {
        const token = await registerWorker({
          invitationCode,
          offerSigned: true,
          ...(data.village && { villageCode: data.village.code }),
          ...(isWolt && {
            cardNumber: data.card.replace(/\D/g, ''),
            cardOwnerName: data.cardOwnerName,
          }),
        })
          .unwrap()
          .then((res) => res.authToken);
        Cookies.set('token', token, { expires: 1, secure: true });
        stepperRef?.next(!userData?.faceIdRequired ? 2 : 1);
      } catch (err) {
        handleError(err);
      }
    } else {
      stepperRef?.next(!userData?.faceIdRequired ? 2 : 1);
    }
  };

  const getOffer = async () => {
    try {
      offerModalRef.current?.open();
      await getOfferContent({}).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  const getGuaranteeOffer = async () => {
    try {
      guaranteeOfferModalRef.current?.open();
      await getGuaranteeAgreement({}).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  const getAssignmentContractHandler = async () => {
    if (!userData) return;
    try {
      assignmentPdfRef.current?.open();
      await getAssignmentContract({ pinfl: userData?.pinfl, tin: userData?.inn }).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  const getContractPdfHandler = async () => {
    if (!userData) return;
    try {
      agentPdfRef.current?.open();
      await getAgentContractByPinfl({ pinfl: userData?.pinfl, tin: userData?.inn }).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  const getCardInfoHandler = async (value: string) => {
    const parsedValue = value.replace(/\D/g, '');

    if (parsedValue.length === 16) {
      try {
        const res = await getCardInfo({
          cardNumber: parsedValue,
        }).unwrap();
        setValue('cardOwnerName', res.data.name);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const render = () => {
    if (typeof hasCamera === 'undefined') return <UILoader size={40} />;

    if (error && isError && 'data' in error) {
      return <UIAlert type='warning' text={error?.data?.error?.msg} />;
    }

    // if (!hasCamera) {
    //   return <UIAlert type="warning" text={t("web_camera_access_error")} />;
    // }

    return (
      <Stack component='form' alignItems='center' onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack
          spacing={2}
          sx={{
            maxWidth: '320px',
            width: '100%',
          }}
        >
          <FormControl>
            <UIFormLabel>{t('pinfl')}:</UIFormLabel>
            <FormFormattedInput
              autoFocus
              type='tel'
              control={control}
              name='pinfl'
              format='##############'
              mask=''
              disabled
              rules={{
                required: true,
                pattern: {
                  value: /\d{14}/,
                  message: t('errors:pinfl_length'),
                },
              }}
            />
          </FormControl>
          <FormControl>
            <UIFormLabel>{t('fio')}:</UIFormLabel>
            <FormTextInput
              control={control}
              name='name'
              disabled
              rules={{
                required: true,
              }}
            />
          </FormControl>

          <FormControl required={isWolt}>
            <UIFormLabel>{t('employee_card_number')}</UIFormLabel>

            <FormFormattedInput
              control={control}
              name='card'
              disabled={!isWolt}
              format='#### #### #### ####'
              onChange={({ target }) => {
                getCardInfoHandler(target.value);
              }}
              rules={{
                required: isWolt,
                validate: (value) => {
                  if (isWolt) {
                    if ((typeof value === 'string' && value.length !== 19) || isCardInfoError) {
                      return t('card_number_error');
                    }
                  }
                  return true;
                },
              }}
            />
            <FormHelperText>
              {watchedCard.length === 19 && !isCardInfoError && cardInfo?.data?.name}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <UIFormLabel>{t('phone_number')}:</UIFormLabel>

            <FormFormattedInput
              control={control}
              name='phone'
              mask={''}
              disabled
              rules={{
                required: true,
              }}
              InputProps={{
                startAdornment: (
                  <Box
                    sx={{
                      mr: 1,
                    }}
                  >
                    +998
                  </Box>
                ),
              }}
              format='(##) ### ## ##'
              placeholder='(99) 999 99 99'
            />
          </FormControl>

          {userData?.invitationFlow === 'yandex' && userData.subCommissionerName && (
            <FormControl>
              <UIFormLabel>{t('subcommittent_for')}:</UIFormLabel>

              <TextField value={userData.subCommissionerName} disabled />
            </FormControl>
          )}

          {!!userData?.actTypes && !!userData?.actTypes.length && (
            <FormControl>
              <UIFormLabel>{t('activity')}:</UIFormLabel>

              <Typography variant='body2'>
                {userData?.actTypes?.reduce(
                  (acc, cur, index) =>
                    acc +
                    (language === 'ru' ? cur.nameShortRu : cur.nameShortUz) +
                    (index !== (userData.actTypes?.length ?? 0) - 1 ? ', ' : ''),
                  ''
                )}
              </Typography>
            </FormControl>
          )}

          <FormControl required>
            <UIFormLabel>{t('region')}:</UIFormLabel>

            <FormAutocompleteInput
              control={control}
              name='region'
              options={regions?.data ?? []}
              onChange={async (_, value) => {
                if (value) {
                  await getDistricts({ region: value.code });
                }
              }}
              rules={{
                required: true,
              }}
              getOptionLabel={(option) => {
                if (!regions?.data.length) return '';
                const exactOption = regions.data.find((el) => el.code === option.code);
                if (exactOption) {
                  return language === 'ru' ? exactOption.nameRu : exactOption.name;
                } else return option.name;
              }}
              loading={isRegionsLoading}
            />
            <FormHelperText>
              {t('place_of_business')} ({t('region').toLowerCase()})
            </FormHelperText>
          </FormControl>

          <FormControl required>
            <UIFormLabel>{t('district')}:</UIFormLabel>

            <FormAutocompleteInput
              control={control}
              name='district'
              options={districts?.data ?? []}
              disabled={!districts?.data.length}
              onChange={async (_, value) => {
                if (value) {
                  await getVillages({ district: value.code });
                }
              }}
              rules={{
                required: true,
              }}
              getOptionLabel={(option) => {
                if (!districts?.data.length) return '';
                const exactOption = districts.data.find((el) => el.code === option.code);
                if (exactOption) {
                  return language === 'ru' ? exactOption.nameRu : exactOption.name;
                } else return option.name;
              }}
              loading={isDistrictsLoading}
            />
            <FormHelperText>
              {t('place_of_business')} ({t('district').toLowerCase()})
            </FormHelperText>
          </FormControl>

          <FormControl required>
            <UIFormLabel>{t('village')}:</UIFormLabel>

            <FormAutocompleteInput
              control={control}
              name='village'
              options={villages?.data ?? []}
              rules={{
                required: true,
              }}
              getOptionLabel={(option) => option.name}
              disabled={!villages?.data.length}
              loading={isVillagesLoading}
            />
            <FormHelperText>
              {t('place_of_business')} ({t('village').toLowerCase()})
            </FormHelperText>
          </FormControl>
        </Stack>

        <FormCheckboxInput
          control={control}
          name='acceptOffer'
          sx={{
            mt: 2,
          }}
          label={
            <Typography>
              <Trans
                i18nKey={userData?.invitationFlow === 'uzum' ? 'accept_offer_uzum' : 'accept_offer'}
                components={[
                  <Box
                    component='span'
                    onClick={(event) => {
                      event.preventDefault();
                      getOffer();
                    }}
                    sx={{
                      color: Colors.ACCENT,
                      textDecoration: 'underline',
                    }}
                  />,
                  <Box
                    component='span'
                    onClick={(event) => {
                      event.preventDefault();
                      getGuaranteeOffer();
                    }}
                    sx={{
                      color: Colors.ACCENT,
                      textDecoration: 'underline',
                    }}
                  />,
                  <Box
                    component='span'
                    onClick={(event) => {
                      event.preventDefault();
                      getContractPdfHandler();
                    }}
                    sx={{
                      color: Colors.ACCENT,
                      textDecoration: 'underline',
                    }}
                  />,
                  <Box
                    component='span'
                    onClick={(event) => {
                      event.preventDefault();
                      getAssignmentContractHandler();
                    }}
                    sx={{
                      color: Colors.ACCENT,
                      textDecoration: 'underline',
                    }}
                  />,
                ]}
              />
            </Typography>
          }
        />

        <UIButton
          type='submit'
          disabled={!watchAcceptOffer}
          loading={isLoading}
          sx={{
            mt: 4,
          }}
        >
          {t('to_register')}
        </UIButton>
        <OfferModal
          ref={offerModalRef}
          pdf={'data:application/pdf;base64,' + offerContent?.data?.pdf || ''}
          title={t('guarantee_offer')}
          noBtns
        />
        <OfferModal
          ref={guaranteeOfferModalRef}
          pdf={'data:application/pdf;base64,' + offerPdf?.data?.pdf || ''}
          title={t('offer')}
          noBtns
        />
        <OfferModal
          ref={agentPdfRef}
          pdf={'data:application/pdf;base64,' + agentPdf?.document || ''}
          title={t('offer')}
          noBtns
        />
        <OfferModal
          ref={assignmentPdfRef}
          pdf={'data:application/pdf;base64,' + assignmentContractPdf?.document || ''}
          title={t('offer')}
          noBtns
        />
      </Stack>
    );
  };

  return (
    <Container>
      <Typography variant='h5' textAlign='center'>
        {t('worker_registration')}
      </Typography>

      <Stack alignItems='center' sx={{ mt: 3 }}>
        {render()}
      </Stack>
    </Container>
  );
};
