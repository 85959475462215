import { useEffect, useRef } from 'react';
import { Layout } from '@/shared/layout';
import { OfferModal } from '@/shared/modals/offer-modal';
import { useNavigate } from 'react-router-dom';
import { setFullscreenLoaderVisibility } from '@/entities/app/model/slice';
import {
  useAuthCustomerMutation,
  useLazyGetOfferContentQuery,
  useSignOfferMutation,
} from '@/entities/auth/api';
import { authCustomer, logout } from '@/entities/auth/model/slice';
import { eimzo } from '@/entities/eimzo';
import Cookies from 'js-cookie';
import { BaseModalRef, getDefaultLink, handleError } from '@/app/helpers';
import { useAppDispatch } from '@/app/hooks';

export const AuthCustomerPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [authCustomerMutation, { data: companyData }] = useAuthCustomerMutation();
  const [getOffer, { data: pdf }] = useLazyGetOfferContentQuery();
  const [signOffer] = useSignOfferMutation();

  const offerModalRef = useRef<BaseModalRef>(null);

  useEffect(() => {
    auth();
  }, []);

  const auth = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) return navigate('/');

    try {
      dispatch(logout());
      dispatch(setFullscreenLoaderVisibility(true));
      const { data, authToken } = await authCustomerMutation({
        token,
      }).unwrap();

      Cookies.set('token', authToken, { expires: 1, secure: true });
      Cookies.set('user-key', token, { expires: 1, secure: true });

      if (!data?.offerSignedAt) {
        await getOffer({}).unwrap();
        offerModalRef.current?.open();
      } else {
        dispatch(authCustomer({ token: authToken, company: data, userKey: token }));
        const pageToNavigate = getDefaultLink(data.roles);

        navigate(pageToNavigate);
      }
    } catch (err) {
      handleError(err);
      navigate('/');
    } finally {
      dispatch(setFullscreenLoaderVisibility(false));
    }
  };

  const pdfSuccessCb = async () => {
    if (!pdf || !companyData) return;
    try {
      const signature = await eimzo.sign(JSON.stringify(pdf.data.toSign), companyData.data.tin);

      await signOffer({
        signature,
      }).unwrap();

      auth();
    } catch (err) {
      handleError(err);
    }
  };

  const pdfRejectCb = () => {
    navigate('/');
  };

  return (
    <Layout>
      <OfferModal
        ref={offerModalRef}
        pdf={'data:application/pdf;base64,' + pdf?.data.pdf || ''}
        cb={pdfSuccessCb}
        rejectCb={pdfRejectCb}
      />
    </Layout>
  );
};
