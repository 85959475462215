import { BaseModalRef, dataURItoBlob } from "@/app/helpers";
import { useOpen } from "@/app/hooks";
import ModalTemplate from "@/shared/modal-template";
import { Box, ButtonBase, Dialog, Stack } from "@mui/material";
import {
  FormEventHandler,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import PrinterIcon from "@/app/assets/icons/printer.svg?react";

interface Props {
  pdf?: string;
  cb?: () => Promise<void>;
  rejectCb?: () => void;
  title?: string;
  noBtns?: boolean;
  download?: boolean;
}

export const OfferModal = forwardRef<BaseModalRef, Props>((props, ref) => {
  const { t } = useTranslation();
  const {
    pdf,
    cb,
    title = t("offer_for_customer"),
    rejectCb,
    noBtns,
    download = true,
  } = props;
  const { open, setOpen } = useOpen();

  const [pdfPages, setPdfPages] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      close: onCloseHandler,
      open: () => {
        setOpen(true);
      },
    }),
    []
  );

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPdfPages([...Array(numPages)]);
  };

  const onCloseHandler = () => {
    setOpen(false);
    rejectCb?.();
  };

  const onSubmit: FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (cb) await cb();
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  };

  const onDownloadHandler = () => {
    const url = URL.createObjectURL(dataURItoBlob(pdf || ""));

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.click();
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "90vw",
          width: "100%",
        },
      }}
    >
      <ModalTemplate
        title={title}
        onSubmit={onSubmit}
        submitBtnText={t("accept_offer_and_sign")}
        onClose={onCloseHandler}
        loading={isLoading}
        noBtns={noBtns}
      >
        <Stack direction="row" spacing={2} mb={2}>
          {pdf && download && (
            <ButtonBase
              sx={{
                borderRadius: "50%",
                p: 1,
              }}
              onClick={onDownloadHandler}
            >
              <Box component={PrinterIcon} />
            </ButtonBase>
          )}
        </Stack>
        <Box
          sx={{
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <Document file={pdf} onLoadSuccess={onLoadSuccess}>
            {pdfPages.map((_i, index) => (
              <Box
                key={index}
                sx={{
                  oferflow: "hidden",
                  "& @media print": {
                    pageBreakInside: "avoid",
                  },

                  "& svg, & canvas": {
                    width: "100% !important",
                    height: "100% !important",
                  },
                }}
              >
                <Page
                  className={"pdf__canvas"}
                  pageNumber={index + 1}
                  scale={1}
                />
              </Box>
            ))}
          </Document>
        </Box>
      </ModalTemplate>
    </Dialog>
  );
});
