import { Stack, Typography, useMediaQuery } from '@mui/material';

import DidoxLogo from '@/app/assets/images/logo_didox.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

export const Footer = () => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 750px)');

  return (
    <Stack
      direction='column'
      alignItems='center'
      py={isMobile ? 3 : 5}
      spacing={2}
      sx={{ backgroundColor: Colors.SECONDARY, textAlign: 'center' }}
    >
      <DidoxLogo />
      <Typography variant='body2' color={Colors.WHITE_TEXT}>
        {t('footer.text', {
          year: new Date().getFullYear(),
        })}
      </Typography>
    </Stack>
  );
};
