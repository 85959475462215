import { forwardRef, useRef } from 'react';
import { Box, Button, ButtonBase, ButtonProps, Stack, Typography } from '@mui/material';
import { FileZone } from '@/shared/ui/ui-file-zone';

import XmarkIcon from '@/app/assets/icons/xmark.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { FileZoneRef } from '@/shared/ui/ui-file-zone/types';

interface Props {
  value: File[];
  onChange: (value: File[]) => void;
  accept?: string;
  disabled?: boolean;
  showFiles?: boolean;
  hideZone?: boolean;
  type?: 'button' | 'file-zone';
  ButtonProps?: ButtonProps;
}

export const FilePicker = forwardRef<HTMLDivElement, Props>(
  (
    {
      value = [],
      onChange,
      disabled,
      showFiles = true,
      accept,
      hideZone,
      type = 'file-zone',
      ButtonProps,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const fileZoneRef = useRef<FileZoneRef>(null);

    const onPickHandler = (filelist: FileList | null) => {
      if (disabled) return;
      const file = filelist?.item(0);

      if (!file) return;
      onChange([file]);
    };

    const onDeleteHandler = (index: number) => {
      if (disabled) return;
      onChange(value.filter((_, i) => i !== index));
    };

    if (type === 'button') {
      return (
        <Stack component='div' direction={'row'} spacing={1} useFlexGap flexWrap={'wrap'} ref={ref}>
          <Box sx={{ display: 'none' }}>
            <FileZone
              ref={fileZoneRef}
              onDrop={onPickHandler}
              onPick={onPickHandler}
              accept={accept}
            />
          </Box>
          <Button
            children={t('chose_file')}
            {...ButtonProps}
            onClick={() => {
              fileZoneRef.current?.click();
            }}
            disabled={disabled}
          />
          {!!showFiles &&
            value.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                  borderRadius: '6px',
                  py: 1,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    mr: 1.5,
                  }}
                >
                  {file.name}
                </Box>
                {!disabled && (
                  <ButtonBase
                    onClick={() => {
                      onDeleteHandler(index);
                    }}
                  >
                    <Box
                      component={XmarkIcon}
                      sx={{
                        minWidth: '20px',
                        minHeight: '20px',
                        '& path': {
                          stroke: Colors.ERROR,
                        },
                      }}
                    />
                  </ButtonBase>
                )}
              </Box>
            ))}
        </Stack>
      );
    }

    return (
      <Box>
        {!hideZone && (
          <FileZone onDrop={onPickHandler} onPick={onPickHandler} ref={fileZoneRef} accept={accept}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <Button
                children={t('chose_file')}
                {...ButtonProps}
                onClick={() => {
                  fileZoneRef.current?.click();
                }}
                disabled={disabled}
                color='inherit'
              />
              {(!value || !value.length) && (
                <Typography
                  variant='caption'
                  sx={{
                    color: Colors.BGRAY,
                  }}
                >
                  {t('no_file_chosen')}
                </Typography>
              )}
              {!showFiles && value.length === 1 && (
                <Typography
                  variant='caption'
                  sx={{
                    color: Colors.BGRAY,
                  }}
                >
                  {value[0].name}
                </Typography>
              )}
            </Stack>
          </FileZone>
        )}
        {showFiles && (
          <Stack
            direction='row'
            spacing={1}
            sx={{
              mt: hideZone ? 0 : 1,
            }}
            useFlexGap
          >
            {value.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                  borderRadius: '6px',
                  py: 1.5,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    mr: 1.5,
                  }}
                >
                  {file.name}
                </Box>
                {!disabled && (
                  <ButtonBase
                    onClick={() => {
                      onDeleteHandler(index);
                    }}
                  >
                    <Box
                      component={XmarkIcon}
                      sx={{
                        minWidth: '20px',
                        minHeight: '20px',
                        '& path': {
                          stroke: Colors.ERROR,
                        },
                      }}
                    />
                  </ButtonBase>
                )}
              </Box>
            ))}
          </Stack>
        )}
      </Box>
    );
  }
);
