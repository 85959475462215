import { FC } from 'react';
import { UIChipProps } from './types';
import { Box, Typography } from '@mui/material';
import { Colors } from '@/app/constants/colors';

export const UIChip: FC<UIChipProps> = ({
  label = '',
  color = 'success',
  sx,
  TypographyProps,
  noBGColor = false,
}) => {
  const getBackgroundColor = () => {
    switch (color) {
      case 'error':
        return 'rgba(255, 82, 82, 0.16)';
      case 'warning':
        return 'rgba(251, 140, 0, 0.16)';
      case 'gray':
        return 'rgba(112, 125, 159, 0.16)';
      case 'purple':
        return 'rgba(77, 55, 179, 0.16)';
      case 'primary':
        return Colors.PRIMARY;
      case 'info':
        return Colors.INFO;
      case 'success':
      default:
        return 'rgba(76, 175, 80, 0.16)';
    }
  };

  const getTextColor = () => {
    switch (color) {
      case 'error':
        return Colors.ERROR;
      case 'warning':
        return Colors.WARNING;
      case 'gray':
        return Colors.BGRAY;
      case 'purple':
        return Colors.ACCENT;
      case 'primary':
        return Colors.SECONDARY;
      case 'info':
        return Colors.WHITE;
      case 'success':
      default:
        return Colors.SUCCESS;
    }
  };
  return (
    <Box
      component='div'
      sx={{
        backgroundColor: !noBGColor ? getBackgroundColor() : undefined,
        width: 'max-content',
        px: 0.75,
        py: 0.5,
        borderRadius: '20px',
        border: '1px solid transparent',
        ...sx,
      }}
    >
      <Typography
        color={getTextColor()}
        variant='caption'
        fontWeight={500}
        component='div'
        {...TypographyProps}
        sx={{
          lineHeight: '1.2rem',
          ...TypographyProps?.sx,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
