import { Box, Button, ButtonProps, Stack } from '@mui/material';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { UILoader } from '../ui-loader';

interface Props extends ButtonProps {
  rounded?: boolean;
  loading?: boolean;
  isMobile?: boolean;
}

export const UIButton = forwardRef<HTMLButtonElement, Props>(
  ({ rounded, children, disabled, loading, startIcon, endIcon, isMobile, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        className={classNames({
          rounded,
        })}
        disabled={disabled || loading}
        {...rest}
        startIcon={loading ? null : startIcon}
        endIcon={loading ? null : endIcon}
      >
        <Box
          sx={{
            opacity: loading ? 0 : 1,
            lineHeight: isMobile ? 1 : 0,
          }}
        >
          {children}
        </Box>
        {loading && (
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <UILoader color={rest.color} size={25} />
          </Stack>
        )}
      </Button>
    );
  }
);
