import { FC, useState } from 'react';
import { UIAlertProps } from './types';
import { Box, Fade, Stack, Typography, useTheme } from '@mui/material';
import WarningIcon from '@/app/assets/icons/warning_amber.svg?react';
import SuccessIcon from '@/app/assets/icons/check_circle.svg?react';
import ErrorIcon from '@/app/assets/icons/error_outline.svg?react';
import { UIButton } from '../ui-button';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@/shared/icons';

export const UIAlert: FC<UIAlertProps> = ({
  text = '',
  type = 'warning',
  sx,
  closable,
  onClick,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const theme = useTheme();

  const renderIcon = () => {
    switch (type) {
      case 'error':
      case 'info':
        return ErrorIcon;
      case 'success':
        return SuccessIcon;
      case 'warning':
      default:
        return WarningIcon;
    }
  };

  const getTextColor = () => {
    switch (type) {
      case 'error':
        return 'rgba(95, 33, 32, 0.87)';
      case 'success':
        return '#1E4620';
      case 'warning':
        return '#663C00';
      default:
        return theme.palette.text.primary;
    }
  };

  const getBackgroundColor = () => {
    switch (type) {
      case 'error':
        return '#FDEDED';
      case 'success':
        return '#EDF7ED';
      case 'info':
        return '#E8F0FE';
      case 'warning':
      default:
        return '#FFF4E5';
    }
  };

  const Icon = renderIcon();

  const onCloseHandler = () => {
    setVisible(false);
  };

  return (
    <Fade in={visible} timeout={500} unmountOnExit>
      <Stack
        direction='row'
        spacing={1.5}
        justifyContent='space-between'
        sx={{
          maxWidth: '400px',
          width: '100%',
          backgroundColor: getBackgroundColor(),
          p: 2,
          borderRadius: '4px',
          ...sx,
        }}
      >
        <Stack spacing={1.5} direction='row'>
          <Box
            component={Icon}
            sx={{
              minWidth: '24px',
              minHeight: '24px',
              ...(type === 'info' && {
                '& path': {
                  fill: '#3A8DFF',
                },
              }),
            }}
          />

          <Typography variant='body1' color={getTextColor()}>
            {text}
          </Typography>
        </Stack>

        {closable && (
          <UIButton
            size='small'
            onClick={onCloseHandler}
            color='inherit'
            endIcon={
              <Box
                component={PlusIcon}
                sx={{
                  transform: 'rotate(45deg)',
                }}
              />
            }
          >
            {t('close')}
          </UIButton>
        )}

        {onClick && (
          <UIButton
            size='small'
            onClick={() => {
              onClick();
              onCloseHandler();
            }}
            color='primary'
          >
            {t('confirm')}
          </UIButton>
        )}
      </Stack>
    </Fade>
  );
};
