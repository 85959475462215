import React, { forwardRef, useImperativeHandle, useState } from "react";
import { UIStepperProps, UIStepperRef } from "./types";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UIButton } from "../ui-button";
import { Colors } from "@/app/constants/colors";

export const UIStepper = forwardRef<UIStepperRef, UIStepperProps>(
  (
    { data, defaultValue = 0, sx, StepProps, StepperProps, StepLabelProps },
    ref
  ) => {
    const theme = useTheme();
    const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));
    const isPhones = useMediaQuery("(max-width:500px)");
    const [activeStep, setActiveStep] = useState(defaultValue);
    const [skipped, setSkipped] = useState(new Set<number>());

    useImperativeHandle(
      ref,
      () => ({
        next: handleNext,
        back: handleBack,
        reset: handleReset,
      }),
      []
    );

    const isStepOptional = (_step: number) => {
      return false;
    };

    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };

    const handleNext = (skip = 1) => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setSkipped(newSkipped);
      setActiveStep((prevActiveStep) => prevActiveStep + skip);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleSkip = () => {
    //   if (!isStepOptional(activeStep)) {
    //     // You probably want to guard against something like this,
    //     // it should never occur unless someone's actively trying to break something.
    //     throw new Error("You can't skip a step that isn't optional.");
    //   }

    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   setSkipped((prevSkipped) => {
    //     const newSkipped = new Set(prevSkipped.values());
    //     newSkipped.add(activeStep);
    //     return newSkipped;
    //   });
    // };

    const handleReset = () => {
      setActiveStep(0);
    };

    return (
      <Box sx={{ width: "100%", ...sx }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel={isLessThanMd && !isPhones}
          orientation={isPhones ? "vertical" : "horizontal"}
          connector={(<></>)}
          {...StepperProps}
        >
          {data.map(({ label }, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step
                key={label}
                {...stepProps}
                {...StepProps}
                className={
                  StepProps?.className
                    ? StepProps.className +
                      " " +
                      (activeStep === index ? "active" : "")
                    : activeStep === index
                    ? "active"
                    : ""
                }
              >
                <StepLabel
                  {...labelProps}
                  StepIconProps={{
                    sx: {
                      width: "1.5em",
                      height: "1.5em",
                      color: "#FFF",
                      "& .MuiStepIcon-text": {
                        fill: Colors.BGRAY,
                        fontSize: "1rem",
                      },
                      "& circle": {
                        stroke: "rgba(112, 125, 159, 0.2)",
                        r: "11",
                      },
                      "&.Mui-active": {
                        "& circle": {
                          stroke: "#FFF",
                        },
                        "& .MuiStepIcon-text": {
                          fill: Colors.SECONDARY,
                        },
                      },
                    },
                  }}
                  {...StepLabelProps}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === data.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <UIButton onClick={handleReset}>Reset</UIButton>
            </Box>
          </React.Fragment>
        ) : (
          <Box
            sx={{
              mt: 2,
            }}
          >
            {data[activeStep]?.Component}
          </Box>
        )}
      </Box>
    );
  }
);
