import { UseFormReturn } from 'react-hook-form';
import { YandexApiFormValues } from '../types';
import { FC } from 'react';
import { FormControl, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { UILoader } from '@/shared/ui/ui-loader';

interface Props {
  form: UseFormReturn<YandexApiFormValues>;
  isLoading: boolean;
}

export const TaxiParkApiSettings: FC<Props> = ({ form, isLoading }) => {
  const { t } = useTranslation();
  const { control } = form;

  if (isLoading) {
    return <UILoader />;
  }

  return (
    <Stack spacing={2}>
      <FormControl>
        <UIFormLabel>{t(t('client_id'))}</UIFormLabel>

        <FormTextInput
          control={control}
          name='clientId'
          rules={{
            required: true,
          }}
        />
      </FormControl>

      <FormControl>
        <UIFormLabel>{t(t('api_key'))}</UIFormLabel>

        <FormTextInput
          control={control}
          name='apiKey'
          rules={{
            required: true,
          }}
        />
      </FormControl>

      <FormControl>
        <UIFormLabel>{t(t('park_id'))}</UIFormLabel>
        <FormTextInput
          control={control}
          name='parkId'
          rules={{
            required: true,
          }}
        />
      </FormControl>
    </Stack>
  );
};
