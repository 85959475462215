import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, FormControl, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useOpen } from '@/app/hooks';
import { BaseModalRef, handleError } from '@/app/helpers';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { UIFormLabel } from '@/shared/ui/ui-form-label';

import { useTranslation } from 'react-i18next';

export interface CommentModalRef extends BaseModalRef {
  init: (body: { title: string }) => Promise<string>;
}

export const CommentModal = forwardRef<CommentModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();
  const [title, setTitle] = useState<string>();

  const { control, handleSubmit, reset } = useForm<{ comment: string }>();

  const resolveCallback = useRef<Function>(() => {});
  const rejectCallback = useRef<Function>(() => {});

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
      init,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    if (!rejectCallback.current) return;
    rejectCallback.current();
    setTitle('');
    reset();
    setOpen(false);
  };

  const init = (body: { title: string }): Promise<string> => {
    onOpen();
    setTitle(body.title);
    return new Promise((res, rej) => {
      resolveCallback.current = res;
      rejectCallback.current = rej;
    });
  };

  const onSubmitHandler = (data: { comment: string }) => {
    try {
      resolveCallback.current(data.comment);
      onClose();
    } catch (error) {
      handleError(error);
      rejectCallback.current(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '560px',
          width: '100%',
        },
      }}
    >
      <ModalTemplate
        title={title}
        submitBtnText={t('send')}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Stack spacing={3}>
          <FormControl required fullWidth>
            <UIFormLabel required>{t('attach_comment')}</UIFormLabel>
            <FormTextInput control={control} name='comment' rules={{ required: true }} />
          </FormControl>
        </Stack>
      </ModalTemplate>
    </Dialog>
  );
});
