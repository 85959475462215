import { FC } from 'react';
import { Box } from '@mui/material';
import { UITable } from '../../../shared/ui/ui-table';
import { EmptyPlaceholder } from '../../../shared/empty-placeholder';
import { UIChip } from '../../../shared/ui/ui-chip';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FilterParams } from '../types';
import {
  DocumentReportStatusId,
  GetDocumentReportStatuses,
  GetUklonRerportsData,
} from '../../../app/api/model';
import { UIChipColor } from '../../../shared/ui/ui-chip/types';
import { DefaultResponseWithPagination } from '../../../app/helpers';

interface UklonReportsTableProps {
  isFetchingUklonReports: boolean;
  uklonReportsData:
    | (DefaultResponseWithPagination<GetUklonRerportsData[]> & { nextPage: number })
    | undefined;
  statusesData: GetDocumentReportStatuses[] | undefined;
  filterParams: FilterParams;
  onChangeFilterParams: (params: Partial<FilterParams>) => void;
  getChipType: (
    status: DocumentReportStatusId,
    label: string
  ) => {
    label: string;
    color: UIChipColor;
  };
}

export const UklonReportsTable: FC<UklonReportsTableProps> = ({
  isFetchingUklonReports,
  uklonReportsData,
  statusesData,
  filterParams,
  onChangeFilterParams,
  getChipType,
}) => {
  const { t } = useTranslation();

  return (
    <UITable
      data={uklonReportsData?.data ?? []}
      EmptyPlaceholder={<EmptyPlaceholder />}
      isLoading={isFetchingUklonReports}
      columns={[
        {
          label: '№',
          render: (_, index) => index + 1,
          nowrap: true,
        },
        { label: t('date'), key: 'date' },
        { label: t('number'), key: 'number' },
        {
          label: t('type'),
          render: ({ unique }) => {
            return (
              <Box
                component={Link}
                to={`/customer/reports/${unique}`}
                sx={{ textDecoration: 'none' }}
              >
                {t('commissioner_report') + ' Uklon'}
              </Box>
            );
          },
        },
        {
          label: t('status'),
          render: ({ status }) => {
            const exactStatus = statusesData?.find((obj) => obj.id === status);
            if (exactStatus) return <UIChip {...getChipType(exactStatus?.id, exactStatus.name)} />;
            return '';
          },
        },
      ]}
      PaginationProps={{
        count: uklonReportsData?.totalPages ?? 0,
        page: filterParams.page,
        onChange: (_e, page) => onChangeFilterParams({ page }),
        disabled: typeof uklonReportsData?.totalPages === 'undefined',
      }}
    />
  );
};
