import { SyntheticEvent, useState } from 'react';
import { Layout } from '@/shared/layout';
import { UISection } from '@/shared/ui/ui-section';
import UITabs from '@/shared/ui/ui-tabs';
import { ReportsTabComponent } from './ui/reports-tab';
import { DocumentsTabComponent } from './ui/documents-tab';
import { useSearchParams } from 'react-router-dom';
import {
  useGetClosingDocumentTypesQuery,
  useGetDocumentReportsStatusesQuery,
  useGetDocumentReportsTypesQuery,
  useGetDocumentStatusesQuery,
} from '@/app/api';
import { useAppSelector } from '../../app/hooks';

import { useTranslation } from 'react-i18next';

export const NewCustomerDocsPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { company } = useAppSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(Number(searchParams.get('activeTab')));

  const isYandex = company?.invitationFlow === 'yandex';

  const { data: reportTypesData, isFetching: isFetchingReportTypes } =
    useGetDocumentReportsTypesQuery({}, { skip: !isYandex });
  const { data: reportStatusesData } = useGetDocumentReportsStatusesQuery({});
  const { data: closingDocumentTypesData } = useGetClosingDocumentTypesQuery({});
  const { data: documentStatusesData } = useGetDocumentStatusesQuery({});

  const handleTabChange = (_event: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
    setSearchParams({ activeTab: newValue.toString() });
  };

  return (
    <Layout>
      <UISection sx={{ pt: 1, height: '100%' }}>
        <UITabs
          value={activeTab}
          onChange={handleTabChange}
          tabs={[
            {
              label: t('yandex_docs_page.reports'),
              component: (
                <ReportsTabComponent
                  typesData={reportTypesData?.data}
                  statusesData={reportStatusesData?.data}
                  isFetchingReportTypes={isFetchingReportTypes}
                />
              ),
            },
            {
              label: t('yandex_docs_page.documents'),
              component: (
                <DocumentsTabComponent
                  typesData={closingDocumentTypesData?.data}
                  statusesData={documentStatusesData?.data}
                />
              ),
            },
          ]}
        />
      </UISection>
    </Layout>
  );
};
