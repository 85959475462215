import { eimzo } from '@/entities/eimzo';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ru from 'dayjs/locale/ru';
import uz from 'dayjs/locale/uz-latn';
import { getUserLang } from '../helpers';

export const appConfig = () => {
  eimzo.startApi();
  dayjs.extend(customParseFormat);
  dayjs.locale(getUserLang() === 'ru' ? ru : uz);
  dayjs.extend(localeData);

  if (import.meta.env.DEV) {
    //@ts-ignore
    window.dayjs = dayjs;
  }
};
