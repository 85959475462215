import { useEffect, useRef } from "react";

export function useDidUpdateEffect(fn: () => void, deps: React.DependencyList) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, deps);
}
