import Image1 from '@/app/assets/images/our-partners/1.png';
import Image2 from '@/app/assets/images/our-partners/2.png';
import Image3 from '@/app/assets/images/our-partners/3.png';
import Image4 from '@/app/assets/images/our-partners/4.png';
import Image5 from '@/app/assets/images/our-partners/5.png';
import Image6 from '@/app/assets/images/our-partners/6.png';
import Image7 from '@/app/assets/images/our-partners/7.png';
import { Stack } from '@mui/material';
import { FC } from 'react';

const OurPartners: FC<{
  isMobile: boolean;
  isTablet: boolean;
}> = ({ isMobile, isTablet }) => {
  const partners = [
    {
      link: 'https://taxi.yandex.uz/ru_uz/',
      image: Image1,
    },
    {
      link: 'https://eats.yandex.com/uz/',
      image: Image2,
    },
    {
      link: 'https://uzumtezkor.uz/ru',
      image: Image3,
    },
    {
      link: 'https://wolt.com/ru/uzb',
      image: Image4,
    },
    {
      link: 'https://uz.uklon.eu/ru/home-ru/',
      image: Image5,
    },
    {
      link: 'https://paramedics.uz/ru',
      image: Image6,
    },
    {
      link: 'https://yemak.uz/',
      image: Image7,
    },
  ];

  return (
    <Stack
      direction='row'
      spacing={isMobile ? 2 : isTablet ? 5 : 10}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: '40px',
      }}
    >
      {partners.map((item, index) => (
        <a
          key={index}
          href={item.link}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={item.image}
            alt={`Partner ${index + 1}`}
            style={{
              maxWidth: '100%',
              transition: 'transform 0.2s',
            }}
            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          />
        </a>
      ))}
    </Stack>
  );
};

export default OurPartners;
