import { Colors } from '@/app/constants/colors';
import { handleError } from '@/app/helpers';
import { useVerifyFaceIdMutation } from '@/entities/auth/api';
import { UIAlert } from '@/shared/ui/ui-alert';
import { UIButton } from '@/shared/ui/ui-button';
import { UILoader } from '@/shared/ui/ui-loader';
import { UIStepperRef } from '@/shared/ui/ui-stepper/types';
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeRegistrationContext } from '../lib/employee-registration-context';

interface Props {
  stepperRef: UIStepperRef | null;
}

export const EmployeeIdentificationStep: FC<Props> = ({ stepperRef }) => {
  const { t } = useTranslation();
  const { userData } = useContext(EmployeeRegistrationContext);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [cameraAccess, setCameraAccess] = useState(false);
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);

  const [capturedImage, setCapturedImage] = useState<string>();

  const [verifyFaceId] = useVerifyFaceIdMutation();

  useEffect(() => {
    if (!cameraAccess) {
      enableCamera();
    }

    return () => {
      // Отключаем видеострим при размонтировании компонента
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [cameraAccess, videoStream]);

  const enableCamera = async () => {
    try {
      setLoading(true);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setVideoStream(stream);
      setCameraAccess(true);
    } catch (error) {
      setError(!!error);
    } finally {
      setLoading(false);
    }
  };

  const handleCapture = () => {
    if (videoStream) {
      const canvas = document.createElement('canvas');
      const video = document.getElementById('face-id-video') as HTMLVideoElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      // Отзеркаливаем холст
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const image = canvas.toDataURL('image/jpeg');
      setCapturedImage(image);
    }
  };

  const onSubmitHandler = async () => {
    if (!capturedImage) return;
    try {
      setIsPhotoLoading(true);

      const osPlatrofm = window.navigator.userAgent.match(/(Mac|iPhone|iPod|iPad)/i)?.[0];

      await verifyFaceId({
        image: capturedImage.split(',')[1],
        deviceId: osPlatrofm!,
      }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      handleError(error);
      setCapturedImage(undefined);
    } finally {
      setIsPhotoLoading(false);
    }
  };

  const onSubmitStepHandler = () => {
    stepperRef?.next();
  };

  const renderVideo = () => {
    if (!isAccessGranted)
      return (
        <Stack
          spacing={5}
          alignItems='center'
          sx={{
            maxWidth: '480px',
            p: 3,
            border: `1px solid ${Colors.BORDER}`,
            borderRadius: '8px',
          }}
        >
          <Box textAlign='center'>
            <Typography>{t('identification.title')}</Typography>
            <Typography variant='body2' color={Colors.BGRAY} mt={1}>
              {t('identification.subtitle')}
            </Typography>
          </Box>
          <UIButton onClick={() => setIsAccessGranted(true)} color='secondary'>
            {t('identification.button')}
          </UIButton>
        </Stack>
      );
    if (loading) {
      return <UILoader size={40} title={t('getting_access')} />;
    }

    if (error) {
      return <UIAlert type='warning' text={t('web_camera_access_error')} />;
    }

    if (isSuccess) {
      return (
        <Stack alignItems='center'>
          <UIAlert type='success' text={t('identification.success')} />
          <UIButton
            onClick={onSubmitStepHandler}
            sx={{
              mt: 5,
            }}
          >
            {t('identification.success_button')}
          </UIButton>
        </Stack>
      );
    }

    if (capturedImage)
      return (
        <Box
          sx={{
            maxWidth: '640px',
            width: '100%',
            borderRadius: '16px',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            component='img'
            src={capturedImage}
            sx={{
              width: '100%',
            }}
          />
          <Stack
            spacing={2}
            direction='row'
            sx={{
              position: 'absolute',
              left: '50%',
              bottom: '20px',
              transform: 'translateX(-50%)',
            }}
          >
            <UIButton
              color='inherit'
              size='large'
              sx={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                setCapturedImage(undefined);
                setCameraAccess(false);
              }}
            >
              {t('capture_again')}
            </UIButton>
            <UIButton size='large' onClick={onSubmitHandler}>
              {t('OK')}
            </UIButton>
          </Stack>
          {isPhotoLoading && (
            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{
                position: 'absolute',
                backgroundColor: 'rgba(255,255,255, 0.6)',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
            >
              <UILoader size={60} />
            </Stack>
          )}
        </Box>
      );

    if (!videoStream) return;
    return (
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            border: `2px solid ${Colors.ERROR}`,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            height: isMobile ? '60%' : '70%',
            width: isMobile ? '60%' : '40%',
            zIndex: 10,
            borderRadius: '50%',
          }}
        />
        <Box
          sx={{
            borderRadius: '16px',
            maxWidth: '640px',
            width: '100%',
            transform: 'scaleX(-1)',
          }}
          component='video'
          autoPlay
          playsInline
          id='face-id-video'
          ref={(videoElement) => {
            if (videoElement && videoStream) {
              //@ts-ignore
              videoElement.srcObject = videoStream;
            }
          }}
        />
        <UIButton
          onClick={handleCapture}
          size='large'
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: '20px',
            transform: 'translateX(-50%)',
          }}
        >
          {t('capture')}
        </UIButton>
      </Box>
    );
  };

  return (
    <Container>
      <Stack alignItems='center'>
        <Typography variant='h5'>{t('identification.title_2')}</Typography>

        <Box mt={3}>{renderVideo()}</Box>
      </Stack>
    </Container>
  );
};
