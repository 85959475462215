import { forwardRef, useImperativeHandle, useLayoutEffect } from 'react';
import { useFilters, useOpen } from '@/app/hooks';
import { FormDatePicker } from '@/shared/inputs/form-date-picker';
import FormAutocompleteInput from '@/shared/inputs/form-autocomplete-input';
import { UIButton } from '@/shared/ui/ui-button';
import { UIChip } from '@/shared/ui/ui-chip';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { Box, ButtonBase, Drawer, FormControl, Stack, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { CloseFilledIcon, CloseIcon } from '@/shared/icons';

import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@/app/constants';
import { Colors } from '@/app/constants/colors';
import { BaseModalRef } from '@/app/helpers';

interface FormValues {
  dateFrom: Date | null;
  dateTo: Date | null;
  contractSigned: string;
  ABPSigned: string;
  inviceSigned: string;
  tags: {
    value: string;
  }[];
}

interface FilterValues extends Omit<FormValues, 'tags' | 'dateFrom' | 'dateTo'> {
  tags: string[];
  dateFrom: string | null;
  dateTo: string | null;
}

const tags = [
  {
    value: '1',
    label: 'tag1',
  },
  {
    value: '2',
    label: 'tag2',
  },
  {
    value: '3',
    label: 'tag3',
  },
  {
    value: '4',
    label: 'Tag with long name',
  },
  {
    value: '5',
    label: 'Custom tag with long name',
  },
  {
    value: '6',
    label: 'PLaceholder tab number 6',
  },
];

export const TasksFilter = forwardRef<BaseModalRef>((_, ref) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      tags: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  });
  const { open, setOpen } = useOpen();
  const { filterParams, setUrlArgsToState, onChangeFilterParams } = useFilters<FilterValues>({
    initialFilterParams: {
      dateFrom: null,
      dateTo: null,
      contractSigned: '',
      ABPSigned: '',
      inviceSigned: '',
      tags: [],
    },
  });

  useLayoutEffect(() => {
    // const params = setUrlArgsToState();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      close: onCloseHandler,
      open: () => setOpen(true),
    }),
    []
  );

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onSubmitHandler = (data: FormValues) => {
    onChangeFilterParams({
      ...data,
      tags: data.tags.map((tag) => tag.value),
      dateFrom: dayjs(data.dateFrom).format(DATE_FORMAT),
      dateTo: dayjs(data.dateTo).format(DATE_FORMAT),
    });
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmitHandler),
        sx: {
          maxWidth: '480px',
          width: '100%',
          py: 2,
          px: 3,
        },
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6'>{t('filter')}</Typography>

        <ButtonBase
          onClick={onCloseHandler}
          sx={{
            p: 1,
            borderRadius: '50%',
          }}
        >
          <Box component={CloseIcon} />
        </ButtonBase>
      </Stack>

      <Stack spacing={2.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('period')}:</UIFormLabel>
          <Stack direction='row' spacing={1}>
            <FormDatePicker control={control} name='dateFrom' label={t('from')} />
            <FormDatePicker control={control} name='dateTo' label={t('to')} />
          </Stack>
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('task_contract_signed')}:</UIFormLabel>

          <FormAutocompleteInput
            control={control}
            name='contractSigned'
            options={['1', '2', '3']}
            TextFieldProps={{
              placeholder: t('empty_placeholder'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('abp_signed')}:</UIFormLabel>

          <FormAutocompleteInput
            control={control}
            name='ABPSigned'
            options={['1', '2', '3']}
            TextFieldProps={{
              placeholder: t('empty_placeholder'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('invoice_signed')}:</UIFormLabel>

          <FormAutocompleteInput
            control={control}
            name='inviceSigned'
            options={['1', '2', '3']}
            TextFieldProps={{
              placeholder: t('empty_placeholder'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('tags')}:</UIFormLabel>
          <Stack direction='row' spacing={1} useFlexGap flexWrap='wrap'>
            {tags.map((tag) => {
              const isSelected = fields.find((field) => field.value === tag.value);

              return (
                <ButtonBase
                  key={tag.value}
                  sx={{
                    borderRadius: '10px',
                  }}
                  onClick={() => {
                    if (fields.find((field) => field.value === tag.value)) {
                      return remove(fields.findIndex((field) => field.value === tag.value));
                    }
                    append({ value: tag.value });
                  }}
                >
                  <UIChip
                    sx={{
                      ...(isSelected
                        ? {
                            backgroundColor: '#fff',
                            borderColor: Colors.ACCENT,
                          }
                        : {}),
                    }}
                    TypographyProps={{
                      sx: {
                        ...(isSelected
                          ? {
                              color: Colors.SECONDARY,
                            }
                          : {}),
                      },
                    }}
                    label={
                      <Stack direction='row' alignItems='center'>
                        {tag.label}
                        {isSelected && <Box component={CloseFilledIcon} ml={1} />}
                      </Stack>
                    }
                  />
                </ButtonBase>
              );
            })}
          </Stack>
        </FormControl>
      </Stack>

      <Stack
        spacing={2}
        direction='row'
        justifyContent='flex-end'
        sx={{
          mt: 5,
        }}
      >
        <UIButton color='inherit'>{t('reset')}</UIButton>
        <UIButton type='submit'>{t('apply')}</UIButton>
      </Stack>
    </Drawer>
  );
});
