import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import CheckIcon from '@/app/assets/icons/check_circle.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

export const ProductAdvantages: FC<{
  isMobile: boolean;
  isTablet: boolean;
}> = ({ isMobile, isTablet }) => {
  const { t } = useTranslation();

  const withoutService: string[] = [
    t('landing:without_service.0'),
    t('landing:without_service.1'),
    t('landing:without_service.2'),
    t('landing:without_service.3'),
    t('landing:without_service.4'),
  ];

  return (
    <Stack direction={isMobile || isTablet ? 'column' : 'row'} alignItems='center'>
      <Stack
        direction='column'
        p={isMobile ? 2 : 3}
        borderRadius={2}
        maxWidth={isMobile || isTablet ? '80%' : '100%'}
        maxHeight={isMobile || isTablet ? '100%' : '75%'}
        sx={{ backgroundColor: Colors.WHITE, flex: 1 }}
      >
        <Typography variant={isMobile ? 'h6' : 'h5'}>{t('landing:if_without_service')}:</Typography>

        <Box component='ul' sx={{ maxWidth: isMobile || isTablet ? '90%' : '100%' }}>
          {withoutService.map((item) => (
            <Box key={item} component='li'>
              <Typography variant={isMobile ? 'body2' : 'body1'}>{item}</Typography>
            </Box>
          ))}
        </Box>
      </Stack>

      <Stack
        direction='column'
        px={isMobile || isTablet ? 3 : 5}
        py={isMobile || isTablet ? 3 : 7}
        maxHeight={isMobile || isTablet ? '100%' : '75%'}
        maxWidth={isMobile || isTablet ? '90%' : '100%'}
        borderRadius={2}
        spacing={2}
        sx={{ backgroundColor: Colors.SUCCESS, flex: 1 }}
      >
        <Box
          component={CheckIcon}
          sx={{
            width: isMobile ? '50px' : '80px',
            height: isMobile ? '50px' : '80px',
            path: {
              fill: Colors.WHITE,
            },
          }}
        />
        <Typography variant={isMobile ? 'h6' : 'h5'} color={Colors.WHITE_TEXT}>
          {t('landing:if_with_service')}:
        </Typography>
        <Typography color={Colors.WHITE_TEXT} variant={isMobile ? 'body2' : 'body1'}>
          {t('landing:with_service')}
        </Typography>
      </Stack>
    </Stack>
  );
};
