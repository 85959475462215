import { FC, forwardRef, PropsWithChildren, useImperativeHandle, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, Divider, Grid, Typography, TypographyProps } from '@mui/material';
import { useReportsContext } from '../context';
import { numericFormatter } from 'react-number-format';
import { useOpen } from '@/app/hooks';

import { useTranslation } from 'react-i18next';
import { UklonFullInfoModalRef } from '../types';
import { Colors } from '../../../app/constants/colors';

const TypographyComponent: FC<PropsWithChildren<TypographyProps>> = ({ children, ...rest }) => {
  return (
    <Typography variant='body2' color={Colors.BGRAY} {...rest}>
      {children}
    </Typography>
  );
};

export const UklonFullInfoModal = forwardRef<UklonFullInfoModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { StackComponent, uklonReportRows, uklonReportData } = useReportsContext();
  const { open, setOpen } = useOpen();
  const [index, setIndex] = useState<number>();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
      init,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const init = (index: number) => {
    onOpen();
    setIndex(index);
  };

  if (!uklonReportRows || typeof index === 'undefined') return null;
  const row = uklonReportRows[index];
  const tripsSum = row.tripCostCash + row.tripCostNonCash;
  const tripsCommission = row.uklonCommissionCash + row.uklonCommissionNonCash;
  const driverNetIncomeFromTrips = tripsSum - Math.abs(tripsCommission);
  const totalDriversNetIncome =
    driverNetIncomeFromTrips +
    (row.compensations ?? 0) +
    row.dayProgramBonuses +
    row.orderBonuses +
    (row.tipsNonCash ?? 0) +
    (row.brandingBonuses ?? 0) -
    (Math.abs(row.fines ?? 0) + Math.abs(row.walletReplenishmentCommission ?? 0));

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 900,
        },
      }}
    >
      <ModalTemplate
        title={t('uklon_trips_page.income_movements_report')}
        cancelBtnText={t('close')}
        onClose={onClose}
      >
        <StackComponent direction='column' spacing={2}>
          <Grid container>
            <Grid item xs={4}>
              <TypographyComponent>{t('period')}</TypographyComponent>
              <Typography variant='body2'>
                {uklonReportData?.dateFrom} - {uklonReportData?.dateTo}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <TypographyComponent>{t('pinfl')}</TypographyComponent>
              <Typography variant='body2'>{row.pinfl ?? '-'}</Typography>
            </Grid>

            <Grid item xs={4}>
              <TypographyComponent>{t('fio')}</TypographyComponent>
              <Typography variant='body2'>{row.name ?? '-'}</Typography>
            </Grid>
          </Grid>

          <Divider />

          <Grid container>
            <Grid item xs={3}>
              <TypographyComponent>
                {t('uklon_trips_page.driver_income_from_trips')}
              </TypographyComponent>
              <Typography variant='body2'>
                {typeof tripsSum === 'number'
                  ? numericFormatter(String(tripsSum), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <TypographyComponent>{t('uklon_trips_page.uklon_comission')}</TypographyComponent>
              <Typography variant='body2'>
                {typeof tripsCommission === 'number'
                  ? numericFormatter(String(tripsCommission), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <TypographyComponent>{t('customer_trips_page.dirvers_profit')}</TypographyComponent>
              <Typography variant='body2'>
                {typeof driverNetIncomeFromTrips === 'number'
                  ? numericFormatter(String(driverNetIncomeFromTrips), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <TypographyComponent>{t('uklon_trips_page.total_income')}</TypographyComponent>
              <Typography variant='body2'>
                {typeof totalDriversNetIncome === 'number'
                  ? numericFormatter(String(totalDriversNetIncome), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : '-'}
              </Typography>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.driver_income_from_trips')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>{t('uklon_trips_page.trip_cost_cash')}</TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.trip_cost_non_cash')}
                </TypographyComponent>
                <TypographyComponent>{t('uklon_trips_page.promo_code_cash')}</TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.promo_code_non_cash')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.tripCostCash === 'number'
                    ? numericFormatter(String(row.tripCostCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.tripCostNonCash === 'number'
                    ? numericFormatter(String(row.tripCostNonCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.promoCodeCash === 'number'
                    ? numericFormatter(String(row.promoCodeCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.promoCodeNonCash === 'number'
                    ? numericFormatter(String(row.promoCodeNonCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.uklon_comission')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>
                  {t('uklon_trips_page.uklon_comission_cash')}
                </TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.uklon_comission_non_cash')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.uklonCommissionCash === 'number'
                    ? numericFormatter(String(row.uklonCommissionCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.uklonCommissionNonCash === 'number'
                    ? numericFormatter(String(row.uklonCommissionNonCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.driver_net_income_from_trips')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>
                  {t('uklon_trips_page.driver_revenue_cash')}
                </TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.driver_revenue_non_cash')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof (row.tripCostCash - row.uklonCommissionCash) === 'number'
                    ? numericFormatter(
                        String(row.tripCostCash - Math.abs(row.uklonCommissionCash)),
                        {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        }
                      )
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof (row.tripCostNonCash - row.uklonCommissionNonCash) === 'number'
                    ? numericFormatter(
                        String(row.tripCostNonCash - Math.abs(row.uklonCommissionNonCash)),
                        {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        }
                      )
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.other_income')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>{t('uklon_trips_page.compensations')}</TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.day_program_bonuses')}
                </TypographyComponent>
                <TypographyComponent>{t('uklon_trips_page.order_bonuses')}</TypographyComponent>
                <TypographyComponent>{t('uklon_trips_page.tips_non_cash')}</TypographyComponent>
                <TypographyComponent>{t('uklon_trips_page.branding_bonuses')}</TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.compensations === 'number'
                    ? numericFormatter(String(row.compensations), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.dayProgramBonuses === 'number'
                    ? numericFormatter(String(row.dayProgramBonuses), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.orderBonuses === 'number'
                    ? numericFormatter(String(row.orderBonuses), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.tipsNonCash === 'number'
                    ? numericFormatter(String(row.tipsNonCash), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.brandingBonuses === 'number'
                    ? numericFormatter(String(row.brandingBonuses), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.other_deductions')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>{t('uklon_trips_page.fines')}</TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.wallet_replenishment_commission')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.fines === 'number'
                    ? numericFormatter(String(row.fines), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.walletReplenishmentCommission === 'number'
                    ? numericFormatter(String(row.walletReplenishmentCommission), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>
            {t('uklon_trips_page.withdrawal_from_driver_balance')}
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>
                  {t('uklon_trips_page.driver_wallet_transfer')}
                </TypographyComponent>
                <TypographyComponent>{t('uklon_trips_page.cash_payment')}</TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.withdrawal_to_driver_card')}
                </TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.withdrawal_commission_to_driver_card')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.walletTransfer === 'number'
                    ? numericFormatter(String(row.walletTransfer), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.cashPayment === 'number'
                    ? numericFormatter(String(row.cashPayment), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.withdrawalToDriverCard === 'number'
                    ? numericFormatter(String(row.withdrawalToDriverCard), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.withdrawalCommissionToDriverCard === 'number'
                    ? numericFormatter(String(row.withdrawalCommissionToDriverCard), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>

          <Divider />

          <Typography variant='h6'>{t('uklon_trips_page.driver_balance_replenishment')}</Typography>

          <Grid container>
            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <TypographyComponent>
                  {t('uklon_trips_page.driver_wallet_transfer')}
                </TypographyComponent>
                <TypographyComponent>
                  {t('uklon_trips_page.driver_wallet_replenishment')}
                </TypographyComponent>
              </StackComponent>
            </Grid>

            <Grid item xs={6}>
              <StackComponent direction='column' spacing={0.5}>
                <Typography variant='body2'>
                  {typeof row.driverWalletTransfer === 'number'
                    ? numericFormatter(String(row.driverWalletTransfer), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
                <Typography variant='body2'>
                  {typeof row.driverWalletReplenishment === 'number'
                    ? numericFormatter(String(row.driverWalletReplenishment), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : '-'}
                </Typography>
              </StackComponent>
            </Grid>
          </Grid>
        </StackComponent>
      </ModalTemplate>
    </Dialog>
  );
});
