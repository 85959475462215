import { customFetchBase } from '@/app/api';
import { DefaultResponse, blobToBase64 } from '@/app/helpers';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  Customer,
  GetOfferContentResponse,
  RegisterEmployeePayload,
  RegisterEmployeeResponse,
  UserData,
  VerifyFaceIdPayload,
} from '../model';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    authCustomer: builder.mutation<
      DefaultResponse<Customer> & { authToken: string },
      { token: string }
    >({
      query: (payload) => ({
        url: '/exec?action=authEmployer',
        method: 'POST',
        body: {
          userKey: payload.token,
        },
      }),
    }),
    registerWorker: builder.mutation<
      DefaultResponse & RegisterEmployeeResponse,
      RegisterEmployeePayload
    >({
      query: (body) => ({
        url: '/exec?action=registerWorker',
        method: 'POST',
        body,
      }),
    }),
    verifyFaceId: builder.mutation<DefaultResponse, VerifyFaceIdPayload>({
      query: (body) => ({
        url: '/exec?action=verifyFaceId',
        method: 'POST',
        body,
      }),
    }),
    checkSelfEmployedStatus: builder.query<
      { selfEmployed: boolean; docNum: string },
      { p?: string }
    >({
      query: (params) => ({
        url: '/exec?action=checkSelfEmployedStatus',
        method: 'POST',
        params,
      }),
    }),
    getSelfEmployedCertificate: builder.query<string, { p: string }>({
      query: (params) => ({
        url: '/exec?action=getSelfEmployedCertificate',
        method: 'POST',
        params,
        responseHandler: async (response) => {
          if (!response.ok) {
            const body = await response.json();
            return body;
          }
          const blob = await response.blob();
          const res = await blobToBase64(blob);
          return res;
        },
      }),
    }),
    registerSelfEmployed: builder.mutation<
      { selfEmployedRegistered: boolean },
      { code?: string; invitationCode: string }
    >({
      query: (body) => ({
        url: '/exec?action=registerSelfEmployed',
        method: 'POST',
        body,
      }),
    }),
    getOfferContent: builder.query<DefaultResponse<GetOfferContentResponse>, {}>({
      query: () => ({
        url: '/exec?action=getOfferContent',
        method: 'POST',
      }),
    }),
    signOffer: builder.mutation<DefaultResponse, { signature: string }>({
      query: (body) => ({
        url: '/exec?action=signOffer',
        method: 'POST',
        body,
      }),
    }),
    getGuaranteeAgreement: builder.query<
      DefaultResponse<{
        pdf: string;
      }>,
      {}
    >({
      query: () => ({
        url: '/exec?action=getGuaranteeAgreement',
        method: 'POST',
        responseType: 'blob',
      }),
    }),
    finishSelfEmployedRegistration: builder.mutation<DefaultResponse, { invitationCode: string }>({
      query: (body) => ({
        url: '/exec?action=finishSelfEmployedRegistration',
        method: 'POST',
        body,
      }),
    }),
    authWorker: builder.mutation<DefaultResponse, { p: string }>({
      query: (params) => ({
        url: '/exec?action=authWorker',
        method: 'POST',
        params,
      }),
    }),
    authVerifyWorker: builder.mutation<
      DefaultResponse<UserData> & { authToken: string },
      { phone: string; code: string }
    >({
      query: (body) => ({
        url: '/exec?action=authVerifyWorker',
        method: 'POST',
        body,
      }),
    }),
    getSmsText: builder.query<{ text: string }, { unique: number }>({
      query: (body) => ({
        url: '/exec?action=getSmsText',
        method: 'POST',
        body,
      }),
    }),
    checkWorkerPhoneInGNK: builder.query<{ response: boolean }, { phone: string }>({
      query: (body) => ({
        url: '/exec?action=checkWorkerPhoneInGNK',
        method: 'POST',
        body,
      }),
    }),
    getAgentContractByPinfl: builder.query<{ document: string }, { pinfl: string; tin: string }>({
      query: (body) => ({
        url: '/exec?action=getAgentContractByPinfl',
        method: 'POST',
        body,
      }),
    }),
    getAssignmentContractByPinfl: builder.query<
      { document: string },
      { pinfl: string; tin: string }
    >({
      query: (body) => ({
        url: '/exec?action=getAssignmentContractByPinfl',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAuthCustomerMutation,
  useRegisterWorkerMutation,
  useVerifyFaceIdMutation,
  useCheckSelfEmployedStatusQuery,
  useLazyCheckSelfEmployedStatusQuery,
  useLazyGetSelfEmployedCertificateQuery,
  useGetSelfEmployedCertificateQuery,
  useRegisterSelfEmployedMutation,
  useLazyGetOfferContentQuery,
  useGetOfferContentQuery,
  useSignOfferMutation,
  useLazyGetGuaranteeAgreementQuery,
  useFinishSelfEmployedRegistrationMutation,
  useAuthWorkerMutation,
  useAuthVerifyWorkerMutation,
  useLazyGetSmsTextQuery,
  useLazyCheckWorkerPhoneInGNKQuery,
  useLazyGetAgentContractByPinflQuery,
  useLazyGetAssignmentContractByPinflQuery,
} = authApi;
