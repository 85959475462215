import { authSlice } from '@/entities/auth/model/slice';
import { configureStore } from '@reduxjs/toolkit';
import { mainApi } from '../api';
import { appSlice } from '@/entities/app/model/slice';
import { authApi } from '@/entities/auth/api';
import { didoxApi } from '../api/didox-api';

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [didoxApi.reducerPath]: didoxApi.reducer,
  },
  devTools: import.meta.env.DEV,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(mainApi.middleware)
      .concat(authApi.middleware)
      .concat(didoxApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
