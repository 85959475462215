import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, SxProps } from '@mui/material';
import { Header } from '@/shared/header';
import { Footer } from '@/shared/footer';

interface Props {
  CustomHeader?: () => JSX.Element;
  sx?: SxProps;
  ContainerProps?: BoxProps;
}

export const Layout: React.FC<PropsWithChildren<Props>> = ({
  CustomHeader = Header,
  children,
  sx,
  ContainerProps,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: '#F2F5FA',
        ...sx,
      }}
      id='cabinet-layout'
    >
      <Box
        component='main'
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
        id='cabinet-layout-main'
      >
        <CustomHeader />
        <Box
          {...ContainerProps}
          sx={{
            flex: 1,
            p: 2.5,
            display: 'flex',
            flexDirection: 'column',
            ...ContainerProps?.sx,
          }}
          component='div'
          id='cabinet-layout-content'
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};
