import { useMemo, useRef } from 'react';
import { useAddPaymentRegisterFromExcelMutation, useGetPaymentRegistersQuery } from '@/app/api';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { Layout } from '@/shared/layout';
import { UILoader } from '@/shared/ui/ui-loader';
import { UISection } from '@/shared/ui/ui-section';
import { Stack } from '@mui/material';
import { FileZone } from '@/shared/ui/ui-file-zone';
import { UIButton } from '@/shared/ui/ui-button';
import { UITable } from '@/shared/ui/ui-table';
import { useNavigate } from 'react-router-dom';
import { UIChip } from '@/shared/ui/ui-chip';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { useAppSelector, useFilters } from '@/app/hooks';
import { checkIfHasPermission, fileToBase64, handleError } from '@/app/helpers';
import dayjs from 'dayjs';

import { PlusIcon } from '@/shared/icons';
import TaskIcon from '@/app/assets/icons/task.svg?react';

import { useTranslation } from 'react-i18next';
import { FileZoneRef } from '@/shared/ui/ui-file-zone/types';
import { PaymentRegister } from '@/app/api/model';

export const CustomerDocumentsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { roles } = useAppSelector((state) => state.auth);
  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18742']), [roles]);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
    },
  });

  const { data, isFetching } = useGetPaymentRegistersQuery(filterParams);
  const [uploadExcel, { isLoading: isUploading }] = useAddPaymentRegisterFromExcelMutation();

  const fileZoneRef = useRef<FileZoneRef>(null);

  const onAddRegistryHandler = () => {
    fileZoneRef.current?.click();
  };

  const onRegistryFileSelect = async (files: FileList | null) => {
    if (!files) return;

    try {
      const base64 = await fileToBase64(files[0]).then((res) => res.split(',')[1]);

      await uploadExcel({
        base64,
        date: dayjs().format('DD/MM/YYYY'),
        id: Date.now(),
      }).unwrap();
    } catch (err) {
      handleError(err);
    }
  };

  const render = () => {
    if (isFetching && !data?.data?.length) {
      return <UILoader size={40} />;
    }

    if (!data?.data?.length) {
      return (
        <Stack spacing={2.5} alignItems='center' justifyContent='center' height='100%'>
          <EmptyPlaceholder sx={{ height: 'auto' }} title={t('no_documents')} Icon={<TaskIcon />} />
          <FileZone
            ref={fileZoneRef}
            onPick={onRegistryFileSelect}
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
              p: 0,
            }}
          >
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                onClick: onAddRegistryHandler,
                endIcon: <PlusIcon />,
                loading: isUploading,
                disabled: !hasPermission,
                children: t('upload_registry'),
              }}
            />
          </FileZone>
        </Stack>
      );
    }

    const onRowClick = (data: PaymentRegister) => {
      navigate(`/documents/${data.unique}?name=${data.name}`);
    };

    return (
      <>
        <Stack direction='row' justifyContent='flex-end'>
          <FileZone
            ref={fileZoneRef}
            onPick={onRegistryFileSelect}
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
              p: 0,
            }}
          >
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                sx: { maxWidth: 'fit-content' },
                endIcon: <PlusIcon />,
                onClick: onAddRegistryHandler,
                loading: isUploading,
                disabled: !hasPermission,
                children: t('upload_registry'),
              }}
            />
          </FileZone>
        </Stack>

        <UITable
          data={data.data}
          isLoading={isFetching}
          onRowClick={onRowClick}
          columns={[
            { label: '№', key: 'index', nowrap: true },
            { label: t('document_number'), key: 'name' },
            { label: t('date'), key: 'date' },
            {
              label: t('type'),
              render: () => <UIChip label={t('registry')} color='gray' />,
              nowrap: true,
            },
          ]}
          PaginationProps={{
            count: data.totalPages,
            page: filterParams.page,
            onChange: (_e, page) => onChangeFilterParams({ page }),
          }}
        />
      </>
    );
  };

  return (
    <Layout>
      <UISection sx={{ height: '100%' }}>{render()}</UISection>
    </Layout>
  );
};
