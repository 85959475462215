import { Box, ButtonBase, ButtonBaseProps, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import RuIcon from '@/app/assets/icons/ru.svg?react';
import UzIcon from '@/app/assets/icons/uz.svg?react';
import { useTranslation } from 'react-i18next';

interface Props {
  Container?: React.FC<ButtonBaseProps>;
}

const langs = [
  {
    name: 'RU',
    value: 'ru',
    icon: RuIcon,
    title: 'Русский',
  },
  {
    name: 'UZ',
    value: 'uz',
    icon: UzIcon,
    title: 'Ўзбекча',
  },
];

export const LangSwitcher: React.FC<Props> = ({ Container = ButtonBase }) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLang = (lang: string) => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
      localStorage.setItem('lang', lang);
      document.documentElement.lang = lang;
      window.location.reload();
    }
    handleClose();
  };

  const currentLang = langs.find((el) => el.value == i18n.language) || langs[0];
  const Icon = currentLang.icon;

  return (
    <Box>
      <Container
        id='lang-btn'
        onClick={handleClick}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
              width: '24px',
              height: '24px',
            },
          }}
        >
          <Icon />
        </Box>
      </Container>
      <Menu
        id='lang-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: '2px',
        }}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'lang-btn',
        }}
      >
        {langs.map((lng) => (
          <MenuItem
            key={lng.value}
            onClick={() => onChangeLang(lng.value)}
            sx={{
              fontWeight: lng.value === i18n.language ? 600 : 400,
            }}
          >
            {lng.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
