import {
  Checkbox,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { UITableProps, UITableRef } from './types';
import { Colors } from '@/app/constants/colors';
import { ReactElement, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UILoader } from '../ui-loader';
import { UITableRow } from './ui/ui-table-row';

const Comp = <T extends object>(props: UITableProps<T>, ref: React.ForwardedRef<UITableRef>) => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    PaginationProps,
    sx,
    checkbox,
    EmptyPlaceholder,
    isLoading,
    addittionRows,
  } = props;

  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    if (typeof checkbox === 'object') {
      setSelected(checkbox.selected);
    }
  }, [checkbox]);

  useImperativeHandle(
    ref,
    () => ({
      selected,
    }),
    [selected]
  );

  return (
    <TableContainer sx={sx}>
      {(!data || !data.length) && !isLoading && EmptyPlaceholder ? (
        EmptyPlaceholder
      ) : isLoading ? (
        <UILoader size={40} />
      ) : (
        <>
          <Table
            sx={{
              borderCollapse: 'separate',
              borderSpacing: addittionRows ? '0 40px' : '0 8px',
            }}
          >
            {columns.some((col) => col?.label) && (
              <TableHead>
                <TableRow>
                  {checkbox && (
                    <TableCell
                      sx={{
                        width: '1%',
                      }}
                    >
                      <Tooltip title={t('select_all')}>
                        <Checkbox
                          color='secondary'
                          checked={selected.length === data.length}
                          onChange={(_event, checked) => {
                            if (checked) {
                              if (typeof checkbox === 'object') {
                                checkbox.setSelected(Array.from(Array(data.length).keys()));
                              }
                              setSelected(Array.from(Array(data.length).keys()));
                            } else {
                              if (typeof checkbox === 'object') {
                                checkbox.setSelected([]);
                              }
                              setSelected([]);
                            }
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  )}
                  {columns.map((el, idx) => (
                    <TableCell
                      align={el?.headerAlign}
                      key={idx}
                      sx={{
                        lineHeight: 1,
                        py: 1,
                        ...(el?.nowrap
                          ? {
                              whiteSpace: 'nowrap',
                              width: '1%',
                            }
                          : {}),
                        ...el?.sx,
                      }}
                    >
                      <Typography
                        variant='caption'
                        color={Colors.BGRAY}
                        sx={{
                          fontWeight: 500,
                          fontSize: '1.4rem',
                        }}
                      >
                        {el?.label || ''}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data.map((el, index) => (
                <UITableRow
                  key={index}
                  {...props}
                  selected={selected}
                  setSelected={setSelected}
                  index={index}
                  element={el}
                />
              ))}
            </TableBody>
          </Table>
          {PaginationProps && !!PaginationProps.count && (
            <Stack
              direction='row'
              justifyContent='center'
              sx={{
                mt: 3,
              }}
            >
              <Pagination {...PaginationProps} disabled={PaginationProps?.disabled || isLoading} />
            </Stack>
          )}
        </>
      )}
    </TableContainer>
  );
};

export const UITable = forwardRef(Comp) as <T extends object>(
  props: UITableProps<T> & { ref?: React.ForwardedRef<UITableRef> }
) => ReactElement<UITableProps<T>>;
