import {
  Box,
  ButtonBase,
  Stack,
  StackProps,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import CopyIcon from '@/app/assets/icons/copy.svg?react';
import { useTranslation } from 'react-i18next';
import SuccessIcon from '@/app/assets/icons/check_circle.svg?react';

interface Props extends StackProps {
  TypographyProps?: TypographyProps;
}

export const UICopyText: FC<Props> = ({ TypographyProps, children, ...rest }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState<ReactNode>(t('to_copy'));

  const onClickhandler = () => {
    setTitle(
      <Stack spacing={1} direction='row'>
        <Box component={SuccessIcon} sx={{ width: '16px', height: '16px' }} />{' '}
        <Box>{t('toast.success')}</Box>
      </Stack>
    );
    navigator.clipboard.writeText(children as string);
  };

  const onCloseHandler = () => {
    setTimeout(() => {
      setTitle(t('to_copy'));
    }, 300)
  };

  return (
    <Stack direction='row' spacing={1} {...rest}>
      <Typography {...TypographyProps}>{children}</Typography>

      <Tooltip title={title} onClose={onCloseHandler}>
        <ButtonBase onClick={onClickhandler} disableRipple>
          <Box
            component={CopyIcon}
            sx={{
              width: '16px',
              height: '16px',
            }}
          />
        </ButtonBase>
      </Tooltip>
    </Stack>
  );
};
