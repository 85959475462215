import { TextField } from "@mui/material";
import { forwardRef } from "react";
import { PatternFormat } from "react-number-format";
import { FormattedInputProps } from "./types";

export const FormatedInput = forwardRef<HTMLInputElement, FormattedInputProps>(
  ({ format = "+998 (##) ###-##-##", ...rest }, ref) => {
    return (
      <PatternFormat
        format={format}
        customInput={TextField}
        {...rest}
        inputRef={ref}
      />
    );
  }
);
