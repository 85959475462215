export const DATE_FORMAT = 'DD.MM.YYYY';

export enum WorkerStatus {
  NOT_INVITED = 'notInvited',
  INVITED = 'invited',
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  CAN_NOT_BE_ACTIVATED = 'canNotBeInvited',
  WRONG_DATA = 'wrongData',
  ALL = '',
}

export enum WorkerAssigmentContractStatus {
  DRAFT = 'draft',
  WAIT_CONTRAGENT_SIGN = 'waitContragentSign',
  SIGNED = 'signed',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export const SIZES = [10, 20, 50, 100];
