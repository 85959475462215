import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Dialog, FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import { useOpen } from '@/app/hooks';
import { useForm } from 'react-hook-form';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import {
  useLazyGetCardInfoQuery,
  useLazyGetDriverBalanceQuery,
  useTransferMoneyToDriverMutation,
} from '@/app/api';
import { FormNumericInput } from '@/shared/inputs/form-numeric-input';
import FormFormattedInput from '@/shared/inputs/form-formatted-input';
import { UILoader } from '../../../shared/ui/ui-loader';
import { numericFormatter } from 'react-number-format';
import notify from '@/shared/toaster/lib/notify';
import { handleError } from '@/app/helpers';

import { useTranslation } from 'react-i18next';
import { SettlementsCreateTransferModalRef } from './types';
import { Colors } from '../../../app/constants/colors';

interface Data {
  unique: number;
  driverName: string;
}

interface FormValues {
  amount: string;
  cardNumber: string;
}

export const SettlementsCreateTransactionModal = forwardRef<SettlementsCreateTransferModalRef>(
  (_props, ref) => {
    const { t } = useTranslation();
    // const { company } = useAppSelector((state) => state.auth);
    const { control, handleSubmit, reset, watch } = useForm<FormValues>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });
    const [data, setData] = useState<Data>();
    const { open, setOpen } = useOpen();

    const [transferMoney, { isLoading }] = useTransferMoneyToDriverMutation();
    const [
      getCardInfo,
      {
        data: cardInfo,
        isError: isCardInfoError,
        error: cardInfoError,
        isFetching: isCardInfoLoading,
      },
    ] = useLazyGetCardInfoQuery();
    // const { data: driversBalance, isFetching: isBalanceFetching } = useGetDriverBalanceQuery(
    //   {
    //     p: data?.unique.toString() ?? '',
    //   },
    //   { skip: !data || !open }
    // );
    const [getDriverBalance, { data: driversBalance, isFetching: isBalanceFetching }] =
      useLazyGetDriverBalanceQuery();

    useImperativeHandle(
      ref,
      () => ({
        init: (params) => {
          setData(params);
          onOpen();
          getDriverBalance({ p: params.unique.toString() ?? '' }, true).unwrap();
        },
        open: onOpen,
        close: onClose,
      }),
      []
    );

    const onOpen = () => {
      setOpen(true);
    };

    const onClose = () => {
      if (isLoading) return;
      setOpen(false);
      setData(undefined);
      reset();
    };

    const onSubmitHandler = async (formData: FormValues) => {
      if (!data) return;

      try {
        // await eimzo.sign('Check if a user has the EDK', company?.tin ?? company?.companyTin ?? '');
        await transferMoney({
          amount: Number(formData.amount) * 100,
          cardNumber: formData.cardNumber.replace(/\D/g, ''),
          unique: data.unique,
        }).unwrap();
        notify('', 'success');
        onClose();
      } catch (err) {
        handleError(err);
      }
    };

    const cardInfoOwnerName = useMemo(() => {
      if (isCardInfoError) {
        if ('data' in cardInfoError && cardInfoError.data) {
          return cardInfoError.data.error.msg;
        }
        return t('card_number_error');
      }
      if (cardInfo?.data.name) {
        return cardInfo.data.name;
      }
      return '';
    }, [cardInfo?.data, cardInfoError]);

    const cardNumber = watch('cardNumber') ?? '';

    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            maxWidth: '560px',
            width: '100%',
          },
        }}
      >
        <ModalTemplate
          title={t('transfer_to_driver')}
          submitBtnText={t('send')}
          onClose={onClose}
          loading={isLoading}
          disabled={isCardInfoLoading || isCardInfoError}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack spacing={2}>
            <FormControl>
              <UIFormLabel>{t('drivers_fio')}:</UIFormLabel>
              <TextField value={data?.driverName} disabled />
              {!!driversBalance && !isBalanceFetching && (
                <FormHelperText>
                  {t('balance')}:{' '}
                  {numericFormatter((driversBalance?.balance ?? 0).toFixed(2), {
                    thousandSeparator: ' ',
                  })}{' '}
                  {t('currency_uzs')}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl>
              <UIFormLabel required>{t('card_number')}:</UIFormLabel>

              <FormFormattedInput
                format='#### #### #### ####'
                control={control}
                name='cardNumber'
                rules={{
                  required: true,
                }}
                onChange={(event) => {
                  const value = event.target.value.replace(/\D/g, '');
                  if (value.length === 16) {
                    getCardInfo({ cardNumber: value }).unwrap();
                  }
                }}
                InputProps={{
                  endAdornment: isCardInfoLoading && <UILoader size={20} />,
                }}
              />
              {cardNumber.replace(/\D/g, '').length === 16 &&
                !isCardInfoLoading &&
                cardInfoOwnerName && (
                  <FormHelperText
                    sx={{
                      color: isCardInfoError ? Colors.ERROR : Colors.BGRAY,
                    }}
                  >
                    {cardInfoOwnerName}
                  </FormHelperText>
                )}
            </FormControl>

            <FormControl>
              <UIFormLabel required>{t('sum')}:</UIFormLabel>
              <FormNumericInput
                control={control}
                name='amount'
                InputProps={{
                  endAdornment: t('currency_uzs'),
                }}
                rules={{
                  required: true,
                  max: {
                    value: driversBalance?.balance ?? 0,
                    message: t('errors:insufficient_funds'),
                  },
                }}
              />
            </FormControl>
          </Stack>
        </ModalTemplate>
      </Dialog>
    );
  }
);
