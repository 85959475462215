import { useGetPaymentRegisterQuery } from '@/app/api';
import { Colors } from '@/app/constants/colors';
import { useFilters } from '@/app/hooks';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { Layout } from '@/shared/layout';
import { UIButton } from '@/shared/ui/ui-button';
import { UILoader } from '@/shared/ui/ui-loader';
import { UISection } from '@/shared/ui/ui-section';
import { UITable } from '@/shared/ui/ui-table';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { numericFormatter } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';

export const CustomerRegistryDocumentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const urlQuery = new URLSearchParams(location.search);

  const registryName = urlQuery.get('name');

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
    },
  });

  const { data, isFetching } = useGetPaymentRegisterQuery(
    {
      unique: Number(id),
      ...filterParams,
    },
    { skip: !id }
  );

  return (
    <Layout>
      <UISection sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {isFetching ? (
          <UILoader />
        ) : (
          <>
            <Stack direction='row' spacing={2} alignItems='center'>
              <UIButton
                color='inherit'
                onClick={() => {
                  navigate('/documents');
                }}
              >
                {t('back')}
              </UIButton>

              <Typography variant='h5'>
                {t('registry')}{' '}
                <Box
                  component='span'
                  sx={{
                    color: Colors.BGRAY,
                  }}
                >
                  {registryName}
                </Box>
              </Typography>
            </Stack>

            <UITable
              data={data?.data ?? []}
              columns={[
                { label: '№', key: 'index', nowrap: true },
                { label: t('self_empl_fio'), key: 'name' },
                { label: t('pinfl'), key: 'pinfl' },
                {
                  label: `${t('sum')}, UZS`,
                  render: ({ amount }) => (
                    <Typography variant='h6'>
                      {numericFormatter((amount / 100).toFixed(2), {
                        thousandSeparator: ' ',
                      })}
                    </Typography>
                  ),
                  align: 'right',
                  headerAlign: 'right',
                },
                {
                  label: t('toast.error'),
                  render: ({ error }) =>
                    error && (
                      <Typography variant='body2' color={Colors.ERROR}>
                        {error}
                      </Typography>
                    ),
                },
              ]}
              EmptyPlaceholder={<EmptyPlaceholder />}
              sx={{ flex: 1 }}
              PaginationProps={{
                count: data?.totalPages ?? 0,
                page: filterParams.page,
                onChange: (_e, page) => onChangeFilterParams({ page }),
              }}
            />
          </>
        )}
      </UISection>
    </Layout>
  );
};
