import { useCallback, useEffect, useRef, useState } from 'react';
import { Layout } from '@/shared/layout';
import { UIStepper } from '@/shared/ui/ui-stepper';
import { Box, Container, Stack, StepIconProps, useMediaQuery } from '@mui/material';
import { EmployeeRegistrationStep } from './ui/employee-registration-step';
import { EmployeeIdentificationStep } from './ui/employee-identification-step';
import { SelfEmplStatus } from './ui/self-empl-status';
import { EmployeeRegistrationContext } from './lib/employee-registration-context';
import { RegistrationEndStep } from './ui/registration-end-step';

import TickCircleIcon from '@/app/assets/icons/tick-circle-rounded.svg?react';
import AfterArrow from '@/app/assets/icons/after-arrow.svg';

import { useTranslation } from 'react-i18next';
import { UIStepperRef } from '@/shared/ui/ui-stepper/types';
import { InvitationInfoResponse } from '@/app/api/model';
import { Colors } from '@/app/constants/colors';

export const EmployeeRegistrationPage = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:500px)');
  const [userData, setUserData] = useState<InvitationInfoResponse & { invitationCode: string }>();

  const stepperRef = useRef<UIStepperRef>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = 'Вы уверены, что хотите покинуть эту страницу?';
      event.returnValue = message; // Стандарт для большинства браузеров
      return message; // Для старых версий Firefox
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const IconComp = useCallback(({ completed, active, className, icon }: StepIconProps) => {
    if (completed) {
      return (
        <Box
          sx={{
            backgroundColor: Colors.SUCCESS,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1,
            borderRadius: '50%',
          }}
        >
          <Box component={TickCircleIcon} />
        </Box>
      );
    }

    return (
      <Box
        className={className}
        sx={{
          backgroundColor: Colors.WHITE_TEXT,
          color: active ? Colors.SECONDARY : Colors.BGRAY,
          width: 40,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          fontWeight: 600,
        }}
      >
        {icon}
      </Box>
    );
  }, []);

  return (
    <Layout
      sx={{
        backgroundColor: '#FFF',
      }}
    >
      <Container>
        <EmployeeRegistrationContext.Provider
          value={{
            userData,
            setUserData,
          }}
        >
          <Stack alignItems='center'>
            <UIStepper
              ref={stepperRef}
              data={[
                {
                  label: t('registration'),
                  Component: <EmployeeRegistrationStep stepperRef={stepperRef.current} />,
                },
                {
                  label: t('person_identification'),
                  Component: <EmployeeIdentificationStep stepperRef={stepperRef.current} />,
                },
                {
                  label: t('self_employed_status'),
                  Component: <SelfEmplStatus stepperRef={stepperRef.current} />,
                },
                {
                  label: t('registration_end'),
                  Component: <RegistrationEndStep />,
                },
              ]}
              sx={{
                maxWidth: 'max-content',
              }}
              StepperProps={{
                sx: {
                  mb: 3,
                },
              }}
              StepProps={{
                sx: {
                  px: 3,
                  position: 'relative',
                  '&.Mui-completed': {
                    backgroundColor: '#DBF9DC',
                  },
                  '&.active': {
                    [isMobile ? 'borderLeft' : 'borderBottom']: `3px solid ${Colors.SUCCESS}`,
                    backgroundColor: '#DBF9DC',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      right: '-10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      backgroundImage: `url(${AfterArrow})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '100%',
                      width: '20px',
                    },
                  },
                },
              }}
              StepLabelProps={{
                StepIconComponent: IconComp,
              }}
            />
          </Stack>
        </EmployeeRegistrationContext.Provider>
      </Container>
    </Layout>
  );
};
