import { eimzo } from '@/entities/eimzo';
import { didoxApi } from '../api/didox-api';
import { store } from '../store';

export const signDidoxDoc = (docId: string): Promise<void> => {
  return new Promise(async (res, rej) => {
    const { company } = store.getState().auth;
    if (!company) return rej();

    try {
      const promise = store.dispatch(didoxApi.endpoints.getDoc.initiate(docId));
      const json = await promise.unwrap();

      const signature = await eimzo.sign(JSON.stringify(json.data.json), company.tin);

      const signPromise = store.dispatch(didoxApi.endpoints.signDoc.initiate({ docId, signature }));

      await signPromise.unwrap();
      res();
    } catch (err) {
      return rej(err);
    }
  });
};

export const signOutgoingDoc = (docId: string): Promise<void> => {
  return new Promise(async (res, rej) => {
    const { company } = store.getState().auth;
    if (!company) return rej();

    try {
      const promise = store.dispatch(didoxApi.endpoints.getDoc.initiate(docId));
      const json = await promise.unwrap();

      // const signature = await eimzo.sign(JSON.stringify(json.data.json), company.tin);

      let toSign = json.data.toSign;
      if (!toSign) {
        const toSignPromise = store.dispatch(
          didoxApi.endpoints.getDocumentSignString.initiate({ action: 'accept', docId })
        );
        await toSignPromise.unwrap().then((res) => {
          toSign = res.data;
          return res;
        });
      }

      const signature = await eimzo.attach(toSign, company.tin);

      const signPromise = store.dispatch(didoxApi.endpoints.signDoc.initiate({ docId, signature }));

      await signPromise.unwrap();
      res();
    } catch (err) {
      return rej(err);
    }
  });
};

export const rejectDidoxDoc = (docId: string, comment: string): Promise<void> => {
  return new Promise(async (res, rej) => {
    const { company } = store.getState().auth;
    if (!company) return rej();

    try {
      const promise = store.dispatch(didoxApi.endpoints.getDoc.initiate(docId));
      const json = await promise.unwrap();

      let toSign = json.data.toSign;
      if (!toSign) {
        const toSignPromise = store.dispatch(
          didoxApi.endpoints.getDocumentSignString.initiate({
            action: 'reject',
            comment,
            docId,
          })
        );
        await toSignPromise.unwrap().then((res) => {
          toSign = res.data;
          return res;
        });
      }

      const signature = await eimzo.sign(JSON.stringify(toSign), company.tin);

      const signPromise = store.dispatch(
        didoxApi.endpoints.rejectDoc.initiate({ docId, data: { signature, comment } })
      );

      await signPromise.unwrap();
      res();
    } catch (err) {
      return rej(err);
    }
  });
};

// export const deleteDidoxDoc = (docId: string, comment: string): Promise<void> => {
//   return new Promise(async (res, rej) => {
//     const { company } = store.getState().auth;
//     if (!company) return rej();

//     try {
//       const promise = store.dispatch(didoxApi.endpoints.getDoc.initiate(docId));
//       const json = await promise.unwrap();

//       let toSign = json.data.toSign;
//       if (!toSign) {
//         const toSignPromise = store.dispatch(
//           didoxApi.endpoints.getDocumentSignString.initiate({
//             action: 'cancel',
//             comment,
//             docId,
//           })
//         );
//         await toSignPromise.unwrap().then((res) => {
//           toSign = res.data;
//           return res;
//         });
//       }

//       const signature = await eimzo.sign(JSON.stringify(toSign), company.tin);

//       const signPromise = store.dispatch(
//         didoxApi.endpoints.rejectDoc.initiate({ docId, data: { signature, comment } })
//       );

//       await signPromise.unwrap();
//       res();
//     } catch (err) {
//       return rej(err);
//     }
//   });
// };

export const deleteDraft = (docId: string): Promise<void> => {
  return new Promise(async (res, rej) => {
    try {
      await store.dispatch(didoxApi.endpoints.deleteDraft.initiate({ docId }));
      res();
    } catch (error) {
      return rej(error);
    }
  });
};
