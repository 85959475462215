import { FC, useEffect, useRef } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { useGetTelegramBotSettingsQuery } from '@/app/api';
import { UILoader } from '@/shared/ui/ui-loader';
import { FormNumericInput } from '@/shared/inputs/form-numeric-input';
import { UISwitch } from '@/shared/ui/ui-switch';

import { useTranslation } from 'react-i18next';
import { BotSettingsFormValues } from '../types';
import { TaxiParkBotPercentage } from '@/app/api/model';
import { ConfirmModalRef } from '../../../shared/confirm-modal/types';
import ConfirmModal from '../../../shared/confirm-modal';

interface Props {
  form: UseFormReturn<BotSettingsFormValues>;
  taxiParkUnique: string | number | undefined;
}

export const TaxiParkBotSettings: FC<Props> = ({ form, taxiParkUnique }) => {
  const { t } = useTranslation();

  const confirmModalRef = useRef<ConfirmModalRef>(null);

  const PERCENTAGE_OPTIONS = [
    {
      value: TaxiParkBotPercentage.FROM_TAXI_COMPANY,
      name: t('bot_percentage.on_employer'),
    },
    {
      value: TaxiParkBotPercentage.FROM_TAXI_DRIVER,
      name: t('bot_percentage.on_driver'),
    },
  ];

  const { control, reset } = form;

  const { data, isLoading } = useGetTelegramBotSettingsQuery(
    { p: String(taxiParkUnique) },
    {
      skip: !taxiParkUnique,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    reset({
      ...data,
      percentage:
        PERCENTAGE_OPTIONS.find((el) => el.value === data?.percentage)?.value ||
        PERCENTAGE_OPTIONS[0]?.value,
      replenishmentPercentage:
        PERCENTAGE_OPTIONS.find((el) => el.value === data?.replenishmentPercentage)?.value ||
        PERCENTAGE_OPTIONS[0]?.value,
    });
  }, [data]);

  if (isLoading) {
    return <UILoader />;
  }

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Typography variant='h6'>{t('bot_settings_title')}</Typography>

        <FormControl>
          <UIFormLabel>{t('money_limit_withdraw_from_wallet_per_day')}</UIFormLabel>

          <FormNumericInput
            control={control}
            name='limitOnWithdrawalsPerDay'
            rules={{
              required: true,
            }}
            InputProps={{
              endAdornment: t('currency_uzs'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('min_balance_in_drivers_wallet')}</UIFormLabel>

          <FormNumericInput
            control={control}
            name='minimumBalance'
            rules={{
              required: true,
            }}
            InputProps={{
              endAdornment: t('currency_uzs'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('min_withdrawal_amount')}</UIFormLabel>

          <FormNumericInput
            control={control}
            name='minimumAmountToWithdrawMoney'
            rules={{
              required: true,
            }}
            InputProps={{
              endAdornment: t('currency_uzs'),
            }}
          />
        </FormControl>

        <FormControl>
          <UIFormLabel>{t('percentage_for_money_transfer')}</UIFormLabel>

          <Stack spacing={1.5} direction='row'>
            <Controller
              control={control}
              name='percentage'
              render={({ field }) => {
                return (
                  <Select {...field} fullWidth size='small'>
                    {PERCENTAGE_OPTIONS.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </Stack>
        </FormControl>
      </Stack>
      <FormControl>
        <UIFormLabel>{t('drivers_options')}:</UIFormLabel>

        <Stack spacing={1.5} direction='row'>
          <Controller
            control={control}
            name='checkSelfEmployment'
            render={({ field }) => {
              return (
                <FormControlLabel
                  {...field}
                  checked={field.value ?? false}
                  sx={{
                    '& .MuiTypography-root': {
                      ml: 1,
                      fontSize: '1.6rem',
                      fontWeight: 600,
                    },
                  }}
                  control={<UISwitch />}
                  label={t('check_self_employment')}
                />
              );
            }}
          />
        </Stack>
      </FormControl>

      <FormControl>
        <Stack spacing={1.5} direction='row'>
          <Controller
            control={control}
            name='checkCommittee'
            render={({ field }) => {
              return (
                <FormControlLabel
                  {...field}
                  checked={field.value ?? false}
                  sx={{
                    '& .MuiTypography-root': {
                      ml: 1,
                      fontSize: '1.6rem',
                      fontWeight: 600,
                    },
                  }}
                  control={<UISwitch />}
                  label={t('check_committee_status')}
                />
              );
            }}
          />
        </Stack>
      </FormControl>

      <FormControl>
        <Stack spacing={1.5} direction='row'>
          <Controller
            control={control}
            name='faceIdToRegister'
            render={({ field }) => {
              return (
                <FormControlLabel
                  {...field}
                  onChange={async (_event, checked) => {
                    if (!checked) {
                      await confirmModalRef.current?.init({
                        text: t('face_id_confirmation_text'),
                      });
                    }
                    field.onChange(checked);
                  }}
                  checked={field.value ?? false}
                  sx={{
                    '& .MuiTypography-root': {
                      ml: 1,
                      fontSize: '1.6rem',
                      fontWeight: 600,
                    },
                  }}
                  control={<UISwitch />}
                  label={t('face_id_during_registration')}
                />
              );
            }}
          />
        </Stack>
      </FormControl>

      <Typography variant='h6'>{t('bot_settings_subtitle')}</Typography>

      <FormControl>
        <UIFormLabel>{t('percentage_for_money_topup')}</UIFormLabel>

        <Stack spacing={1.5} direction='row'>
          <Controller
            control={control}
            name='replenishmentPercentage'
            render={({ field }) => {
              return (
                <Select {...field} fullWidth size='small'>
                  {PERCENTAGE_OPTIONS.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </Stack>
      </FormControl>
      <ConfirmModal ref={confirmModalRef} />
    </Stack>
  );
};
