import { FC } from 'react';
import { UITable } from '@/shared/ui/ui-table';
import { Box, Stack, Typography } from '@mui/material';
import { PaginationParams, getSumFromTiins } from '@/app/helpers';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { useFilters } from '@/app/hooks';
import { useGetTransactionsQuery } from '@/app/api';
import { numericFormatter } from 'react-number-format';

import IncomeIcon from '@/app/assets/icons/add-circle.svg?react';
import ExpenseIcon from '@/app/assets/icons/minus-cirlce.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

interface Props {
  isTiins: boolean;
}

export const BalanceInfoTable: FC<Props> = ({ isTiins }) => {
  const { t } = useTranslation();

  const { filterParams, onChangeFilterParams } = useFilters<PaginationParams>({
    initialFilterParams: {
      page: 1,
      pageSize: 20,
    },
  });

  const { data, isLoading } = useGetTransactionsQuery(filterParams);

  return (
    <Box>
      {/* <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1}>
            <FormTextInput
              control={control}
              name="search"
              label={t("search")}
            />
            <UIButton>{t("to_find")}</UIButton>
          </Stack>

          <UIDatePicker label={t("from") + ":"} />
          <UIDatePicker label={t("to") + ":"} />
        </Stack>

        <UIButton
          color="secondary"
          variant="outlined"
          onClick={excelModalRef.current?.open}
        >
          {t("excel_download")}
        </UIButton>
      </Stack> */}

      <UITable
        isLoading={isLoading}
        EmptyPlaceholder={<EmptyPlaceholder subtitle={t('no_transactions')} />}
        data={data?.data ?? []}
        columns={[
          {
            label: '№',
            key: 'index',
            nowrap: true,
          },
          {
            label: t('date'),
            key: 'createdAt',
          },
          {
            label: t('correspondent'),
            render: ({ recipient, sender, action }) => {
              let name = recipient?.recipientName || '';
              let tinOrPinfl = '';

              if (action === 'income') {
                name = sender?.senderName;

                if (sender?.senderTIN) {
                  tinOrPinfl = sender?.senderTIN;
                } else if (sender?.senderPINFL) {
                  tinOrPinfl = sender?.senderPINFL;
                }
              } else {
                if (recipient?.recipientTIN) {
                  tinOrPinfl = recipient?.recipientTIN;
                } else if (recipient?.recipientPINFL) {
                  tinOrPinfl = recipient?.recipientPINFL;
                }
              }

              return (
                <Typography variant='body2'>
                  {name}{' '}
                  <Box component='span' color={Colors.BGRAY}>
                    {tinOrPinfl}
                  </Box>
                </Typography>
              );
            },
          },
          {
            label: t('comment'),
            key: 'goodsName',
          },
          {
            label: `${t('sum')}, UZS`,
            align: 'right',
            headerAlign: 'right',
            render: ({ amount, action }) => (
              <Stack direction='row' alignItems='center' spacing={0.5} justifyContent='flex-end'>
                {(() => {
                  switch (action) {
                    case 'income':
                      return <Box component={IncomeIcon} />;
                    case 'expense':
                      return <Box component={ExpenseIcon} />;
                    default:
                      return null;
                  }
                })()}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.6rem',
                  }}
                >
                  {numericFormatter(String(getSumFromTiins(amount, isTiins).toFixed(2)), {
                    thousandSeparator: ' ',
                  })}
                </Typography>
              </Stack>
            ),
          },
        ]}
        PaginationProps={{
          count: data?.totalPages,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
    </Box>
  );
};
