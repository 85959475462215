import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { SmsConfirmModalProps, SmsConfirmModalRef } from "./types";
import {
  Box,
  ButtonBase,
  Dialog,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useOpen } from "@/app/hooks";
import { useTranslation } from "react-i18next";
import ModalTemplate from "@/shared/modal-template";
import { Colors } from "@/app/constants/colors";
import { UIFormLabel } from "@/shared/ui/ui-form-label";
import { UIButton } from "@/shared/ui/ui-button";
import { UIAlert } from "@/shared/ui/ui-alert";

export const SmsConfirmModal = forwardRef<
  SmsConfirmModalRef,
  SmsConfirmModalProps
>(({ length = 5, loading, reSendSms, blockClose }, ref) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobileWidth = useMediaQuery(breakpoints.down("sm"));
  const isMobileHeight = useMediaQuery("(max-height: 600px)");
  const { open, setOpen } = useOpen();
  const [phone, setPhone] = useState("");
  const [smsCode, setSmsCode] = useState(Array.from({ length }, () => ""));
  const [time, setTime] = useState(60);
  const [isError, setIsError] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rejectCallback = useRef<Function>();
  const successCallback = useRef<(code: string) => void>();

  const isMobile = isMobileWidth || isMobileHeight;

  useImperativeHandle(
    ref,
    () => ({
      init,
      close: () => setOpen(false),
    }),
    []
  );

  useEffect(() => {
    if (!open) return;

    return () => {
      setTime(60);
      setSmsCode(Array.from({ length }, () => ""));
      setIsError(false);
    };
  }, [open]);

  useEffect(() => {
    if (!open) return;

    const timeout = setTimeout(() => {
      setTime((prev) => {
        if (prev === 0) {
          clearTimeout(timeout);
          return 0;
        }

        return prev - 1;
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [open, time]);

  useEffect(() => {
    if (smsCode.every((code) => !code)) {
      inputRefs.current[0]?.focus();
    }
  }, [smsCode]);

  const init = async (
    phone: string,
    cb?: (code: string) => Promise<void>
  ): Promise<{ code: string }> => {
    return new Promise((resolve, reject) => {
      rejectCallback.current = reject;
      successCallback.current = async (code: string) => {
        if (cb) {
          try {
            try {
              await cb(code);
              return resolve({ code });
            } catch {
              setIsError(true);
              setSmsCode(Array.from({ length }, () => ""));
            }
          } catch {
            return reject();
          }
        } else {
          return resolve({ code });
        }
      };

      setPhone(phone);
      setOpen(true);
    });
  };

  const onCloseHandler = () => {
    setOpen(false);
    setIsError(false);
    rejectCallback.current?.();
  };

  const handleInputChange = (
    index: number,
    event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    val?: string
  ) => {
    setIsError(false);
    const value = event?.target.value ?? val ?? "";
    const regex = /^[0-9\b]+$/;

    const cursorPosition = event ? event.target.selectionStart || 1 : 1;

    if (value && !regex.test(value)) {
      return;
    }

    const newCode = [...smsCode];
    newCode[index] = value[cursorPosition - 1];
    setSmsCode(newCode);

    if (value && index < length - 1) {
      inputRefs.current?.[index + 1]?.focus();
    }

    if (value && index === length - 1) {
      inputRefs.current?.[index]?.blur();
      setTimeout(() => {
        buttonRef.current?.focus();
      }, 10);
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (/\[0-9]*/.test(event.key) && smsCode[index]) {
      inputRefs.current[index + 1]?.focus();
    }
    if (event.key === "Backspace") {
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      handleInputChange(index, undefined, "");
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text").slice(0, length);

    if (pastedData.length <= length) {
      const newCode = pastedData.split("");
      setSmsCode((prevCode) => {
        const updatedCode = [...prevCode];
        newCode.forEach((char, index) => {
          updatedCode[index] = char;
          if (index < length - 1) {
            inputRefs.current[index + 1]?.focus();
          }
        });
        return updatedCode;
      });
    }
  };

  const onResendSmsHandler = async () => {
    setIsResendLoading(true);
    try {
      await reSendSms?.();
      setTime(60);
      setIsResendLoading(false);
    } catch {
      setIsResendLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "560px",
          maxHeight: "100vh",
          width: "100%",
          my: 1,
        },
      }}
    >
      <ModalTemplate
        title={t("number_confirm")}
        onClose={!blockClose ? onCloseHandler : undefined}
        onSubmit={(event) => {
          event.preventDefault();
          successCallback.current?.(smsCode.join(""));
        }}
        noBtns
        absoluteCloseBtn
        sx={{
          ...(isMobile && {
            p: 2,
            overflow: "visible",
            maxHeight: "100vh",
          }),
        }}
        ContainerProps={{
          sx: {
            mb: 2,
            maxHeight: "max-content",
          },
        }}
        TitleProps={{
          textAlign: "center",
          sx: {
            ...(isMobile && {
              fontSize: "1.8rem",
              mx: 3,
            }),
          },
        }}
        CloseBtnSx={{
          ...(isMobile && {
            right: "10px",
          }),
        }}
      >
        <Typography
          variant="subtitle2"
          color={Colors.BGRAY}
          textAlign="center"
          sx={{
            ...(isMobile && {
              fontSize: "1.2rem",
            }),
          }}
        >
          {t("sms_confirmation_message", { phone })}
        </Typography>

        <FormControl
          component={Stack}
          alignItems="center"
          sx={{
            mt: isMobile ? 1 : 3,
          }}
        >
          <UIFormLabel>{t("sms_confrimtion_code")}:</UIFormLabel>
          <Stack direction="row" spacing={1}>
            {Array.from({ length }, (_, index) => (
              <TextField
                error={isError}
                autoFocus={index === 0}
                onPaste={handlePaste}
                key={index}
                value={smsCode[index] || ""}
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                inputRef={(el) => (inputRefs.current[index] = el!)}
                inputProps={{ maxLength: 2, pattern: "[0-9]*" }}
                sx={{
                  width: isMobile ? "30px" : "40px",
                  ...(isMobile && {
                    "& .MuiInputBase-input": {
                      px: 0.5,
                      py: 0.5,
                      textAlign: "center",
                    },
                  }),
                }}
              />
            ))}
          </Stack>

          {isError && (
            <UIAlert
              text={t("errors:incorrect_sms")}
              type="error"
              sx={{
                my: 2,
              }}
            />
          )}

          <UIButton
            ref={buttonRef}
            sx={{
              mt: 2.5,
            }}
            disabled={smsCode.some((code) => !code)}
            loading={loading}
            type="submit"
            size={isMobile ? "small" : "medium"}
          >
            {t("confirm")}
          </UIButton>

          {!reSendSms && (
            <>
              <Typography
                variant="subtitle1"
                fontWeight={500}
                sx={{
                  mt: 2.5,
                  ...(isMobile && {
                    fontSize: "1.2rem",
                  }),
                }}
              >
                {t("didnt_receive_sms")}
              </Typography>
              {!time ? (
                <ButtonBase
                  onClick={onResendSmsHandler}
                  disabled={isResendLoading}
                  disableRipple
                >
                  <Typography
                    variant="subtitle2"
                    color={isResendLoading ? Colors.BGRAY : Colors.ACCENT}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {t("resend_sms")}
                  </Typography>
                </ButtonBase>
              ) : (
                <Typography
                  variant="subtitle2"
                  color={Colors.BGRAY}
                  textAlign="center"
                  sx={{
                    ...(isMobile && {
                      fontSize: "1.2rem",
                      lineHeight: 1.5,
                    }),
                  }}
                >
                  {t("resend_sms_after", { time })}
                </Typography>
              )}
            </>
          )}
        </FormControl>
      </ModalTemplate>
    </Dialog>
  );
});
