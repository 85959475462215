import { ComponentType, ForwardedRef, forwardRef } from 'react';
import { Box, Tooltip } from '@mui/material';

import { useTranslation } from 'react-i18next';

type ExtendedProps<T> = T & { disabled?: boolean; showTooltip?: boolean };

interface PermissionCheckHOCProps<T> {
  WrappedComponent: ComponentType<T> & { ref?: ForwardedRef<HTMLElement> };
  props: ExtendedProps<T>;
}

const PermissionCheckHOCBase = <T,>(
  { WrappedComponent, props }: PermissionCheckHOCProps<T>,
  ref?: ForwardedRef<HTMLElement>
) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={(props.showTooltip ?? true) && props.disabled ? t('permissions_are_restricted') : ''}
    >
      <Box component='span'>
        <WrappedComponent {...props} ref={ref} />
      </Box>
    </Tooltip>
  );
};

const PermissionCheckHOC = forwardRef(PermissionCheckHOCBase) as <T>(
  props: PermissionCheckHOCProps<T>
) => JSX.Element;

export default PermissionCheckHOC;
