import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { UITable } from '../../../shared/ui/ui-table';
import { EmptyPlaceholder } from '../../../shared/empty-placeholder';
import { UIChip } from '../../../shared/ui/ui-chip';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FilterParams } from '../types';
import {
  DocumentReportStatusId,
  GetDocumentReportStatuses,
  GetYandexReportsData,
} from '../../../app/api/model';
import { UIChipColor } from '../../../shared/ui/ui-chip/types';
import { DefaultResponseWithPagination } from '../../../app/helpers';

interface YandexReportsTableProps {
  isFetchingYandexReports: boolean;
  yandexReportsData:
    | (DefaultResponseWithPagination<GetYandexReportsData[]> & { nextPage: number })
    | undefined;
  statusesData: GetDocumentReportStatuses[] | undefined;
  filterParams: FilterParams;
  onChangeFilterParams: (params: Partial<FilterParams>) => void;
  getChipType: (
    status: DocumentReportStatusId,
    label: string
  ) => {
    label: string;
    color: UIChipColor;
  };
}

export const YandexReportsTable: FC<YandexReportsTableProps> = ({
  isFetchingYandexReports,
  yandexReportsData,
  statusesData,
  filterParams,
  onChangeFilterParams,
  getChipType,
}) => {
  const { t } = useTranslation();
  return (
    <UITable
      data={yandexReportsData?.data ?? []}
      EmptyPlaceholder={<EmptyPlaceholder />}
      isLoading={isFetchingYandexReports}
      columns={[
        {
          label: '№',
          render: (_, index) => index + 1,
          nowrap: true,
        },
        { label: t('date'), key: 'date' },
        {
          label: t('period'),
          render: (row) => (
            <Stack direction='row' spacing={1.5}>
              <Typography variant='body2'>{row.dateFrom}</Typography>
              <Typography variant='body2'>-</Typography>
              <Typography variant='body2'>{row.dateTo}</Typography>
            </Stack>
          ),
        },
        { label: t('number'), key: 'number' },
        {
          label: t('type'),
          render: ({ unique, type }) => {
            return (
              <Box
                component={Link}
                to={`/customer/reports/${unique}`}
                sx={{ textDecoration: 'none' }}
              >
                {type}
              </Box>
            );
          },
        },
        { label: t('sum'), key: 'amount' },
        {
          label: t('status'),
          render: ({ status }) => {
            const exactStatus = statusesData?.find((obj) => obj.name === status);
            if (exactStatus) return <UIChip {...getChipType(exactStatus?.id, status)} />;
            return '';
          },
        },
      ]}
      PaginationProps={{
        count: yandexReportsData?.totalPages ?? 0,
        page: filterParams.page,
        onChange: (_e, page) => onChangeFilterParams({ page }),
        disabled: typeof yandexReportsData?.totalPages === 'undefined',
      }}
    />
  );
};
