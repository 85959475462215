import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useImportReportRowsMutation } from '@/app/api';
import { BaseModalRef, fileToBase64, handleError } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import { FilePicker } from '@/shared/inputs/file-picker';
import ModalTemplate from '@/shared/modal-template';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { UITable } from '@/shared/ui/ui-table';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import dayjs from 'dayjs';
import _ from 'lodash';
import notify from '@/shared/toaster/lib/notify';

import { useTranslation } from 'react-i18next';
import { CustomYandexReportRowInterface } from '../types';
import { UIButton } from '@/shared/ui/ui-button';
import { useReportsContext } from '../context';

export const ImportRowsModal = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { form, isTravelReport, isRewardReport, isCorporateReport } = useReportsContext();
  const { open, setOpen } = useOpen();
  const [reportRows, setReportRows] = useState<CustomYandexReportRowInterface[]>([]);

  const [files, setFiles] = useState<File[]>([]);

  const [importReportRows, { isLoading }] = useImportReportRowsMutation();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setFiles([]);
    setReportRows([]);
  };

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!files.length) return;

    const base64 = await fileToBase64(files[0]);

    try {
      const response = await importReportRows({ base64: base64.split(',')[1] })
        .unwrap()
        .then((res) => res.data);
      const editedResponseRows: CustomYandexReportRowInterface[] = response.map((obj) => {
        const rowToSet = { pinfl: obj.pinfl, name: obj.name };
        if (isTravelReport || isCorporateReport) {
          Object.assign(rowToSet, {
            amount: Number(obj.amount),
            aggregatorPercent: Number(obj.aggregatorPercent),
            taxiPercent: Number(obj.taxiPercent),
          });

          if (obj && obj.contractDate) {
            Object.assign(rowToSet, {
              contractNumber: obj.contractNumber,
              contractDate: dayjs(obj.contractDate).format('DD.MM.YYYY'),
            });
          }
        }

        if (isRewardReport) {
          Object.assign(rowToSet, {
            taxiDriverReward: Number(obj.taxiDriverReward ?? obj.amount),
          });
        }

        return rowToSet;
      });
      setReportRows(editedResponseRows);
      notify('', 'success');
    } catch (err) {
      handleError(err);
    }
  };

  const saveReportRows = () => {
    const prevRows = form.getValues('yandexReportRows');
    const rowsToSet = [...(prevRows ?? []), ...reportRows];
    form.setValue('yandexReportRows', rowsToSet);
    onClose();
  };

  const calculateDriversProfit = (row: CustomYandexReportRowInterface) => {
    const amount = row.amount;
    const aggregatorPercent = row.aggregatorPercent;
    const taxiPercent = row.taxiPercent;
    let driversProfit = amount;

    if (aggregatorPercent && taxiPercent && amount) {
      const aggregatorFee = _.multiply(amount, _.divide(aggregatorPercent, 100));
      const taxiFleetFee = _.multiply(amount, _.divide(taxiPercent, 100));

      driversProfit = +(amount - aggregatorFee - taxiFleetFee).toFixed(2);
    }

    return driversProfit;
  };

  const onClickRemoveRow = (index: number) => {
    const clonedRow = reportRows.slice();

    clonedRow.splice(index, 1);
    setReportRows(clonedRow);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: reportRows.length ? 1000 : 560,
        },
      }}
    >
      {reportRows.length ? (
        <ModalTemplate
          title={t('customer_trips_page.download_values_from_file')}
          onClose={onClose}
          submitBtnText={t('upload')}
          loading={isLoading}
          noBtns
        >
          <UITable
            data={reportRows}
            EmptyPlaceholder={<EmptyPlaceholder />}
            columns={[
              {
                label: '№',
                render: (_, index) => index + 1,
                nowrap: true,
              },
              {
                label: t('customer_trips_page.employee'),
                render: (row) => (
                  <Stack spacing={1.5}>
                    <Typography variant='body2'>{row.name}</Typography>
                    <Typography variant='body2'>{row.pinfl}</Typography>
                  </Stack>
                ),
              },
              isTravelReport
                ? {
                    label: t('customer_trips_page.contract_number'),
                    key: 'contractNumber',
                  }
                : null,
              isTravelReport
                ? {
                    label: t('customer_trips_page.contract_date'),
                    render: (row) => {
                      const dateToShow = dayjs(row.contractDate).format('DD.MM.YYYY');
                      return dateToShow;
                    },
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.total_sum'),
                    key: 'amount',
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.aggregator_percent') + ', %',
                    key: 'aggregatorPercent',
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.aggregator_fee'),
                    render: (row) =>
                      +(
                        'aggregatorCommission' in row
                          ? row?.aggregatorCommission ?? 0
                          : _.multiply(row?.amount ?? 0, _.divide(row?.aggregatorPercent ?? 0, 100))
                      ).toFixed(2),
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.taxi_fleet_percent') + ', %',
                    key: 'taxiPercent',
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.taxi_fleet_fee'),
                    render: (row) =>
                      +(
                        'taxiCommission' in row
                          ? row?.taxiCommission ?? 0
                          : _.multiply(row?.amount ?? 0, _.divide(row?.taxiPercent ?? 0, 100))
                      ).toFixed(2),
                  }
                : null,
              !isRewardReport
                ? {
                    label: t('customer_trips_page.drivers_sum'),
                    render: (row) => {
                      return calculateDriversProfit(row);
                    },
                  }
                : null,
              isRewardReport
                ? {
                    label: t('customer_trips_page.drivers_bonuses'),
                    key: 'taxiDriverReward',
                  }
                : null,
              {
                render: (_row, index) => {
                  return (
                    <Tooltip title={t('delete')}>
                      <Box component='span' onClick={() => onClickRemoveRow(index)}>
                        <UIActionButton icon='delete' />
                      </Box>
                    </Tooltip>
                  );
                },
              },
            ]}
          />
          <Stack direction='row' justifyContent='flex-end' spacing={1.5}>
            <UIButton onClick={onClose} color='inherit' disabled={isLoading}>
              {t('cancel')}
            </UIButton>

            <UIButton type='submit' onClick={saveReportRows} disabled={isLoading} color='primary'>
              {t('save')}
            </UIButton>
          </Stack>
        </ModalTemplate>
      ) : (
        <ModalTemplate
          title={t('customer_trips_page.download_values_from_file')}
          onClose={onClose}
          submitBtnText={t('upload')}
          loading={isLoading}
          onSubmit={handleSubmit}
        >
          <FormControl>
            <UIFormLabel>{t('attach_file')}:</UIFormLabel>

            <FilePicker value={files} onChange={setFiles} accept='.xlsx, .xls' />
            <FormHelperText>{t('excel_upload_helper_text')}</FormHelperText>
          </FormControl>
        </ModalTemplate>
      )}
    </Dialog>
  );
});
