import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Dialog, Stack, Typography } from '@mui/material';
import ModalTemplate from '@/shared/modal-template';
import { UIChip } from '@/shared/ui/ui-chip';
import { UITable } from '@/shared/ui/ui-table';
import { numericFormatter } from 'react-number-format';
import PermissionCheckHOC from '../../../shared/permission-hoc';
import {
  useGetImportWorkersFromYandexHistoryNotDownloadedFileMutation,
  useGetImportWorkersFromYandexHistoryQuery,
} from '@/app/api';
import { useAppSelector, useFilters, useOpen } from '@/app/hooks';

import { useTranslation } from 'react-i18next';
import { UIChipColor, UIChipProps } from '@/shared/ui/ui-chip/types';
import { BaseModalRef, checkIfHasPermission } from '@/app/helpers';
import { Colors } from '@/app/constants/colors';

export const ImportYandexDriversHistoryModal = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { roles } = useAppSelector((state) => state.auth);
  const { open, setOpen } = useOpen(false);
  const [isDownloading, setIsDownloading] = useState<number[]>([]);

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
    },
  });

  const { data, isFetching } = useGetImportWorkersFromYandexHistoryQuery(filterParams, {
    skip: !open,
    refetchOnMountOrArgChange: true,
  });
  const [getNotDownloadedMen] = useGetImportWorkersFromYandexHistoryNotDownloadedFileMutation();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getStatusChipProps = (status: string): UIChipProps => {
    const label = t(`yandex_import_statuses.${status}`);
    let color: UIChipColor = 'gray';

    switch (status) {
      case 'inProgress':
      case 'waiting':
        color = 'warning';
        break;
      case 'error':
        color = 'error';
        break;
      case 'finished':
        color = 'success';
        break;
      default:
        break;
    }

    return {
      color,
      label,
    };
  };

  const getExcelOfNotDownloadedMen = async (unique: number) => {
    if (isDownloading.includes(unique)) return;
    try {
      setIsDownloading((prev) => [...prev, unique]);
      await getNotDownloadedMen({ unique }).unwrap();
    } finally {
      setIsDownloading((prev) => prev.filter((el) => el !== unique));
    }
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18722', '18732']), [roles]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '940px',
          width: '100%',
        },
      }}
    >
      <ModalTemplate noBtns onClose={onClose} title={t('import_drivers_from_yandex_go_history')}>
        <UITable
          isLoading={isFetching}
          data={data?.data ?? []}
          columns={[
            { label: '№', key: 'index' },
            { label: t('date'), key: 'createdAt' },
            {
              label: t('total_drivers'),
              render: ({ totalWorkers }) =>
                totalWorkers && numericFormatter(String(totalWorkers), { thousandSeparator: ',' }),
            },
            {
              label: t('successfully_imported_drivers'),
              render: ({ downloadedWorkers }) =>
                downloadedWorkers &&
                numericFormatter(String(downloadedWorkers), { thousandSeparator: ',' }),
            },
            {
              label: t('section'),
              render: (row) => (
                <Typography>
                  {row.section === 'workers' ? t('workers') : t('mutual_settlements')}
                </Typography>
              ),
            },
            {
              label: t('status'),
              render: ({ status }) => <UIChip {...getStatusChipProps(status)} />,
              nowrap: true,
            },
            {
              label: t('did_not_download'),
              render: ({ hasNotDownloadedFile, notDownloadedCount, unique }) => {
                if (hasNotDownloadedFile) {
                  const isInProgress = isDownloading.includes(unique);

                  return (
                    <Stack direction='row' spacing={1.5}>
                      <Typography variant='body2'>{notDownloadedCount ?? 0}</Typography>
                      <PermissionCheckHOC
                        WrappedComponent={Typography}
                        props={{
                          variant: 'body2',
                          onClick: () => getExcelOfNotDownloadedMen(unique),
                          sx: {
                            color: Colors.INFO,
                            cursor: isInProgress
                              ? 'progress'
                              : !hasPermission
                              ? 'not-allowed'
                              : 'pointer',
                            pointerEvents: isInProgress || !hasPermission ? 'none' : 'auto',
                            opacity: isInProgress || !hasPermission ? '0.5' : '1',
                          },
                          children: t('download'),
                        }}
                      />
                    </Stack>
                  );
                } else return null;
              },
            },
            //@ts-ignore
            ...(data?.data.some((el) => el.errorText)
              ? [
                  {
                    label: t('error'),
                    key: 'errorText',
                  },
                ]
              : []),
          ]}
          PaginationProps={{
            count: data?.totalPages ?? 0,
            page: filterParams.page,
            onChange: (_e, page) => onChangeFilterParams({ page }),
          }}
        />
      </ModalTemplate>
    </Dialog>
  );
});
