import { Colors } from '@/app/constants/colors';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';

export const MuiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:500px)');

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: Colors.PRIMARY,
          },
          secondary: {
            main: Colors.SECONDARY,
          },
          warning: {
            main: Colors.WARNING,
          },
          info: {
            main: Colors.INFO,
          },
          error: {
            main: Colors.ERROR,
          },
          success: {
            main: Colors.SUCCESS,
          },
          text: {
            primary: Colors.SECONDARY,
            disabled: 'rgba(0, 0, 0, 0.26)',
          },
        },
        typography: {
          fontFamily: 'inter, sans-serif',
          htmlFontSize: 10,
          h2: {
            fontWeight: 600,
          },
          h3: {
            fontWeight: 600,
          },
          h4: {
            fontWeight: 600,
          },
          h5: {
            fontWeight: 600,
          },
          h6: {
            fontWeight: 600,
          },
          subtitle1: {
            fontWeight: 400,
          },
          button: {
            fontWeight: 600,
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1240,
            xl: 1536,
          },
        },
        components: {
          MuiContainer: {
            defaultProps: {
              disableGutters: true,
            },
            styleOverrides: {
              maxWidthLg: {
                paddingLeft: '6px',
                paddingRight: '6px',
              },
            },
          },
          MuiTypography: {
            defaultProps: {
              color: 'textPrimary',
            },
          },
          MuiTextField: {
            defaultProps: {
              color: 'secondary',
              size: 'small',
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '&.Mui-disabled': {
                  backgroundColor: Colors.LGRAY,
                },
              },
              notchedOutline: {
                borderColor: 'rgba(112, 125, 159, 0.4)',
              },
            },
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                color: Colors.BGRAY,
                marginRight: 0,
                marginLeft: 0,
                font: '0.86rem',
                fontWeight: 500,
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              colorSecondary: {
                color: Colors.ACCENT,
                '&.Mui-checked': {
                  color: Colors.ACCENT,
                },
              },
            },
          },
          MuiButton: {
            defaultProps: {
              variant: 'contained',
              disableRipple: true,
              size: 'medium',
            },
            styleOverrides: {
              root: {
                fontSize: '1.4rem',
                borderRadius: '6px',
                color: Colors.SECONDARY,
                textTransform: 'none',
                lineHeight: 1.3,
                height: '40px',
                boxShadow: 'none',
                '&.rounded': {
                  borderRadius: '50px',
                },
              },
              contained: {
                '&.Mui-disabled': {
                  backgroundColor: Colors.LGRAY,
                  color: 'rgba(0, 0, 0, 0.26)',
                },
              },
              containedError: {
                color: Colors.WHITE_TEXT,
              },
              containedPrimary: {
                '&:hover': {
                  backgroundColor: Colors.PRIMARY,
                  // color: Colors.ACCENT,

                  // "& svg": {
                  //   fill: Colors.ACCENT,
                  //   stroke: Colors.ACCENT,
                  // },
                },
              },
              outlinedSecondary: {
                color: Colors.ACCENT,
                borderColor: Colors.ACCENT,
              },
              containedInherit: {
                backgroundColor: Colors.LGRAY,
              },
              sizeSmall: {
                height: '28px',
              },
              sizeLarge: {
                height: '44px',
              },
              containedSecondary: {
                color: '#fff',
                backgroundColor: Colors.ACCENT,
                '&:hover': {
                  backgroundColor: Colors.ACCENT,
                },
              },
              text: {
                backgroundColor: '#F4F5F7',
              },
            },
          },
          MuiAutocomplete: {
            defaultProps: {
              // popupIcon: <ChevronDown />,
              getOptionLabel: (option) => {
                if (typeof option === 'object' && 'name' in option) {
                  return option.name;
                }
                if (typeof option === 'object' && 'label' in option) {
                  return option.label;
                }

                if (typeof option === 'string') {
                  return option;
                }
                return '';
              },
            },
            styleOverrides: {
              inputRoot: {
                paddingTop: '2.5px',
                paddingBottom: '2.5px',
              },
              popupIndicator: {
                height: '24px',
                width: '24px',
              },
              endAdornment: {
                top: '50%',
                transform: 'translateY(-50%)',
              },
            },
          },
          MuiTooltip: {
            defaultProps: {
              placement: 'top',
              disableInteractive: true,
              arrow: true,
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: '8px',
              },
            },
          },
          MuiRadio: {
            defaultProps: {
              // icon: <RadionUnCheckedIcon />,
              // checkedIcon: <RadionCheckedIcon />,
            },
            styleOverrides: {
              colorPrimary: {
                '&.Mui-checked': {
                  color: Colors.ACCENT,
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                fontWeight: 600,
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                fontWeight: 500,
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                '&.Mui-focused': {
                  color: Colors.ACCENT,
                },
              },
              asterisk: {
                color: Colors.ERROR,
                marginRight: '2px',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                fontWeight: 600,
                textTransform: 'none',
                padding: '15px 16px',
                '&.Mui-selected': {
                  color: Colors.ACCENT,
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              indicator: {
                backgroundColor: Colors.ACCENT,
                height: '3px',
              },
            },
          },
          MuiFormControl: {
            defaultProps: {
              fullWidth: true,
            },
          },
          MuiPaginationItem: {
            styleOverrides: {
              text: {
                backgroundColor: Colors.LGRAY,
                fontSize: '1.6rem',
                borderRadius: '4px',
                '&.Mui-selected': {
                  backgroundColor: Colors.ACCENT,
                  color: Colors.WHITE_TEXT,
                },
              },
              previousNext: {
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0,0,0,.2)',
                },
              },
            },
          },
          MuiStepper: {
            styleOverrides: {
              root: {
                backgroundColor: Colors.LGRAY,
                // padding: "0 2.4rem",
                borderRadius: '8px',
                overflow: 'hidden',
              },
            },
          },
          MuiStep: {
            styleOverrides: {
              root: {
                padding: isMobile ? '0.8rem 0' : '1.6rem 0',
                minHeight: '100%',
                alignSelf: 'stretch',
              },
            },
          },
          MuiAvatar: {
            styleOverrides: {
              root: {
                backgroundColor: Colors.BGRAY,
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                border: 'none',
                padding: '8px',
              },
            },
          },
        },
      }),
    [isMobile]
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
