export enum Colors {
  PRIMARY = "#FFEA00",
  SECONDARY = "#341F47",
  BGRAY = "#707D9F",
  LGRAY = "#F4F5F7",
  ACCENT = "#4D37B3",
  ERROR = "#FF5252",
  SUCCESS = "#4CAF50",
  INFO = "#2196F3",
  WARNING = "#FB8C00",
  WHITE_TEXT = "#FFFFFF",
  BORDER = "rgba(77, 55, 179, 0.4)",
  WHITE = "#ffffff",
}
