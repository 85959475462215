import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';
import { logout } from '@/entities/auth/model/slice';
import notify from '@/shared/toaster/lib/notify';

import i18n from '../i18n';
import {
  CustomError,
  DefaultResponse,
  DefaultResponseWithPagination,
  PaginationParams,
  blobToBase64,
} from '../helpers';
import Cookies from 'js-cookie';
import { WorkerStatus } from '../constants';
import {
  AddReport,
  BaseLocationData,
  CardDataResponse,
  CompanyBalance,
  EditEmployeeBody,
  EditYandexReport,
  Employee,
  ExcelFileHistoryResponse,
  GetYandexReportsData,
  GetDocumentReportStatuses,
  GetDocumentReportTypes,
  GetCompanyWorker,
  GetCompanyWorkersCounters,
  GetEmployeesFilterParams,
  GetInfoByTinOrPinflResponse,
  GetYandexReportResponseBody,
  GetReportsRequestBody,
  ImportReportRows,
  InvitationInfoResponse,
  InviteWorkerBody,
  MutualSettlements,
  MutualSettlementsTransaction,
  PaymentRegister,
  SendSmsVerificationCodeBody,
  SinglePaymentRegister,
  TaxiCompany,
  TaxiParkBotSettings,
  Transaction,
  YandexApiAccessBody,
  YandexDoc,
  YandexDriverUpload,
  GetInvoicesRequestBody,
  GetInvoicesResponseBody,
  GetClosingDocumentTypes,
  ClosingDocumentTypeId,
  GetDocumentStatuses,
  GetCreateStatus,
  GetUklonRerportsData,
  GetUklonReportResponseBody,
  EditUklonReport,
} from './model';

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = Cookies.get('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Accept-Language', i18n.language);
    return headers;
  },
  responseHandler: async (response) => {
    if (response.status === 401) {
      return Promise.reject();
    }
    const result = (await response.json()) as
      | {
          error: { code: number; msg: string };
          success: boolean;
        }
      | {
          data: unknown;
          success: boolean;
        };

    return result;
  },
});

//@ts-ignore
export const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, CustomError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error && 'status' in result.error && result.error.status === 401) {
    const t = i18n.t.bind(i18n);
    api.dispatch(logout());
    // window.open(import.meta.env.VITE_SESSION_REDIRECT_URL, "_self");
    notify(t('session_expired'), 'error');
    return result;
  }
  return result;
};

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: customFetchBase,
  tagTypes: ['Employees', 'TaxiCompanies', 'DriverTransaction', 'PaymentRegistry', 'YandexInvoice'],
  endpoints: (builder) => ({
    getCompanyWorkers: builder.query<
      DefaultResponseWithPagination<Employee[]> & {
        importWorkersFromYandexApiIsOk?: boolean;
        elements: number;
      },
      GetEmployeesFilterParams
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCompanyWorkers',
        body,
      }),
      providesTags: ['Employees'],
    }),
    inviteWorker: builder.mutation<DefaultResponse, InviteWorkerBody>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=inviteWorker',
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    sendConfirmationCode: builder.mutation<DefaultResponse, SendSmsVerificationCodeBody>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=sendConfirmCode',
        body,
      }),
    }),
    getInvitationInfo: builder.query<DefaultResponse<InvitationInfoResponse>, { p: string }>({
      query: (params) => ({
        method: 'POST',
        url: 'exec?action=getInvitationInfo',
        params,
      }),
    }),
    getCardInfo: builder.query<DefaultResponse<CardDataResponse>, { cardNumber: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCardInfo',
        body,
      }),
    }),
    getInfoByTinOrPinfl: builder.query<GetInfoByTinOrPinflResponse, string>({
      query: (inn) => ({
        method: 'GET',
        url: `${import.meta.env.VITE_DIDOX_GNK_BASE_URL}/utils/info/${inn}`,
        headers: {
          'user-key': Cookies.get('user-key'),
        },
      }),
    }),
    sendInviteManually: builder.mutation<DefaultResponse, { unique: number }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=sendInviteManually',
        body,
      }),
    }),
    checkConfirmCode: builder.mutation<
      DefaultResponse,
      { code: string; phone: string; pinfl: number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=checkConfirmCode',
        body,
      }),
    }),
    checkSoliq: builder.query<{ SoliqIsUp: boolean }, {}>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=checkSoliq',
        body,
      }),
    }),
    getCompanyWorker: builder.query<GetCompanyWorker, number>({
      query: (unique) => ({
        method: 'POST',
        url: 'exec?action=getCompanyWorker',
        body: { unique },
      }),
    }),
    editCompanyWorker: builder.mutation<DefaultResponse, EditEmployeeBody>({
      query: (body) => ({
        url: '/exec?action=editCompanyWorker',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    checkBalance: builder.query<CompanyBalance, unknown>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=checkBalance',
      }),
    }),
    getTransactions: builder.query<DefaultResponseWithPagination<Transaction[]>, PaginationParams>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getTransactions',
        body,
      }),
    }),
    checkSubscription: builder.query<
      DefaultResponse<{
        tariffName: string;
        activatedUsersLimit: number;
        activatedUsers: number;
        daysLeft: number;
        lastDay: 'string';
      }>,
      {}
    >({
      query: () => ({
        method: 'POST',
        url: 'exec?action=checkSubscription',
      }),
    }),
    getCommissioners: builder.query<
      DefaultResponse<{ name: string; tin: string; default?: boolean }[]>,
      {}
    >({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getCommissioners',
      }),
    }),
    importWorkersFromExcelWithQueue: builder.mutation<
      DefaultResponse,
      { name: string; autoSendInvitations: true | null; base64: string }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=importWorkersFromExcelWithQueueBase64',
        body,
      }),
    }),
    getCompanyWorkerStatuses: builder.query<
      DefaultResponse<{ id: WorkerStatus; name: string }[]>,
      {}
    >({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getCompanyWorkerStatuses',
      }),
    }),
    multipleSendingInviteManually: builder.mutation<DefaultResponse, { unique: number[] }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=multipleSendingInviteManually',
        body,
      }),
    }),
    exportCompanyWorkersExcel: builder.mutation<string, Record<string, any>>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=exportCompanyWorkersExcel',
        body,
        responseHandler: async (response) => {
          const blob = await response.blob();
          const res = await blobToBase64(blob);
          return res;
        },
      }),
    }),
    exportCalculationsTransactions: builder.mutation<string, Record<string, any>>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=exportCalculationsTransactions',
        body,
        responseHandler: async (response) => {
          const blob = await response.blob();
          const res = await blobToBase64(blob);
          return res;
        },
      }),
    }),
    checkCompanyWorkerExists: builder.query<
      { exists: boolean; message?: string },
      { pinfl: string; taxiCompanyId: number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=checkCompanyWorkerExists',
        body,
      }),
    }),
    getTaxiCompanies: builder.query<DefaultResponse<TaxiCompany[]>, {}>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getTaxiCompanies',
      }),
      providesTags: ['TaxiCompanies'],
    }),
    switchTaxiCompany: builder.mutation<DefaultResponse, { unique: number }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=switchTaxiCompany',
        body,
      }),
      invalidatesTags: ['TaxiCompanies'],
    }),
    deleteWorkers: builder.mutation<DefaultResponse, { workers: number[] }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=deleteWorkers',
        body,
      }),
      invalidatesTags: ['Employees'],
    }),
    getImportWorkersFromExcelHistory: builder.query<ExcelFileHistoryResponse, { page: number }>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getImportWorkersFromExcelHistory',
      }),
    }),
    getYandexApiAccess: builder.query<Omit<YandexApiAccessBody, 'unique'>, { p: number | string }>({
      query: (params) => ({
        method: 'POST',
        url: 'exec?action=getYandexApiAccess',
        params,
      }),
    }),
    editYandexApiAccess: builder.mutation<DefaultResponse, YandexApiAccessBody>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=editYandexApiAccess',
        body,
      }),
    }),
    getTelegramBotSettings: builder.query<TaxiParkBotSettings, { p: string | number }>({
      query: (params) => ({
        method: 'POST',
        url: 'exec?action=getTelegramBotSettings',
        params,
      }),
    }),
    editTelegramBotSettings: builder.mutation<
      DefaultResponse,
      TaxiParkBotSettings & { unique: string | number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=editTelegramBotSettings',
        body,
      }),
    }),
    getDriversList: builder.query<
      DefaultResponseWithPagination<MutualSettlements[]> & {
        importWorkersFromYandexApiIsOk?: boolean;
      },
      PaginationParams<{ search: string }>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCalculationsDriversList',
        body,
      }),
    }),
    getCalculationsTransactions: builder.query<
      DefaultResponseWithPagination<MutualSettlementsTransaction[]> & { amountTotal: number },
      PaginationParams<{ search: string; dateTo: string; dateFrom: string; statusFilter: string }>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCalculationsTransactions',
        body,
      }),
      providesTags: ['DriverTransaction'],
    }),
    getCalculationsTransactionsStatuses: builder.query<
      DefaultResponse<{ id: string; name: string }[]>,
      {}
    >({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getCalculationsTransactionsStatuses',
      }),
    }),
    transferMoneyToDriver: builder.mutation<
      DefaultResponse,
      { unique: number; amount: number; cardNumber: string }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=transferMoneyToDriver',
        body,
      }),
      invalidatesTags: ['DriverTransaction'],
    }),
    getDriverBalance: builder.query<{ balance: number }, { p: string }>({
      query: (params) => ({
        method: 'POST',
        url: 'exec?action=EmployerAPI.getDriverBalance',
        params,
      }),
    }),
    addPaymentRegisterFromExcel: builder.mutation<
      DefaultResponse,
      { base64: string; date: string; id: number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=addPaymentRegisterFromExcel',
        body,
      }),
      invalidatesTags: ['PaymentRegistry'],
    }),
    getPaymentRegisters: builder.query<
      DefaultResponseWithPagination<PaymentRegister[]>,
      PaginationParams
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getPaymentRegisters',
        body,
      }),
      providesTags: ['PaymentRegistry'],
    }),
    getPaymentRegister: builder.query<
      DefaultResponseWithPagination<SinglePaymentRegister[]> & { name: string },
      PaginationParams<{ unique: number }>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getPaymentRegister',
        body,
      }),
    }),
    getImportWorkersFromYandexActiveDriversCount: builder.query<{ total: number }, {}>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=getImportWorkersFromYandexActiveDriversCount',
      }),
    }),
    importWorkersFromYandex: builder.mutation<DefaultResponse, {}>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=importWorkersFromYandex',
      }),
    }),
    importBotDriversFromYandex: builder.mutation<DefaultResponse, {}>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=importBotDriversFromYandex',
      }),
    }),
    getImportWorkersFromYandexHistory: builder.query<
      DefaultResponseWithPagination<YandexDriverUpload[]>,
      PaginationParams
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getImportWorkersFromYandexHistory',
        body,
      }),
    }),
    getInvoices: builder.query<
      DefaultResponseWithPagination<YandexDoc[]>,
      PaginationParams & { status: string }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getInvoices',
        body,
      }),
      providesTags: ['YandexInvoice'],
    }),
    addInvoiceFromExcel: builder.mutation<DefaultResponse, { base64: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=addInvoiceFromExcel',
        body,
      }),
      invalidatesTags: ['YandexInvoice'],
    }),
    // Yandex Docs API
    getDocumentReportsTypes: builder.query<DefaultResponse<GetDocumentReportTypes[]>, {}>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=getDocumentReportType',
      }),
    }),
    getClosingDocumentTypes: builder.query<DefaultResponse<GetClosingDocumentTypes[]>, {}>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=getDocumentReportClosingDocuments',
      }),
    }),
    getDocumentReportsStatuses: builder.query<DefaultResponse<GetDocumentReportStatuses[]>, {}>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=getDocumentReportStatus',
      }),
    }),
    getDocumentStatuses: builder.query<DefaultResponse<GetDocumentStatuses[]>, {}>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=getDocumentStatus',
      }),
    }),
    EditYandexReport: builder.mutation<DefaultResponse, EditYandexReport>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=editReport',
        body,
      }),
    }),
    addReport: builder.mutation<{ success: boolean; unique: number }, AddReport>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=addReport',
        body,
      }),
    }),
    getReports: builder.query<
      DefaultResponseWithPagination<GetYandexReportsData[]> & { nextPage: number },
      PaginationParams<GetReportsRequestBody>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getReports',
        body,
      }),
    }),
    getReport: builder.query<GetYandexReportResponseBody, PaginationParams<{ unique: number }>>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getReport',
        body,
      }),
    }),
    postYandexReport: builder.mutation<
      DefaultResponse,
      { unique: number; posted: boolean; check: boolean }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=postReport',
        body,
      }),
    }),
    importReportRows: builder.mutation<DefaultResponse<ImportReportRows[]>, { base64: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=importReportRows',
        body,
      }),
    }),
    importReportRowsFromAggregator: builder.mutation<
      DefaultResponse<ImportReportRows[]>,
      { unique: number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=importRowsFromAggregator',
        body,
      }),
    }),
    newGetInvoices: builder.query<
      DefaultResponseWithPagination<GetInvoicesResponseBody[]> & {
        nextPage: number;
      },
      PaginationParams<GetInvoicesRequestBody>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getInvoices2',
        body,
      }),
    }),
    createClosingDocument: builder.mutation<
      DefaultResponse,
      { unique: number; type: ClosingDocumentTypeId }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=createClosingDocument',
        body,
      }),
    }),
    // getInvoiceCreateStatus: builder.query<GetInvoiceCreateStatus, { unique: number }>({
    //   query: (body) => ({
    //     method: 'POST',
    //     url: 'exec?action=getInvoiceCreateStatus',
    //     body,
    //   }),
    // }),
    getCreateStatus: builder.query<GetCreateStatus, { unique: number }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCreateStatus',
        body,
      }),
    }),
    addReportUklon: builder.mutation<{ success: boolean; unique: number }, { base64: string }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=addReportUklon',
        body,
      }),
    }),
    getReportsUklon: builder.query<
      DefaultResponseWithPagination<GetUklonRerportsData[]> & { nextPage: number },
      PaginationParams<GetReportsRequestBody>
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getReportsUklon',
        body,
      }),
    }),
    getReportUklon: builder.query<GetUklonReportResponseBody, PaginationParams<{ unique: number }>>(
      {
        query: (body) => ({
          method: 'POST',
          url: 'exec?action=getReportUklon',
          body,
        }),
      }
    ),
    editReportUklon: builder.mutation<DefaultResponse, EditUklonReport>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=editReportUklon',
        body,
      }),
    }),
    postReportUklon: builder.mutation<
      DefaultResponse,
      { unique: number; posted: boolean; check: boolean }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=postReportUklon',
        body,
      }),
    }),
    getPdfUklon: builder.query<{ success: boolean; base64: string }, { unique: number }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getPdfUklon',
        body,
      }),
    }),
    getRegions: builder.query<DefaultResponse<BaseLocationData[]>, {}>({
      query: () => ({
        method: 'GET',
        url: 'exec?action=getRegions',
      }),
    }),
    getDistricts: builder.query<DefaultResponse<BaseLocationData[]>, { region: number }>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getDistricts',
        body,
      }),
    }),
    getVillages: builder.query<
      DefaultResponse<Omit<BaseLocationData, 'nameRu'>[]>,
      { district: number }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getVillages',
        body,
      }),
    }),
    getCompanyWorkersCounters: builder.query<GetCompanyWorkersCounters, GetEmployeesFilterParams>({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=getCompanyWorkersCounters',
        body,
      }),
    }),
    getImportWorkersFromYandexHistoryNotDownloadedFile: builder.mutation<Blob, { unique: number }>({
      query: (body) => ({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'exec?action=getImportWorkersFromYandexHistoryNotDownloadedFile',
        body,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const tagA = document.createElement('a');
            tagA.download = 'not_downloaded.xlsx';
            tagA.href = url;
            tagA.click();
            tagA.remove();
            URL.revokeObjectURL(url);
          } else throw new Error(i18n.t('failed_to_download_file'));
        },
      }),
    }),
    changeAccessToWithdrawal: builder.mutation<
      DefaultResponse,
      { unique: number; accessToWithdrawal: boolean }
    >({
      query: (body) => ({
        method: 'POST',
        url: 'exec?action=changeAccessToWithdrawal',
        body,
      }),
    }),
    checkActiveBalanceNotificationsBotBinding: builder.mutation<
      { hasActiveBinding: boolean; bindingDate: string; success: boolean },
      unknown
    >({
      query: () => ({
        method: 'GET',
        url: 'exec?action=checkActiveBalanceNotificationsBotBinding',
      }),
    }),
    createBalanceNotificationsBotBinding: builder.mutation<
      { link: string; success: boolean },
      unknown
    >({
      query: () => ({
        method: 'POST',
        url: 'exec?action=createBalanceNotificationsBotBinding',
      }),
    }),
    deleteBalanceNotificationsBotBinding: builder.mutation<DefaultResponse, unknown>({
      query: () => ({
        method: 'POST',
        url: 'exec?action=deleteBalanceNotificationsBotBinding',
      }),
    }),
  }),
});

export const {
  useGetCompanyWorkersQuery,
  useLazyGetCompanyWorkersQuery,
  useInviteWorkerMutation,
  useSendConfirmationCodeMutation,
  useGetInvitationInfoQuery,
  useLazyGetInvitationInfoQuery,
  useGetCardInfoQuery,
  useLazyGetCardInfoQuery,
  useLazyGetInfoByTinOrPinflQuery,
  useSendInviteManuallyMutation,
  useCheckConfirmCodeMutation,
  useCheckSoliqQuery,
  useGetCompanyWorkerQuery,
  useLazyGetCompanyWorkerQuery,
  useEditCompanyWorkerMutation,
  useCheckBalanceQuery,
  useGetTransactionsQuery,
  useCheckSubscriptionQuery,
  useGetCommissionersQuery,
  useLazyGetCommissionersQuery,
  useImportWorkersFromExcelWithQueueMutation,
  useGetCompanyWorkerStatusesQuery,
  useMultipleSendingInviteManuallyMutation,
  useExportCompanyWorkersExcelMutation,
  useExportCalculationsTransactionsMutation,
  useCheckCompanyWorkerExistsQuery,
  useLazyCheckCompanyWorkerExistsQuery,
  useGetTaxiCompaniesQuery,
  useLazyGetTaxiCompaniesQuery,
  useSwitchTaxiCompanyMutation,
  useDeleteWorkersMutation,
  useGetImportWorkersFromExcelHistoryQuery,
  useGetYandexApiAccessQuery,
  useLazyGetYandexApiAccessQuery,
  useEditYandexApiAccessMutation,
  useLazyGetTelegramBotSettingsQuery,
  useEditTelegramBotSettingsMutation,
  useGetTelegramBotSettingsQuery,
  useGetDriversListQuery,
  useGetCalculationsTransactionsQuery,
  useGetCalculationsTransactionsStatusesQuery,
  useTransferMoneyToDriverMutation,
  useLazyGetDriverBalanceQuery,
  useGetDriverBalanceQuery,
  useAddPaymentRegisterFromExcelMutation,
  useGetInfoByTinOrPinflQuery,
  useGetPaymentRegistersQuery,
  useLazyCheckBalanceQuery,
  useLazyCheckSoliqQuery,
  useLazyGetPaymentRegistersQuery,
  useGetPaymentRegisterQuery,
  useLazyGetPaymentRegisterQuery,
  useGetImportWorkersFromYandexActiveDriversCountQuery,
  useLazyGetImportWorkersFromYandexActiveDriversCountQuery,
  useImportWorkersFromYandexMutation,
  useGetImportWorkersFromYandexHistoryQuery,
  useGetInvoicesQuery,
  useAddInvoiceFromExcelMutation,
  useGetReportsQuery,
  useLazyGetDocumentReportsTypesQuery,
  useGetDocumentReportsTypesQuery,
  useAddReportMutation,
  useLazyGetDocumentReportsStatusesQuery,
  useGetDocumentReportsStatusesQuery,
  useGetReportQuery,
  useLazyGetReportQuery,
  useImportReportRowsMutation,
  usePostYandexReportMutation,
  useEditYandexReportMutation,
  useNewGetInvoicesQuery,
  useLazyNewGetInvoicesQuery,
  useGetClosingDocumentTypesQuery,
  useLazyGetClosingDocumentTypesQuery,
  useCreateClosingDocumentMutation,
  useGetDocumentStatusesQuery,
  useImportReportRowsFromAggregatorMutation,
  useGetCreateStatusQuery,
  useLazyGetCreateStatusQuery,
  useAddReportUklonMutation,
  useEditReportUklonMutation,
  useGetReportsUklonQuery,
  useLazyGetReportsUklonQuery,
  useGetReportUklonQuery,
  useLazyGetReportUklonQuery,
  usePostReportUklonMutation,
  useGetPdfUklonQuery,
  useLazyGetPdfUklonQuery,
  useGetRegionsQuery,
  useLazyGetDistrictsQuery,
  useLazyGetVillagesQuery,
  useLazyGetRegionsQuery,
  useGetCompanyWorkersCountersQuery,
  useGetImportWorkersFromYandexHistoryNotDownloadedFileMutation,
  useImportBotDriversFromYandexMutation,
  useChangeAccessToWithdrawalMutation,
  useCheckActiveBalanceNotificationsBotBindingMutation,
  useCreateBalanceNotificationsBotBindingMutation,
  useDeleteBalanceNotificationsBotBindingMutation,
} = mainApi;
