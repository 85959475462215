import React, { useEffect, useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { UITabPanel } from './ui/ui-tab-panel';

import { UITabsProps } from './types';

const UITabs: React.FC<UITabsProps> = ({
  tabs,
  defaultValue = 0,
  changeActiveTabHandle,
  children,
  endAdornment,
  sx,
  value = defaultValue,
  onChange,
  TabPanelSx,
  endAdornmentFlex,
  upContent,
  TabProps,
  TabsProps,
}) => {
  const [myValue, setMyValue] = useState<number>(value || 0);

  useEffect(() => {
    setMyValue(value);
  }, [value]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setMyValue(newValue);
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Tabs
          aria-label='ui-tabs'
          textColor='secondary'
          {...TabsProps}
          onChange={onChange || handleChange}
          value={myValue}
        >
          {tabs.map((tab, idx) => (
            <Tab
              disableRipple
              {...TabProps}
              key={idx}
              onClick={() => changeActiveTabHandle?.(idx)}
              label={tab.label}
            />
          ))}
        </Tabs>

        {!!endAdornment && (
          <Box flex={endAdornmentFlex} ml={3}>
            {endAdornment}
          </Box>
        )}
        {children}
      </Stack>
      {upContent && <Box my={1.5}>{upContent}</Box>}
      {tabs.map((tab, idx) => (
        <UITabPanel value={myValue} index={idx} key={idx} sx={TabPanelSx}>
          {tab.component}
        </UITabPanel>
      ))}
    </Box>
  );
};

export default UITabs;
