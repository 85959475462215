import { useMemo, useState } from 'react';
import { AddExcelDocRegistry } from '@/features/add-excel-doc-registry';
import { SignDocsModal } from '@/features/sign-docs-modal';
import FormSelectInput from '@/shared/inputs/form-autocomplete-input';
import { Layout } from '@/shared/layout';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import { UIButton } from '@/shared/ui/ui-button';
import { UIChip } from '@/shared/ui/ui-chip';
import { UISection } from '@/shared/ui/ui-section';
import { UITable } from '@/shared/ui/ui-table';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { useGetInvoicesQuery } from '@/app/api';
import { useAppSelector, useFilters } from '@/app/hooks';
import { UIAlert } from '@/shared/ui/ui-alert';
import { numericFormatter } from 'react-number-format';
import { didoxDocsStatuses } from './lib';
import { checkIfHasPermission } from '../../app/helpers';

import { PlusIcon } from '@/shared/icons';

import { useTranslation } from 'react-i18next';
import { YandexDocStatus } from '@/app/api/model';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { SignDocsModalRef } from '@/features/sign-docs-modal/types';
import { UIChipColor, UIChipProps } from '@/shared/ui/ui-chip/types';

export const YandexDocumentsPage = () => {
  const { t } = useTranslation();
  const { control } = useForm();
  const { roles } = useAppSelector((state) => state.auth);
  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
      pageSize: 10,
      status: '',
    },
  });
  const [selected, setSelected] = useState<number[]>([]);

  const { data, isFetching } = useGetInvoicesQuery(filterParams);

  const selectedDocs = selected.map((idx) => (data?.data ?? [])[idx]);

  const addExcelDocModalRef = useModalRef();
  const signDocsModalRef = useModalRef<SignDocsModalRef>();

  const getStatusChipProps = (status: YandexDocStatus): UIChipProps => {
    const label = t(`documents_statuses.${status}`) || status;
    let color: UIChipColor = 'info';

    switch (status) {
      case 'canceled':
      case 'rejected':
        color = 'error';
        break;
      case 'signed':
        color = 'success';
        break;
      case 'waitContragentSign':
        color = 'warning';
        break;
      default:
        break;
    }

    return {
      color,
      label,
    };
  };

  const onChangeStatus = (status: string) => {
    onChangeFilterParams({ status: status });
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18742']), [roles]);

  return (
    <Layout>
      <UIAlert
        text={t('documents_page_hint')}
        type='info'
        closable
        sx={{
          mb: 3,
          minWidth: '100%',
        }}
      />
      <UISection>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row'>
            {/* <FormTextInput control={control} name='' label={t('search')} />
            <UIButton sx={{ ml: 1 }} type='submit'>
              {t('to_find')}
            </UIButton> */}

            {/* <UIButton variant='outlined' color='secondary' sx={{ ml: 2 }}>
              {t('filter')}
            </UIButton> */}
          </Stack>

          <Stack direction='row' spacing={2}>
            {/* <UIButton variant='outlined' color='secondary'>
              {t('excel_download')}
            </UIButton> */}
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                onClick: () => addExcelDocModalRef.current?.open(),
                endIcon: <PlusIcon />,
                disabled: !hasPermission,
                children: t('upload_registry'),
              }}
            />
          </Stack>
        </Stack>

        <Stack direction='row' mt={3} justifyContent='space-between'>
          <Stack direction='row' spacing={1}>
            <PermissionCheckHOC
              WrappedComponent={UIButton}
              props={{
                onClick: () => {
                  const docs = selected.map((idx) => (data?.data ?? [])[idx]);
                  signDocsModalRef.current?.init(docs ?? [], 'sign');
                },
                disabled:
                  selectedDocs.every((el) => el.status === 'waitContragentSign') || !hasPermission,
                children: t('to_sign'),
              }}
            />
          </Stack>
          <Stack direction='row' spacing={1}>
            <FormSelectInput
              control={control}
              name='status'
              options={didoxDocsStatuses}
              getOptionLabel={(o) => o.label}
              onChange={(_e, o) => onChangeStatus(o?.value || '')}
              TextFieldProps={{
                label: t('status'),
                sx: {
                  minWidth: '212px',
                },
              }}
            />
          </Stack>
        </Stack>

        <UITable
          data={data?.data ?? []}
          isLoading={isFetching}
          columns={[
            { label: '№', key: 'index', nowrap: true },
            {
              label: t('status'),
              render: ({ status }) => <UIChip {...getStatusChipProps(status)} />,
            },
            {
              label: t('type_of_document'),
              render: () => <UIChip label={t('invoice')} color='gray' />,
            },
            { label: t('document_number'), key: 'name' },
            { label: t('date'), key: 'date' },
            {
              label: t('sum') + ', ' + t('currency_uzs'),
              render: ({ amount }) => (
                <Typography variant='h6'>
                  {amount
                    ? numericFormatter(amount?.toFixed(2), { thousandSeparator: ' ' })
                    : '0.00'}
                </Typography>
              ),
            },
            {
              label: t('actions'),
              render: ({ didoxId }) => (
                <Stack direction='row' spacing={1}>
                  {didoxId && (
                    <Link to={`/customer/documents/${didoxId}/docs`}>
                      <UIActionButton icon='eye' />
                    </Link>
                  )}
                </Stack>
              ),
              nowrap: true,
            },
          ]}
          checkbox={{
            selected,
            setSelected,
          }}
          PaginationProps={{
            count: data?.totalPages ?? 0,
            page: filterParams.page,
            onChange: (_event, page) => onChangeFilterParams({ page }),
          }}
        />
      </UISection>

      <AddExcelDocRegistry ref={addExcelDocModalRef} />
      <SignDocsModal ref={signDocsModalRef} />
    </Layout>
  );
};
