import { forwardRef } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { UILoader } from '../ui-loader';

import { TrashIcon } from '@/shared/icons';
import PrintIcon from '@/app/assets/icons/printer.svg?react';
import RefreshIcon from '@/app/assets/icons/refresh-dark.svg?react';
import CopyIcon from '@/app/assets/icons/copy.svg?react';
import EditIcon from '@/app/assets/icons/edit.svg?react';
import CardSendIcon from '@/app/assets/icons/card-send.svg?react';
import EyeIcon from '@/app/assets/icons/eye.svg?react';
import PlusIcon from '@/app/assets/icons/add.svg?react';

import { UIActionButtonProps } from './types';

export const UIActionButton = forwardRef<HTMLButtonElement, UIActionButtonProps>(
  ({ icon, loading, ...rest }, ref) => {
    const getIcon = () => {
      switch (icon) {
        case 'refresh':
          return RefreshIcon;
        case 'print':
          return PrintIcon;
        case 'copy':
          return CopyIcon;
        case 'edit':
          return EditIcon;
        case 'card-send':
          return CardSendIcon;
        case 'eye':
          return EyeIcon;
        case 'plus':
          return PlusIcon;
        case 'delete':
        default:
          return TrashIcon;
      }
    };

    const Icon = getIcon();

    return (
      <ButtonBase
        {...rest}
        ref={ref}
        disabled={rest.disabled || loading}
        sx={{
          p: 1.25,
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
          ...rest.sx,
        }}
      >
        {loading ? (
          <UILoader size={20} />
        ) : (
          <Box
            component={Icon}
            sx={{
              width: '2rem',
              height: '2rem',
            }}
          />
        )}
      </ButtonBase>
    );
  }
);
