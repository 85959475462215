import { useModalRef } from "@/app/hooks/use-modal-ref";
import { TasksFilter } from "@/features/tasks-filter";
import { EmptyPlaceholder } from "@/shared/empty-placeholder";
import { Layout } from "@/shared/layout";
import { UISection } from "@/shared/ui/ui-section";

// interface FormValues {
//   search: string;
// }

export const TasksPage = () => {
  // const { t } = useTranslation();
  // const { watch } = useForm<FormValues>();

  const filterRef = useModalRef();

  return (
    <Layout>
      <UISection sx={{ height: "100%" }}>
        <EmptyPlaceholder />
        {/* <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              component="form"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <FormTextInput
                control={control}
                name="search"
                placeholder={t("tasks_search_placeholder")}
                sx={{
                  width: "300px",
                }}
              />
              <UIButton type="submit" disabled={!searchValue}>
                {t("to_find")}
              </UIButton>
            </Stack>
            <UIButton variant="outlined" color="secondary">
              {t("tasks_filter")}
            </UIButton>
          </Stack>
          <UIButton endIcon={<PlusIcon />}>{t("upload_tasks")}</UIButton>
        </Stack>

        <Stack direction="row" spacing={2} mt={3}>
          <Box
            alignItems="center"
            sx={{
              minHeight: "100%",
              display: "flex",
              alignItems: "center",
              backgroundColor: Colors.LGRAY,
              px: 2,
              py: 1,
              borderRadius: "4px",
            }}
          >
            <Typography variant="subtitle2">{t("chosen")}: 123</Typography>
          </Box>
          <UIButton>{t("to_sign")}</UIButton>
          <UIButton>{t("cancel_tasks")}</UIButton>
        </Stack> */}
      </UISection>
      <TasksFilter ref={filterRef} />
    </Layout>
  );
};
