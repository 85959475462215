import { useRef, useState } from 'react';
import { Layout } from '@/shared/layout';
import { FormControlLabel, Stack } from '@mui/material';
import { UISection } from '@/shared/ui/ui-section';
import { BalanceWidgets } from '@/widgets/balance-widgets';
import UITabs from '@/shared/ui/ui-tabs';
import { UISwitch } from '@/shared/ui/ui-switch';
import { BalanceInfoTable } from '@/features/balance-info-table';
import { UIButton } from '../../shared/ui/ui-button';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { useAppSelector } from '../../app/hooks';
import {
  useCheckActiveBalanceNotificationsBotBindingMutation,
  useCreateBalanceNotificationsBotBindingMutation,
  useDeleteBalanceNotificationsBotBindingMutation,
} from '../../app/api';
import ConfirmModal from '../../shared/confirm-modal';

import { useTranslation } from 'react-i18next';
import { ConfirmModalRef } from '../../shared/confirm-modal/types';
import notify from '../../shared/toaster/lib/notify';

export const BalancaPage = () => {
  const { t } = useTranslation();
  const { roles, company } = useAppSelector((state) => state.auth);
  const [isTiins, setIsTiins] = useState(false);

  const [checkWhetherBinded, { isLoading: isCheckWhetherBindedLoading }] =
    useCheckActiveBalanceNotificationsBotBindingMutation();
  const [createBinding, { isLoading: isCreateBindingLoading }] =
    useCreateBalanceNotificationsBotBindingMutation();
  const [deleteBinding, { isLoading: isDeleteBindingLoading }] =
    useDeleteBalanceNotificationsBotBindingMutation();

  const confirmModalRef = useRef<ConfirmModalRef>(null);

  const checkWhetherBindedHandler = async () => {
    const { bindingDate, hasActiveBinding } = await checkWhetherBinded({}).unwrap();
    if (hasActiveBinding) {
      try {
        const answer = await confirmModalRef.current?.init({
          title: t('get_telegram_notifications'),
          text: t('notifications_are_already_setup', {
            date: bindingDate,
          }),
          btnText: t('bind_another_account'),
          cancelBtnText: t('cancel_notification_binding'),
        });
        if (answer) {
          const { link } = await createBinding({}).unwrap();
          handleLinkClick(link);
        }
      } catch (error) {
        if (!error) {
          await deleteBinding({}).unwrap();
          notify(t('notifications_are_cancelled'), 'success');
        }
      }
    } else {
      const { link } = await createBinding({}).unwrap();
      handleLinkClick(link);
    }
  };

  const handleLinkClick = (link: string) => {
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    notify(t('notifications_are_setup'), 'success');
  };

  return (
    <Layout>
      <Stack
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <BalanceWidgets />
        <UISection
          sx={{
            height: '100%',
            pt: 0,
          }}
        >
          <UITabs
            tabs={[
              {
                label: t('all'),
                component: <BalanceInfoTable isTiins={isTiins} />,
              },
              // {
              //   label: t("income"),
              //   component: <></>,
              // },
              // {
              //   label: t("consumption"),
              //   component: <></>,
              // },
            ]}
            endAdornment={
              <Stack direction='row' spacing={1.5}>
                {['302936161', '306986478'].includes(company?.tin ?? '') && (
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      children: t('get_telegram_notifications'),
                      color: 'primary',
                      size: 'small',
                      disabled: !!roles.length,
                      loading:
                        isCheckWhetherBindedLoading ||
                        isCreateBindingLoading ||
                        isDeleteBindingLoading,
                      onClick: checkWhetherBindedHandler,
                    }}
                  />
                )}
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      ml: 1,
                      fontSize: '1.6rem',
                      fontWeight: 600,
                    },
                  }}
                  control={<UISwitch onChange={(_, checked) => setIsTiins(checked)} />}
                  label={t('sum_in_tiins')}
                />
              </Stack>
            }
          />
        </UISection>
      </Stack>
      <ConfirmModal ref={confirmModalRef} />
    </Layout>
  );
};
