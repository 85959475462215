import { ToastContainer, TypeOptions } from "react-toastify";

export const Toaster = () => {
  // const getToastIcon = (type: TypeOptions) => {
  //   switch (type) {
  //     case 'error':
  //       return <TostErrorIcon />;
  //     case 'warning':
  //       return <TostWarningIcon />;
  //     case 'success':
  //     default:
  //       return <TostSuccessIcon />;
  //   }
  // };

  return (
    <ToastContainer
      autoClose={3000}
      position="top-right"
      // icon={({ type }) => getToastIcon(type)}
      closeButton={false}
    />
  );
};
