import { Colors } from '@/app/constants/colors';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { logout, setSelectedTaxiCompany } from '@/entities/auth/model/slice';
import {
  Box,
  ButtonBaseProps,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from '@/app/assets/icons/arrow-down.svg?react';
import { useGetTaxiCompaniesQuery, useSwitchTaxiCompanyMutation } from '@/app/api';
import TickCircleIcon from '@/app/assets/icons/tick-circle-rounded.svg?react';
import CircleIcon from '@/app/assets/icons/circle-white.svg?react';
import LogoutIcon from '@/app/assets/icons/logout_2.svg?react';
import { handleError } from '@/app/helpers';
import { UILoader } from '@/shared/ui/ui-loader';
import ProfileIcon from '@/app/assets/icons/profile-circle.svg?react';
import { TariffHeaderMenu } from '@/features/tariff-header-menu';
import { UIActionButton } from '@/shared/ui/ui-action-button';
import { TaxiParkSettingsModal } from '@/features/taxi-park-settings-modal';
import { TaxiParkSettingsModalRef } from '@/features/taxi-park-settings-modal/types';
import { UIChip } from '@/shared/ui/ui-chip';

interface Props extends ButtonBaseProps {
  Container: React.FC<ButtonBaseProps>;
}

export const ProfileMenu: React.FC<Props> = ({ Container, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { breakpoints } = useTheme();
  const isLessThanLg = useMediaQuery(breakpoints.down('lg'));
  const isMobile = useMediaQuery('(max-width: 715px)');
  const { cabinetType } = useAppSelector((state) => state.app);
  const { company, userData } = useAppSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const taxiParkModalRef = useRef<TaxiParkSettingsModalRef>(null);
  const isYandex = company?.invitationFlow === 'yandex';

  const { data: taxiCompanies } = useGetTaxiCompaniesQuery(
    {},
    { skip: cabinetType !== 'customer' || !isYandex }
  );
  const [switchTaxiCompany, { isLoading: isSwitchLoading }] = useSwitchTaxiCompanyMutation();

  const selectedTaxiCompany =
    taxiCompanies?.data.find((el) => el.selected) ?? taxiCompanies?.data.find((el) => el.isDefault);

  useEffect(() => {
    if (selectedTaxiCompany) {
      dispatch(setSelectedTaxiCompany(selectedTaxiCompany));
    }
  }, [selectedTaxiCompany]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (cabinetType === 'employee') return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const onSwitchTaxiCompany = async (unique: number) => {
    try {
      await switchTaxiCompany({ unique }).unwrap();
      const selectedTaxiCompany =
        taxiCompanies?.data.find((el) => el.unique === unique) ??
        taxiCompanies?.data.find((el) => el.isDefault);
      if (selectedTaxiCompany) {
        setSelectedTaxiCompany(selectedTaxiCompany);
      }
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Tooltip title={cabinetType === 'customer' ? company?.name : userData?.name} placement='left'>
        <Box>
          <Container
            {...rest}
            id='profile-btn'
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            {isMobile ? (
              <Box component={ProfileIcon} />
            ) : (
              <Stack
                direction='row'
                spacing={2}
                alignItems='center'
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography
                    color={Colors.WHITE_TEXT}
                    sx={{
                      fontWeight: 600,
                      maxWidth: '100%',
                      fontSize: '1.6rem',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {cabinetType === 'customer' ? company?.companyName : userData?.name}
                  </Typography>

                  {cabinetType === 'customer' && (
                    <Typography
                      variant='body2'
                      color={Colors.WHITE_TEXT}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '1.2rem',
                        textAlign: 'left',
                      }}
                    >
                      {selectedTaxiCompany?.name}
                    </Typography>
                  )}
                </Box>

                {cabinetType === 'customer' && <Box component={ArrowDownIcon} />}
              </Stack>
            )}
          </Container>

          <Menu
            id='profile-menu'
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            open={open}
            slotProps={{
              paper: {
                sx: {
                  p: 0,
                  borderRadius: '14px',
                  minWidth: anchorEl?.offsetWidth,
                },
              },
            }}
            MenuListProps={{
              'aria-labelledby': 'profile-btn',
              sx: {
                p: 0,
              },
            }}
          >
            {isYandex && cabinetType === 'customer' && (
              <Stack
                spacing={1}
                sx={{
                  backgroundColor: Colors.LGRAY,
                  p: 1.75,
                  position: 'relative',
                }}
              >
                {isSwitchLoading && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      backgroundColor: 'rgba(0, 0, 0, .1)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backdropFilter: 'blur(6px)',
                      WebkitBackdropFilter: 'blur(6px)',
                    }}
                  >
                    <UILoader size={40} />
                  </Box>
                )}

                {taxiCompanies?.data.map((tc, _index, array) => {
                  const isSelected =
                    tc.selected || (tc.isDefault && array.every((el) => !el.selected));

                  return (
                    <Stack
                      key={tc.unique}
                      direction='row'
                      alignItems={'center'}
                      component='div'
                      justifyContent='space-between'
                      spacing={2}
                      sx={{
                        backgroundColor: isSelected ? Colors.WHITE : 'inherit',
                        borderRadius: '12px',
                        ...(isSelected && {
                          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
                        }),
                        pr: 2,
                        transition: 'all .3s ease',
                        '&:hover': {
                          backgroundColor: isSelected ? Colors.WHITE : 'rgba(255, 255, 255, .5)',
                          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
                          transition: 'all .3s ease',
                        },
                      }}
                    >
                      <Stack
                        direction='row'
                        alignItems='center'
                        spacing={1.75}
                        onClick={() => onSwitchTaxiCompany(tc.unique)}
                        sx={{
                          p: 2,
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: isSelected ? Colors.ACCENT : Colors.BGRAY,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '3.6rem',
                            width: '3.6rem',
                            borderRadius: '50%',
                            flexShrink: 0,
                          }}
                        >
                          <Box
                            sx={{
                              width: '2rem',
                              height: '2rem',
                            }}
                            component={isSelected ? TickCircleIcon : CircleIcon}
                          />
                        </Box>

                        <Stack spacing={1}>
                          {tc.yandexSubFlows?.length ? (
                            <Stack direction='row' spacing={1.5}>
                              {tc.yandexSubFlows.map((flow) => (
                                <UIChip
                                  key={flow}
                                  color={flow === 'delivery' ? 'info' : 'primary'}
                                  label={flow === 'delivery' ? t('delivery') : t('taxi_park')}
                                  sx={{ mb: 0.5 }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant='caption' color={Colors.BGRAY}>
                              {t('taxi_park')}:
                            </Typography>
                          )}
                          {/* {tc.yandexSubFlow ? (
                            <UIChip
                              color={tc?.yandexSubFlow === 'delivery' ? 'info' : 'primary'}
                              label={
                                tc.yandexSubFlow === 'delivery' ? t('delivery') : t('taxi_park')
                              }
                              sx={{ mb: 0.5 }}
                            />
                          ) : (
                            <Typography variant='caption' color={Colors.BGRAY}>
                              {t('taxi_park')}:
                            </Typography>
                          )} */}

                          <Typography variant='body2' fontWeight={600}>
                            {tc.name}
                          </Typography>
                        </Stack>
                      </Stack>

                      {isYandex && (
                        <UIActionButton
                          icon='edit'
                          onClick={() => {
                            taxiParkModalRef.current?.open({ p: tc.unique });
                            handleClose();
                          }}
                        />
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            )}

            {isLessThanLg && cabinetType === 'customer' && (
              <Box
                sx={{
                  backgroundColor: Colors.SECONDARY,
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: Colors.WHITE_TEXT,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {company?.name}
                </Typography>

                <TariffHeaderMenu
                  sx={{
                    backgroundColor: 'inherit',
                  }}
                />
              </Box>
            )}

            <Box
              sx={{
                py: 2,
              }}
            >
              <Stack
                component={MenuItem}
                direction='row'
                alignItems='center'
                spacing={1.75}
                onClick={logoutHandler}
              >
                <Box component={LogoutIcon} />

                <Typography fontWeight={600}>{t('log_out_from_account')}</Typography>
              </Stack>
            </Box>
          </Menu>
        </Box>
      </Tooltip>
      <TaxiParkSettingsModal ref={taxiParkModalRef} />
    </>
  );
};
