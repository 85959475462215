import { Paper, PaperProps, styled } from "@mui/material";
import { FC } from "react";

type Props = PaperProps;

const PaperComp = styled(Paper)(() => ({
  borderRadius: "12px",
  padding: "2.4rem",
}));

export const UISection: FC<Props> = (props) => {
  return <PaperComp elevation={0} {...props} />;
};
