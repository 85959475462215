import { memo, useCallback } from 'react';
import { UISection } from '@/shared/ui/ui-section';
import { Stack, Typography } from '@mui/material';
import { UIButton } from '@/shared/ui/ui-button';
import { Link } from 'react-router-dom';
import { useReportsContext } from '../context';
import { UIChip } from '@/shared/ui/ui-chip';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { UIChipColor } from '@/shared/ui/ui-chip/types';
import { YandexDocStatus } from '@/app/api/model';

export const ClosingDocumentsComponent = memo(
  () => {
    const { t } = useTranslation();
    const { arrOfClosingDocs } = useReportsContext();

    const getChipType = useCallback(
      (
        status: YandexDocStatus,
        label: string
      ): {
        label: string;
        color: UIChipColor;
      } => {
        switch (status) {
          case 'canceled':
            return {
              label: label,
              color: 'error',
            };
          case 'draft':
            return {
              label: label,
              color: 'purple',
            };
          case 'rejected':
            return {
              label: label,
              color: 'error',
            };
          case 'signed':
            return {
              label: label,
              color: 'success',
            };
          case 'waitContragentSign':
            return {
              label: label,
              color: 'primary',
            };
          default:
            return {
              label: label,
              color: 'purple',
            };
        }
      },
      []
    );

    if (!arrOfClosingDocs?.length) return null;

    return (
      <UISection>
        <Stack spacing={2}>
          <Typography variant='h5'>{t('customer_trips_page.closing_documents')}</Typography>
          {arrOfClosingDocs.map((doc, index) => {
            return (
              <Stack
                key={index}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ border: `1px solid ${Colors.BORDER}`, borderRadius: '8px', p: 1 }}
              >
                <Stack direction='row' alignItems='center' spacing={1.5}>
                  <Typography variant='body1'>{doc.type}</Typography>
                  <UIChip {...getChipType(doc.statusId, doc.status)} />
                </Stack>
                <Link to={`/customer/documents/${doc.didoxId}/report`}>
                  <UIButton>{t('inspect')}</UIButton>
                </Link>
              </Stack>
            );
          })}
        </Stack>
      </UISection>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
