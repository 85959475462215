import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import Cookies from 'js-cookie';
import i18n from '../i18n';
import { DefaultResponse } from '../helpers';
import { DidoxDoc } from './model/didox-api-model';

export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_DIDOX_API_BASE_URL,
  prepareHeaders: (headers) => {
    const token = Cookies.get('user-key');
    if (token) {
      headers.set('user-key', token);
    }
    headers.set('Accept-Language', i18n.language);
    return headers;
  },
  // responseHandler: async (response) => {
  //   if (response.status === 401) {
  //     return Promise.reject();
  //   }
  //   const result = (await response.json()) as
  //     | {
  //         error: { code: number; msg: string };
  //         success: boolean;
  //       }
  //     | {
  //         data: unknown;
  //         success: boolean;
  //       };

  //   return result;
  // },
});

export const didoxApi = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getDoc: builder.query<DefaultResponse<DidoxDoc>, string>({
      query: (docId) => ({
        method: 'GET',
        url: `documents/${docId}`,
      }),
    }),
    getDocHtml: builder.query<string, string>({
      query: (docId) => ({
        method: 'GET',
        url: `documents/${docId}/html`,
        responseHandler: async (response) => {
          const result = await response.text();

          return result;
        },
      }),
    }),
    signDoc: builder.mutation<DefaultResponse, { docId: string; signature: string }>({
      query: ({ docId, signature }) => ({
        method: 'POST',
        url: `documents/${docId}/sign`,
        body: {
          signature,
        },
      }),
    }),
    getDocumentPdf: builder.query<string, string>({
      query: (docId) => ({
        method: 'GET',
        url: `documents/${docId}/pdf`,
        // responseHandler: async (response) => {
        //   const result = await response.blob();

        //   return result;
        // },
      }),
    }),
    getDocumentSignString: builder.query<
      DefaultResponse<string>,
      { action: 'accept' | 'reject' | 'cancel'; comment?: string; docId: string }
    >({
      query: ({ docId, action, comment }) => ({
        method: 'POST',
        url: `documents/${docId}/tosign`,
        body: {
          action,
          comment,
        },
      }),
    }),
    rejectDoc: builder.mutation<
      DefaultResponse,
      { docId: string; data: { signature: string; comment: string } }
    >({
      query: ({ docId, data }) => ({
        method: 'POST',
        url: `documents/${docId}/reject`,
        body: data,
      }),
    }),
    deleteDoc: builder.mutation<DefaultResponse, { docId: string; data: { signature: string } }>({
      query: ({ docId, data }) => ({
        method: 'POST',
        url: `documents/${docId}/delete`,
        body: data,
      }),
    }),
    deleteDraft: builder.mutation<DefaultResponse, { docId: string }>({
      query: ({ docId }) => ({
        method: 'POST',
        url: `documents/${docId}/delete/draft`,
      }),
    }),
    getDocumentAttachment: builder.query<string, string>({
      query: (docId) => ({
        method: 'GET',
        url: `documents/${docId}/attachment`,
        responseHandler: async (response) => {
          const result = await response.text();

          return result;
        },
      }),
    }),
  }),
});
