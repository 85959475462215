import { Routing } from "@/pages";
import { I18nextProvider } from "react-i18next";
import { appConfig } from "./configs/app-config";
import { useLayoutEffect } from "react";
import { authConfig } from "./configs/auth-config";
import "react-toastify/dist/ReactToastify.css";
import { MuiProvider } from "./providers/mui-provider";
import { ReduxProvider } from "./providers/redux-provider";
import i18n from "./i18n";
import { store } from "./store";
import { Toaster } from "@/shared/toaster";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FullscreenLoader } from "@/shared/fullscreen-loader";

appConfig();

const App = () => {
  useLayoutEffect(() => {
    authConfig(store);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <I18nextProvider i18n={i18n}>
        <ReduxProvider>
          <MuiProvider>
            <Routing />
            <Toaster />
            <FullscreenLoader />
          </MuiProvider>
        </ReduxProvider>
      </I18nextProvider>
    </LocalizationProvider>
  );
};

export default App;
