import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Box, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { EmptyPlaceholder } from '../../../shared/empty-placeholder';
import { UITable } from '../../../shared/ui/ui-table';
import { UIActionButton } from '../../../shared/ui/ui-action-button';
import { Link } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';
import { FieldArrayWithId } from 'react-hook-form';
import dayjs from 'dayjs';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { ReportsPageFormValues } from '../types';
import { GetYandexReportResponseBody } from '../../../app/api/model';

interface UIYandexTableProps {
  yandexReportRows: FieldArrayWithId<ReportsPageFormValues, 'yandexReportRows', 'id'>[] | undefined;
  isTravelReport: boolean;
  isRewardReport: boolean;
  isPosted: boolean;
  yandexReportData: GetYandexReportResponseBody | undefined;
  unique: string | undefined;
  onMountGetReport: (page?: number) => Promise<void>;
  onClickOpenChangeRowModal: (index: number) => void;
  onClickRemoveYandexOrUklonRow: (index: number, unique: number | undefined) => void;
  StackComponent: (props: PropsWithChildren<StackProps>) => JSX.Element;
}

export const UIYandexTable: FC<UIYandexTableProps> = ({
  yandexReportRows = [],
  yandexReportData,
  isPosted,
  isTravelReport,
  isRewardReport,
  StackComponent,
  unique,
  onClickOpenChangeRowModal,
  onClickRemoveYandexOrUklonRow,
  onMountGetReport,
}) => {
  const { t } = useTranslation();
  const [sliceIndex, setSliceIndex] = useState<number>(1);

  const { currentPage, isPaginationDisabled } = useMemo(() => {
    let page = 1;
    const isPaginationDisabled =
      yandexReportRows.length < 11 &&
      (typeof yandexReportData?.totalPages === 'undefined' || yandexReportData?.totalPages === 1);

    if (yandexReportRows.length > 10) {
      sliceIndex === 1 ? (page = 1) : (page = sliceIndex - 1);

      return { currentPage: page, isPaginationDisabled };
    }

    if (yandexReportData?.totalPages === 1) page = 1;
    else if (yandexReportData?.totalPages) page = yandexReportData.totalPages;

    if (
      yandexReportData &&
      yandexReportData.totalPages !== 1 &&
      typeof yandexReportData.nextPage === 'number'
    ) {
      page = yandexReportData.nextPage - 1;
    }

    return { currentPage: page, isPaginationDisabled };
  }, [yandexReportData, yandexReportRows, sliceIndex]);

  const rowIndex = useMemo(() => {
    if (yandexReportRows.length > 10) {
      return sliceIndex;
    } else return currentPage;
  }, [yandexReportRows, sliceIndex, currentPage]);

  const calculateDriversProfit = useCallback(
    (row: FieldArrayWithId<ReportsPageFormValues, 'yandexReportRows', 'id'>) => {
      const amount = row.amount;
      const aggregatorPercent = row.aggregatorPercent;
      const taxiPercent = row.taxiPercent;
      let driversProfit = amount;

      if (aggregatorPercent && taxiPercent && amount) {
        const aggregatorFee = _.multiply(amount, _.divide(aggregatorPercent, 100));
        const taxiFleetFee = _.multiply(amount, _.divide(taxiPercent, 100));

        driversProfit = +(amount - aggregatorFee - taxiFleetFee).toFixed(2);
      }

      return driversProfit;
    },
    []
  );

  return (
    <UITable
      data={
        yandexReportRows.length > 10
          ? yandexReportRows.slice(
              sliceIndex === 1 ? 0 : (sliceIndex - 1) * 10,
              sliceIndex === 1 ? 10 : sliceIndex * 10
            )
          : yandexReportRows
      }
      EmptyPlaceholder={<EmptyPlaceholder />}
      columns={[
        {
          label: '№',
          render: (_, index) => (rowIndex === 1 ? index + 1 : (rowIndex - 1) * 10 + index + 1),
          nowrap: true,
        },
        {
          label: t('customer_trips_page.employee'),
          render: ({ name, pinfl }) => (
            <Stack spacing={1.5}>
              <Typography variant='body2'>{name}</Typography>
              <Typography variant='body2'>{pinfl}</Typography>
            </Stack>
          ),
        },
        isTravelReport
          ? {
              label: t('customer_trips_page.contract_number'),
              key: 'contractNumber',
            }
          : null,
        isTravelReport
          ? {
              label: t('customer_trips_page.contract_date'),
              render: ({ contractDate }) => {
                const dateToShow = dayjs(contractDate).format('DD.MM.YYYY');
                return dateToShow;
              },
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.total_sum'),
              // key: 'amount',
              render: ({ amount }) =>
                typeof amount === 'number'
                  ? numericFormatter(String(amount), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.aggregator_percent') + ', %',
              key: 'aggregatorPercent',
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.aggregator_fee'),
              render: ({ aggregatorCommission }) =>
                typeof aggregatorCommission === 'number'
                  ? numericFormatter(String(aggregatorCommission), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.taxi_fleet_percent') + ', %',
              key: 'taxiPercent',
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.taxi_fleet_fee'),
              render: ({ taxiCommission }) =>
                typeof taxiCommission === 'number'
                  ? numericFormatter(String(taxiCommission), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.drivers_sum'),
              render: (row) => {
                const driversProfit = calculateDriversProfit(row);
                return typeof driversProfit === 'number'
                  ? numericFormatter(String(driversProfit), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0;
              },
            }
          : null,
        isRewardReport
          ? {
              label: t('customer_trips_page.drivers_bonuses'),
              // key: 'taxiDriverReward',
              render: ({ taxiDriverReward }) =>
                typeof taxiDriverReward === 'number'
                  ? numericFormatter(String(taxiDriverReward), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        isTravelReport && isPosted
          ? {
              label: t('yandex_docs_page.documents'),
              render: ({ documents }) => {
                if (!documents?.length) return null;

                return (
                  <Stack spacing={1}>
                    {documents.map((doc, index) => (
                      <Link
                        key={index}
                        to={
                          doc.didoxId
                            ? `/customer/documents/${doc.didoxId}/report`
                            : `/customer/reports/${unique}`
                        }
                      >
                        {doc.type}
                      </Link>
                    ))}
                  </Stack>
                );
              },
            }
          : null,
        {
          render: ({ unique }, index) => (
            <StackComponent justifyContent='flex-end' spacing={1.5}>
              <Tooltip title={t('edit')}>
                <Box
                  component='span'
                  onClick={!isPosted ? () => onClickOpenChangeRowModal(index) : undefined}
                >
                  <UIActionButton icon='edit' disabled={isPosted} />
                </Box>
              </Tooltip>
              <Tooltip title={t('delete')}>
                <Box
                  component='span'
                  onClick={
                    !isPosted
                      ? () => onClickRemoveYandexOrUklonRow(index, unique ? unique : undefined)
                      : undefined
                  }
                >
                  <UIActionButton icon='delete' disabled={isPosted} />
                </Box>
              </Tooltip>
            </StackComponent>
          ),
        },
      ]}
      PaginationProps={{
        page: yandexReportRows.length > 10 ? sliceIndex : currentPage,
        count:
          yandexReportRows.length > 10
            ? Math.ceil(yandexReportRows.length / 10)
            : yandexReportData?.totalPages,
        disabled: isPaginationDisabled,
        onChange: (_, page) => {
          yandexReportRows.length < 11 ? onMountGetReport(page) : setSliceIndex(page);
        },
      }}
    />
  );
};
