import ScrollToTop from '@/shared/scroll-to-top';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import { BrowserRouter, Route, RouteProps, Routes } from 'react-router-dom';
import { MainPage } from './main-page';
import { BalancaPage } from './balance-page';
import { EmployeeRegistrationPage } from './employee-registration-page';
import { EmployeesPage } from './employees-page';
import { TasksPage } from './tasks';
import { AuthCustomerPage } from './auth-customer-page';
import { EmployeeAuthPage } from './employee-auth-page';
import { PrivateRoute } from './private-route';
import { CustomerMutualSettlementsPage } from './customer-mutual-settlements-page';
import { CustomerDocumentsPage } from './customer-documents-page';
import { CustomerRegistryDocumentPage } from './customer-registry-document-page';
import { SingleDocumentPage } from './single-document-page';
import { YandexDocumentsPage } from './new-customer-documents-page/index';
import { NewCustomerDocsPage } from './new-customer-documents-page/new-index';
import { ReportTripsPage } from './report-trips-page';

const routes: RouteProps[] = [
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/auth/customer',
    element: <AuthCustomerPage />,
  },
  {
    path: '/auth/employee',
    element: <EmployeeAuthPage />,
  },
  {
    path: '/registration/employee',
    element: <EmployeeRegistrationPage />,
  },
  {
    path: '/balance',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <BalancaPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/employees',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <EmployeesPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/tasks',
    element: (
      <PrivateRoute>
        <TasksPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/settlements',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <CustomerMutualSettlementsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/documents',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <CustomerDocumentsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/yandex/documents',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <YandexDocumentsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/customer/documents',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <NewCustomerDocsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/customer/documents/:docId/:from',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <SingleDocumentPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/documents/:id',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <CustomerRegistryDocumentPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/customer/reports/:unique',
    element: (
      <PrivateRoute cabinetTypeCheck='customer'>
        <ReportTripsPage />
      </PrivateRoute>
    ),
  },
];

export const Routing = () => {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ScrollToTop />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
