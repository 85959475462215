import { useOpen } from '@/app/hooks';
import ModalTemplate from '@/shared/modal-template';
import UITabs from '@/shared/ui/ui-tabs';
import { Dialog } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaxiParkApiSettings } from './ui/taxi-park-api-settings';
import { TaxiParkBotSettings } from './ui/taxi-park-bot-settings';
import { useForm } from 'react-hook-form';
import { BotSettingsFormValues, TaxiParkSettingsModalRef, YandexApiFormValues } from './types';
import { handleError } from '@/app/helpers';
import {
  useEditTelegramBotSettingsMutation,
  useEditYandexApiAccessMutation,
  useLazyGetYandexApiAccessQuery,
} from '@/app/api';
import notify from '@/shared/toaster/lib/notify';

export const TaxiParkSettingsModal = forwardRef<TaxiParkSettingsModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();

  const yandexApiForm = useForm<YandexApiFormValues>();
  const botForm = useForm<BotSettingsFormValues>();

  const [getApiAccess, { isFetching: isApiAccessFetching }] = useLazyGetYandexApiAccessQuery();
  const [editYandexApiAccess, { isLoading: isEditAccessLoading }] =
    useEditYandexApiAccessMutation();
  const [editTgBot, { isLoading: isEditTgBotLoading }] = useEditTelegramBotSettingsMutation();

  const [activeIndex, setActiveIndex] = useState(0);
  const [taxiParkUniqueId, setTaxiParkUniqueId] = useState<string | number>();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpenHandler,
      close: onCloseHandler,
    }),
    []
  );

  const onOpenHandler = async ({ p }: { p: number | string }) => {
    setOpen(true);
    try {
      const data = await getApiAccess({ p }).unwrap();
      yandexApiForm.reset({
        apiKey: data?.apiKey,
        clientId: data?.clientId,
        parkId: data?.parkId,
      });
    } catch (error) {
      handleError(error);
    }
    setTaxiParkUniqueId(p);
  };

  const onCloseHandler = () => {
    setOpen(false);
    setTaxiParkUniqueId(undefined);
    setTimeout(() => {
      yandexApiForm.reset();
      botForm.reset();
      setActiveIndex(0);
    }, 500);
  };

  const onApiAccessSubmit = async (values: YandexApiFormValues) => {
    if (!taxiParkUniqueId) return;
    try {
      await editYandexApiAccess({ unique: taxiParkUniqueId, ...values }).unwrap();
      notify('', 'success');
      onCloseHandler();
    } catch (error) {
      handleError(error);
    }
  };

  const onBotSettingsSubmit = async (values: BotSettingsFormValues) => {
    if (!taxiParkUniqueId) return;
    try {
      await editTgBot({
        unique: taxiParkUniqueId,
        limitOnWithdrawalsPerDay: Number(values.limitOnWithdrawalsPerDay),
        minimumBalance: Number(values.minimumBalance),
        percentage: values.percentage,
        checkCommittee: values.checkCommittee,
        checkSelfEmployment: values.checkSelfEmployment,
        replenishmentPercentage: values.replenishmentPercentage,
        minimumAmountToWithdrawMoney: Number(values.minimumAmountToWithdrawMoney),
        faceIdToRegister: values.faceIdToRegister,
      }).unwrap();
      notify('', 'success');
      onCloseHandler();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        sx: {
          maxWidth: '560px',
          width: '100%',
        },
      }}
    >
      <ModalTemplate
        title={t('taxi_park_settings')}
        onClose={onCloseHandler}
        loading={isEditAccessLoading || isEditTgBotLoading}
        onSubmit={
          activeIndex
            ? botForm.handleSubmit(onBotSettingsSubmit)
            : yandexApiForm.handleSubmit(onApiAccessSubmit)
        }
      >
        <UITabs
          value={activeIndex}
          onChange={(_, index) => setActiveIndex(index)}
          tabs={[
            {
              label: 'API Yandex Go',
              component: (
                <TaxiParkApiSettings form={yandexApiForm} isLoading={isApiAccessFetching} />
              ),
            },
            {
              label: t('bot_settings'),
              component: <TaxiParkBotSettings form={botForm} taxiParkUnique={taxiParkUniqueId} />,
            },
          ]}
        />
      </ModalTemplate>
    </Dialog>
  );
});
