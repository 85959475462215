import { Stack, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentIcon from '@/app/assets/icons/document-text.svg?react';
import { Colors } from '@/app/constants/colors';

interface Props {
  title?: string;
  subtitle?: string;
  Icon?: React.ReactNode;
  sx?: SxProps;
}

export const EmptyPlaceholder: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { title = t('no_data'), subtitle, Icon = <DocumentIcon />, sx } = props;

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{
        height: '100%',
        ...sx,
      }}
    >
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          height: '8rem',
          width: '8rem',
          backgroundColor: Colors.LGRAY,
          borderRadius: '50%',
        }}
      >
        {Icon}
      </Stack>

      <Typography
        variant='h6'
        sx={{
          mt: 2,
        }}
      >
        {title}
      </Typography>

      <Typography
        variant='body1'
        sx={{
          color: Colors.BGRAY,
          fontWeight: 500,
        }}
      >
        {subtitle}
      </Typography>
    </Stack>
  );
};
