import { useAddInvoiceFromExcelMutation } from '@/app/api';
import { BaseModalRef, fileToBase64, handleError } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import { FilePicker } from '@/shared/inputs/file-picker';
import ModalTemplate from '@/shared/modal-template';
import notify from '@/shared/toaster/lib/notify';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { Box, Dialog, FormControl, FormHelperText } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AddExcelDocRegistry = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();

  const [files, setFiles] = useState<File[]>([]);

  const [exportExcel, { isLoading }] = useAddInvoiceFromExcelMutation();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    []
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setFiles([]);
  };

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();

    if (!files.length) return;

    const base64 = await fileToBase64(files[0]);

    try {
      await exportExcel({
        base64: base64.split(',')[1],
      }).unwrap();
      notify('', 'success');
      onClose();
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 560,
        },
      }}
    >
      <ModalTemplate
        title={t('upload_registry')}
        onClose={onClose}
        submitBtnText={t('upload')}
        loading={isLoading}
        onSubmit={handleSubmit}
      >
        <FormControl>
          <UIFormLabel>{t('attach_file')}</UIFormLabel>

          <FilePicker value={files} onChange={setFiles} accept='.xlsx, .xls' />
          <FormHelperText>
            {t('excel_upload_helper_text')}{' '}
            <Box
              component={Link}
              to={t('links:yandex_doc_registry_template')}
              target='_blank'
              download={'template.xlsx'}
            >
              {t('download_excel_template')}
            </Box>
          </FormHelperText>
        </FormControl>
      </ModalTemplate>
    </Dialog>
  );
});
