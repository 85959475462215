import { useState } from 'react';
import { Layout } from '@/shared/layout';
import UITabs from '@/shared/ui/ui-tabs';
import { Stack, Typography } from '@mui/material';
import { MutualSettlementsDrivers } from './ui/mutual-settlements-drivers';
import { UISection } from '@/shared/ui/ui-section';
import { MutualSettlementsTransactions } from './ui/mutual-settlements-transactions';
import { numericFormatter } from 'react-number-format';
import { getSumFromTiins } from '@/app/helpers';
import { useAppSelector } from '../../app/hooks';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

export const CustomerMutualSettlementsPage = () => {
  const { t } = useTranslation();
  const { company } = useAppSelector((state) => state.auth);
  const isPlovme = company?.invitationFlow === 'plovme';

  const [totalSum, setTotalSum] = useState(0);

  return (
    <Layout>
      <Stack spacing={2}>
        {!!totalSum && (
          <UISection>
            <Stack direction='row' alignItems='baseline' spacing={1.5}>
              <Typography variant='h6' color={Colors.SUCCESS}>
                {t('total')}:
              </Typography>
              <Typography variant='body1' fontWeight={600} fontSize={'2rem'}>
                {numericFormatter(String(getSumFromTiins(totalSum, false).toFixed(2)), {
                  thousandSeparator: ' ',
                })}{' '}
                UZS
              </Typography>
            </Stack>
          </UISection>
        )}

        <UISection sx={{ pt: 1, height: '100%' }}>
          <UITabs
            tabs={
              isPlovme
                ? [
                    {
                      label: t('transfers'),
                      component: <MutualSettlementsTransactions setTotalSum={setTotalSum} />,
                    },
                  ]
                : [
                    { label: t('drivers'), component: <MutualSettlementsDrivers /> },
                    {
                      label: t('transfers'),
                      component: <MutualSettlementsTransactions setTotalSum={setTotalSum} />,
                    },
                  ]
            }
          />
        </UISection>
      </Stack>
    </Layout>
  );
};
