import { Dayjs } from 'dayjs';

export const validateDateValues = (
  date: Dayjs | null,
  t: any,
  isRequired: boolean = true
): boolean | string => {
  if (!isRequired && !date) return true;
  return date?.isValid() ? true : t('errors:required_field');
};
