import { useGetImportWorkersFromExcelHistoryQuery } from '@/app/api';
import { ExcelFileStatus } from '@/app/api/model';
import { Colors } from '@/app/constants/colors';
import { BaseModalRef } from '@/app/helpers';
import { useFilters, useOpen } from '@/app/hooks';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import ModalTemplate from '@/shared/modal-template';
import { UIChip } from '@/shared/ui/ui-chip';
import { UIChipProps } from '@/shared/ui/ui-chip/types';
import { UITable } from '@/shared/ui/ui-table';
import { Dialog, Stack, Typography } from '@mui/material';
import { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

export const ExcelUploadHistoryModal = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();

  const { filterParams, onChangeFilterParams } = useFilters({
    initialFilterParams: {
      page: 1,
    },
  });

  const { data, isFetching } = useGetImportWorkersFromExcelHistoryQuery(filterParams, {
    skip: !open,
    refetchOnMountOrArgChange: true,
  });

  useImperativeHandle(
    ref,
    () => ({
      open: onOpenHandler,
      close: onCloseHandler,
    }),
    []
  );

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onOpenHandler = () => {
    setOpen(true);
  };

  const getStatusChipProps = (
    status: ExcelFileStatus
  ): { label: string; color: UIChipProps['color'] } => {
    let color: UIChipProps['color'] = 'success';

    switch (status) {
      case 'inProgress':
        color = 'purple';
        break;
      case 'waiting':
        color = 'warning';
        break;
      case 'done':
        color = 'success';
        break;
      default:
        color = 'error';
    }
    return {
      color,
      label: t(`upload_statuses.${status}`),
    };
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      PaperProps={{
        sx: {
          maxWidth: '940px',
          width: '100%',
        },
      }}
    >
      <ModalTemplate onClose={onCloseHandler} noBtns title={t('file_upload_history')}>
        {data && (
          <Stack
            direction='row'
            spacing={2}
            useFlexGap
            flexWrap='wrap'
            sx={{
              px: 2,
              py: 1.5,
              backgroundColor: Colors.LGRAY,
              borderRadius: '8px',
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
              }}
            >
              {t('total_uploaded_files')}:{' '}
              {+data?.doneCount + +data.inProgressCount + +data.waitingCount}
            </Typography>

            <Typography
              sx={{
                fontWeight: 500,
                color: Colors.SUCCESS,
              }}
            >
              {t('in_queue')}: {data?.waitingCount}
            </Typography>

            <Typography
              sx={{
                fontWeight: 500,
                color: Colors.BGRAY,
              }}
            >
              {t('successfully')}: {data?.doneCount}
            </Typography>
          </Stack>
        )}

        <UITable
          data={data?.data ?? []}
          isLoading={isFetching}
          EmptyPlaceholder={<EmptyPlaceholder />}
          columns={[
            {
              key: 'fileName',
              label: t('file'),
            },
            {
              key: 'createdAt',
              label: t('upload_date'),
            },
            {
              render: ({ status }) => <UIChip {...getStatusChipProps(status)} />,
              label: t('status'),
            },
            {
              key: 'numberOfWorkers',
              label: t('employees_count'),
            },
          ]}
          PaginationProps={{
            count: data?.totalPages ?? 0,
            page: filterParams.page,
            onChange: (_e, page) => onChangeFilterParams({ page }),
          }}
        />
      </ModalTemplate>
    </Dialog>
  );
});
