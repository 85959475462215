import { FC, forwardRef, useImperativeHandle, useState } from 'react';
import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Drawer,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LangSwitcher } from '@/features/lang-switcher';
import { useAppSelector } from '@/app/hooks';
import { Link, useLocation } from 'react-router-dom';
import { getHeaderNavData } from '../lib';
import { BaseModalRef } from '@/app/helpers';

import { PlusIcon } from '@/shared/icons';
import PhoneIcon from '@/app/assets/icons/phone-icon.svg?react';

import { Colors } from '@/app/constants/colors';
import { useTranslation } from 'react-i18next';

const ButtonComp: FC<ButtonBaseProps> = (props) => (
  <ButtonBase
    {...props}
    sx={{
      mt: 2,
      path: {
        stroke: Colors.ACCENT,
        fill: Colors.ACCENT,
      },
      ...props.sx,
    }}
  />
);

export const NavDrawer = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { breakpoints } = useTheme();
  const { cabinetType } = useAppSelector((state) => state.app);
  const { company, roles } = useAppSelector((state) => state.auth);
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => onCloseHandler,
    }),
    []
  );

  const onCloseHandler = () => {
    setOpen(false);
  };

  const renderNav = () => {
    if (!cabinetType) return;

    const data = getHeaderNavData();

    return data.map((el, idx) => {
      const isActive = pathname === el.to;

      if (
        el.condition &&
        !el.condition({
          cabinetType,
          invitationFlow: company?.invitationFlow,
          roles,
        })
      ) {
        return null;
      }

      return (
        <MenuItem
          component='li'
          key={idx}
          sx={{
            px: 2.5,
            backgroundColor: isActive ? 'rgba(0,0,0,.1)' : 'transparent',
            borderRadius: '4px',
          }}
        >
          <Box
            component={Link}
            to={el.to as string}
            sx={{
              textDecoration: 'none',
              fontWeight: 600,
              display: 'block',
              lineHeight: '2',
            }}
          >
            {el.label}
          </Box>
        </MenuItem>
      );
    });
  };

  return (
    <Drawer
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '320px',
          height: '100%',
        },
      }}
    >
      <Stack
        direction='row'
        sx={{
          ml: '1rem',
          mt: '12px',
        }}
      >
        <ButtonBase
          onClick={onCloseHandler}
          sx={{
            p: 1,
            borderRadius: '50%',
            backgroundColor: 'rgba(0,0,0,.07)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,.12)',
            },
          }}
        >
          <Box
            component={PlusIcon}
            sx={{
              transform: 'rotate(45deg)',
              height: '2.4rem',
              width: '2.4rem',
            }}
          />
        </ButtonBase>
      </Stack>

      <MenuList>{renderNav()}</MenuList>

      <Stack direction='column' alignItems='flex-start' spacing={3} px={2.5}>
        <Box component='div'>
          <Typography>{t('landing:if_questions_left')}:</Typography>
          <Box
            component='a'
            href='tel:+998781484900'
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              mt: 2,
            }}
          >
            <Box component={PhoneIcon} width={24} sx={{ path: { fill: 'white' } }} />
            <Typography ml={2} variant={isMobile ? 'body2' : 'body1'}>
              (78) 148-49-00
            </Typography>
          </Box>
        </Box>

        <Box component='div'>
          <Typography>{t('landing:change_language')}:</Typography>
          <LangSwitcher Container={ButtonComp} />
        </Box>
      </Stack>
    </Drawer>
  );
});
