import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUserLang } from '../helpers';
import commonRu from './locales/ru/common.json';
import commonUz from './locales/uz/common.json';
import errorsRu from './locales/ru/errors.json';
import errorsUz from './locales/uz/errors.json';
import landingRu from './locales/ru/landing.json';
import landingUz from './locales/uz/landing.json';
import linksRu from './locales/ru/links.json';
import linksUz from './locales/uz/links.json';

i18n
  .use(initReactI18next)
  .init(
    {
      defaultNS: 'common',
      lng: getUserLang() ?? 'uz',
      fallbackLng: import.meta.env.DEV ? 'ru' : 'uz',
      interpolation: {
        escapeValue: false,
      },
      returnObjects: true,
      cleanCode: true,
      react: {
        useSuspense: true,
      },
      resources: {
        ru: {
          common: commonRu,
          errors: errorsRu,
          landing: landingRu,
          links: linksRu,
        },
        uz: {
          common: commonUz,
          errors: errorsUz,
          landing: landingUz,
          links: linksUz,
        },
      },
    },
    (error) => {
      if (error) return console.error(error);
    }
  )
  .catch((error) => Promise.reject(error));

export default i18n;
