import i18n from '@/app/i18n';

const t = i18n.t.bind(i18n);

export const didoxDocsStatuses = [
  {
    value: 'draft',
    label: t('documents_statuses.draft'),
  },
  {
    value: 'waitContragentSign',
    label: t('documents_statuses.waitContragentSign'),
  },
  {
    value: 'signed',
    label: t('documents_statuses.signed'),
  },
  {
    value: 'rejected',
    label: t('documents_statuses.rejected'),
  },
  {
    value: 'canceled',
    label: t('documents_statuses.canceled'),
  },
];
